import { useTheme } from "@mui/material";
import {
    Button,
    Typography,
    Skeleton,
    Icon,
    Dialog,
    Grid,
    Box,
} from "@ntpkunity/controls";
import { StatusFlag } from "./customerStatusFlagsStyle";
import {
    useAddStatusFlag,
    useGetAddedCustomerFlags,
    useRemoveCustomerFlags,
} from "common/hooks/useCustomerData";
import { useGetConfiguredFlags } from "common/hooks/useConfigurationData";
import { useEffect, useState } from "react";
import type { ICustomerStatusFlag, IConfiguredStatusFlag } from "Interface";
import { useParams } from "react-router-dom";
import { FlagCategory } from "enums/UseQueryKeys";
import { useStoreContext } from "store/storeContext";
import DisableLoader from "../../../../shared/assets/images/loader-disabled.gif";
import { getInternalAccessToken } from "utilities/methods";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import { HistorySearchComponent } from "@shared/history/HistorySearchComponent";
import { HistoryModule } from "enums/HistoryModule";
import { PAUSE_RESUME_SUBSCRIPTION_FLAGS_SET } from "common/constants";
import { useGetContractByRefercnceId } from "common/hooks/useSubscription";
import { SubscriptionStatus } from "enums/SubscriptionTypes";
import { useCustomerOrderData } from "common/hooks/useCustomerOrderData";

export const CustomerStatusFlags = () => {
    const theme = useTheme();
    const params = useParams();
    const markStatusFlagPermission = useHasPermissions(
        ccpPermissions.FIELDS.MARK_STATUS_FLAG,
        PermissionTypes.DISABLED
    );
    const unmarkStatusFlagPermission = useHasPermissions(
        ccpPermissions.FIELDS.UNMARK_STATUS_FLAG,
        PermissionTypes.DISABLED
    );
    const reference_id = params?.reference_id;
    const [order_reference_id, setOrderReferenceId] = useState();
    const tokenInforamtion = getInternalAccessToken();
    const { actions } = useStoreContext();
    const { data: getConfiguredFlags, isLoading: isConfiguredFlagLoading } =
    useGetConfiguredFlags(reference_id);
    const { data: getAddedFlags, isLoading: isAddedFlagsLoading } =
    useGetAddedCustomerFlags(reference_id);
    const { mutate: removeFlags, isLoading: isRemoveLoading } =
    useRemoveCustomerFlags();
    const { mutate: addStatusFlag, isLoading: isAddLoading } = useAddStatusFlag();
    const { data: getCustomerOrderData } = useCustomerOrderData(reference_id);

    const { data: getConratctByRefernceId } = useGetContractByRefercnceId(
        reference_id,
        order_reference_id
    );
    const [configuredStatusflags, setConfiguredStatusflags] = useState(null);
    const [customerStatusFlags, setCustomerStatusFlags] =
    useState<ICustomerStatusFlag[]>();
    const [selectedConfiguredFlag, setSelectedConfiguredFlag] =
    useState<IConfiguredStatusFlag>();
    const [dialogOpened, setDialogOpened] = useState<boolean>();
    const [popupButtonState, setPopupButtonState] = useState<string>("add");
    const [popupButtonFlagName, setPopupButtonFlagName] = useState<string>("");

    const [markStatusFlagPermissionState, setMarkStatusFlagPermissionState] =
    useState(false);
    const [unmarkStatusFlagPermissionState, setUnmarkStatusFlagPermissionState] =
    useState(false);

    useEffect(() => {
        if (getCustomerOrderData?.data?.orders) {
            const orderData = getCustomerOrderData?.data?.orders;
            const inProgressOrder = orderData?.[0];
            setOrderReferenceId(inProgressOrder?.id);
        }
    }, [getCustomerOrderData]);

    useEffect(() => {
        setCustomerStatusFlags(getAddedFlags?.data);
        setConfiguredStatusflags(getConfiguredFlags?.data);
    }, [getConfiguredFlags?.data, getAddedFlags?.data]);
/* eslint-disable array-callback-return */
    useEffect(() => {
        if (configuredStatusflags && customerStatusFlags) {
            const copyConfiguredStatusflags = configuredStatusflags?.slice();
            customerStatusFlags?.map((item) => {
                const configuredStatusflag = copyConfiguredStatusflags?.find(
                    (f) => f.flag_name === item?.flag_name
                );
                if (configuredStatusflag) {
                    configuredStatusflag.is_added = true;
                } else {
                    configuredStatusflag.is_added = false;
                }
            });
            setConfiguredStatusflags(copyConfiguredStatusflags);
        }
    }, [customerStatusFlags]);

    useEffect(() => {
        setMarkStatusFlagPermissionState(markStatusFlagPermission);
        setUnmarkStatusFlagPermissionState(unmarkStatusFlagPermission);
    }, [markStatusFlagPermission, unmarkStatusFlagPermission]);

    const handleFlagClick = (configuredStatusFlag: IConfiguredStatusFlag) => {
        setSelectedConfiguredFlag(configuredStatusFlag);
        setDialogOpened(true);
    };

    const handleOnSave = () => {
        if (selectedConfiguredFlag?.is_added) {
            const removedCustomerStatusFlag = customerStatusFlags.find(
                (f) => f.flag_name === selectedConfiguredFlag.flag_name
            );
            // remove flag
            removeFlags(
                {
                    identifier: removedCustomerStatusFlag?.identifier,
                    deleted_by: tokenInforamtion?.user_name,
                },
                {
                    onSuccess () {
                        setPopupButtonFlagName("");
                        setPopupButtonState("add");
                        const copyConfiguredStatusflags = configuredStatusflags.slice();
                        copyConfiguredStatusflags[
                            copyConfiguredStatusflags.findIndex(
                                (f) => f.identifier === selectedConfiguredFlag.identifier
                            )
                        ].is_added = false;
                        setConfiguredStatusflags(copyConfiguredStatusflags);
                        setCustomerStatusFlags((current) =>
                            current.filter(
                                (f) => f.identifier !== removedCustomerStatusFlag.identifier
                            )
                        );
                        setDialogOpened(false);
                        actions.setToast({
                            toastMessage: "Flag has been Removed Successfully",
                            toastState: true,
                        });
                    },
                    onError (error: any) {
                        actions.setToast({
                            toastMessage:
                error?.detail?.message?.toString(),
                            toastState: true,
                            variant: "error",
                        });
                        setDialogOpened(false);
                    },
                }
            );
        } else {
            // add flag
            addStatusFlag(
                {
                    reference_id: params?.reference_id,
                    flag_name: selectedConfiguredFlag?.flag_name,
                    category: selectedConfiguredFlag?.category,
                    created_by: tokenInforamtion?.user_name,
                },
                {
                    onSuccess ({ data }) {
                        setPopupButtonFlagName("");
                        setPopupButtonState("add");
                        setCustomerStatusFlags((preData) => [...preData, data]);
                        setDialogOpened(false);
                        actions.setToast({
                            toastMessage: "Flag has been Added Successfully",
                            toastState: true,
                        });
                    },
                    onError (error: any) {
                        actions.setToast({
                            toastMessage:
                error?.detail?.message?.toString(),
                            toastState: true,
                            variant: "error",
                        });
                        setDialogOpened(false);
                    },
                }
            );
        }
    };

    const pauseResumeModalText = () => {
        if (!PAUSE_RESUME_SUBSCRIPTION_FLAGS_SET.has(popupButtonFlagName)) {
            return null;
        }

        if (popupButtonState !== "add") {
            const count = customerStatusFlags.reduce((accumulator, item) => {
                if (PAUSE_RESUME_SUBSCRIPTION_FLAGS_SET.has(item.flag_name)) {
                    return accumulator + 1;
                }
                return accumulator;
            }, 0);

            return count === 1 &&
        getConratctByRefernceId !== null &&
        getConratctByRefernceId?.subscription_status ===
          SubscriptionStatus.PAUSE
                ? "As a result of unmarking this flag, subscription will be resumed and all pending invoices will be sent to customer for payment"
                : null;
        }

        if (
            getConratctByRefernceId?.subscription_status === SubscriptionStatus.PAUSE
        ) {
            return "Subscription is already paused, it will not be resumed until this flag is un-marked.";
        } else if (
            getConratctByRefernceId?.subscription_status === SubscriptionStatus.ACTIVE || getConratctByRefernceId?.subscription_status === SubscriptionStatus.PENDING_RETURN
        ) {
            return "Susbcription will be paused for an indefinite period (even after the subscription end date) and all invoices will be created as Draft during pause period";
        }
        return null;
    };

    return (
        <>
            <StatusFlag>
                <>
                    {isConfiguredFlagLoading || isAddedFlagsLoading ? (
                        <Skeleton width={"100%"} height={80} theme={theme} />
                    ) : (
                        <>
                            {configuredStatusflags &&
                configuredStatusflags?.map((item, index) => {
                    return (
                        <Button
                            disabled={
                                item.category == FlagCategory.Automatic ||
                        (item.is_added !== true &&
                          !markStatusFlagPermissionState) ||
                        (item.is_added === true &&
                          !unmarkStatusFlagPermissionState)
                            }
                            theme={theme}
                            defaultBtn
                            className={item.is_added === true ? "selected" : ""}
                            key={index}
                            text={item.flag_name}
                            onClick={() => {
                                handleFlagClick(item);
                                setPopupButtonFlagName(item.flag_name);
                                if (item.is_added == true) {
                                    setPopupButtonState("remove");
                                }
                            }}
                        />
                    );
                })}
                        </>
                    )}
                </>
            </StatusFlag>
            {useHasPermissions(
                ccpPermissions.FIELDS.VIEW_STATUS_FLAG_HISTORY,
                PermissionTypes.VIEW
            ) && <HistorySearchComponent module={HistoryModule.STATUS_FLAG} />}
            <Dialog
                theme={theme}
                size="xs"
                variant="confirmation"
                open={dialogOpened}
                children={
                    <>
                        <Box theme={theme} textAlign="center">
                            <Box theme={theme} className="dialog-icon">
                                {popupButtonState == "remove" ? (
                                    <Icon name="ErrorTriangle" />
                                ) : (
                                    <Icon name="DialogAddIcon" />
                                )}
                            </Box>
                            <Box theme={theme} mt={4}>
                                <Typography
                                    theme={theme}
                                    mb={2}
                                    className="content-title"
                                    variant="h2"
                                    component="h2"
                                >
                  Are you sure you want to {popupButtonState} "
                                    {popupButtonFlagName}" flag?
                                </Typography>
                                <Typography
                                    theme={theme}
                                    className="text-muted"
                                    variant="body2"
                                    component="span"
                                >
                                    {pauseResumeModalText()}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                }
                customFooter={
                    <>
                        <Grid container theme={theme} spacing={2}>
                            <Grid theme={theme} item xs={6} textAlign={"right"}>
                                <Button
                                    theme={theme}
                                    secondary
                                    text="Cancel"
                                    onClick={() => {
                                        setDialogOpened(false);
                                    }}
                                />
                            </Grid>
                            <Grid theme={theme} item xs={6}>
                                <Button
                                    disabled={isRemoveLoading || isAddLoading}
                                    theme={theme}
                                    primary
                                    text="Save"
                                    onClick={handleOnSave}
                                    endIcon={
                                        (isRemoveLoading || isAddLoading) && (
                                            <img src={DisableLoader} alt="Loader" />
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            />
        </>
    );
};
