import { useState, useEffect, FC } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Link, TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  CircleLoader,
  DataTable,
  DatePicker,
  Dialog,
  Grid,
  Icon,
  Input,
  Menu,
  Select,
  Skeleton,
  Textarea,
  Typography,
} from "@ntpkunity/controls";
import { InvoicesWrap } from "@shared/InvoicesWrap";
import { InvoiceModelBlurWrap, InvoiceModelWrap } from "./invoiceModalStyle";
import {
  useSaveInvoice,
  useStripePriceList,
  useSaveInvoiceItem,
  useSaveStripePrice,
  useDeleteInvoiceItem,
  useGetStripeInvoice,
  useDeleteStripeInvoice,
  useSendStripeInvoice,
  useStripeInvoiceCreditNotes,
  useStripeCreditNotePreview,
  useSaveCreditNote,
  useUpdateInvoice,
  useStripeProductSearch,
  useGetStripeProductById,
} from "common/hooks/useHubexData";
import DisableLoader from "@shared/assets/images/loader-disabled.gif";
import { SELECT } from "utilities/constants";
import type { ICustomerInvoice } from "Interface";
import dayjs from "dayjs";
import { useStoreContext } from "store/storeContext";
import {
  ModalModes,
  PermissionTypes,
  StripeMetaData,
  SubscriptionStatus,
  InvoiceDisplayStatus,
  InvoiceDisplayType,
} from "enums";
import { useProceedReturnRequest } from "common/hooks/useReturnData";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { ReturnTypes } from "enums/ReturnTypes";
import { useGetTaxAgainstStripeProduct } from "common/hooks/useSubscription";
import { utcToLocal } from "utilities/methods";
import { dateFormatSearch } from "common/constants";

const credit_note_reasons = [
  { text: "Select", value: SELECT },
  { text: "Duplicate Charge", value: "duplicate" },
  { text: "Product unsatisfactory", value: "product_unsatisfactory" },
  { text: "Order Change", value: "order_change" },
  { text: "Fraudulent Charge", value: "fraudulent" },
  { text: "Other", value: "Other" },
];

const InvoiceSummary = ({
  formValues,
  theme,
  customerInvoice,
  stripeInvoiceId,
  fetchSummaryInfo,
  setFetchSummaryInfo,
  setCustomerInvoice,
  modalOpeningMode,
  stripeInvoiceCreditNotes,
  setError,
  clearErrors,
  setValue,
  getValues,
  errors,
  isTaxCalculate,
  setIsTaxCalculate,
  invoiceTaxAmount,
  setDeleteConfirmationDialog,
}) => {
  const { mutate: getStripeInvoice } = useGetStripeInvoice();
  const { mutate: getStripeCreditNotePreview } = useStripeCreditNotePreview();

  const creditNoteOptions = [
    { text: "Select how to credit", value: SELECT, disabled: true },
    {
      text: `Refund to **** ${
        customerInvoice?.payment_method_last4 !== undefined &&
        customerInvoice?.payment_method_last4
      }`,
      value: "refundAmount",
    },
    { text: "Credit customer’s balance", value: "creditAmount" },
  ];

  const [summaryFields, setSummaryFields] = useState<{
    amount_due;
    subtotal;
    totalTaxAmount;
    total;
    starting_balance;
  }>();
  const [creditNotes, setCreditNotes] = useState<any[]>();
  const [firstSegregation, setFirstSegregation] = useState(SELECT);
  const [secondSegregation, setSecondSegregation] = useState(SELECT);
  const [showAlsoCreditToOption, setShowAlsoCreditToOption] =
    useState<boolean>(true);
  const { actions } = useStoreContext();

  useEffect(() => {
    if (fetchSummaryInfo) {
      if (modalOpeningMode === ModalModes.CREDIT_NOTE) {
        const taxAmount =
          formValues?.lineItems?.find(
            (f) => f?.metadata[StripeMetaData.TAX_CHARGE] === "True"
          )?.creditAmount ?? 0;
        const lineItems = formValues?.lineItems?.filter(
          (f) => f.creditAmount > 0
        );
        if (lineItems?.length > 0) {
          getStripeCreditNotePreview(
            {
              lines: lineItems,
              invoice_id: stripeInvoiceId,
            },
            {
              onSuccess() {
                setSummaryFields((preStatus) => ({
                  ...preStatus,
                  amount_due:
                    customerInvoice?.due_amount > 0
                      ? customerInvoice?.due_amount
                      : 0,
                  subtotal:
                    lineItems?.length > 0
                      ? lineItems
                          ?.map((item) => item.creditAmount * 100)
                          .reduce((prev, next) => prev + next) -
                        taxAmount * 100
                      : 0,
                  totalTaxAmount: taxAmount * 100,
                  total:
                    lineItems?.length > 0
                      ? lineItems
                          ?.map((item) => item.creditAmount * 100)
                          .reduce((prev, next) => prev + next)
                      : 0,
                }));
                setValue(
                  "refundAmount",
                  lineItems?.length > 0
                    ? lineItems
                        ?.map((item) => item.creditAmount * 100)
                        .reduce((prev, next) => prev + next)
                    : 0
                );
                setIsTaxCalculate(false);
                setFetchSummaryInfo(false);
              },
              onError(error: any) {
                console.log("Error Occured", error);
              },
            }
          );
        } else {
          setSummaryFields((preStatus) => ({
            ...preStatus,
            amount_due:
              customerInvoice?.due_amount > 0 ? customerInvoice?.due_amount : 0,
            subtotal:
              lineItems?.length > 0
                ? lineItems
                    ?.map((item) => item.creditAmount * 100)
                    .reduce((prev, next) => prev + next)
                : 0,
            totalTaxAmount: taxAmount,
            total:
              lineItems?.length > 0
                ? lineItems
                    ?.map((item) => item.creditAmount * 100)
                    .reduce((prev, next) => prev + next)
                : 0,
          }));
          setIsTaxCalculate(false);
          setFetchSummaryInfo(false);
          const zeroAmountLineItems = formValues?.lineItems?.filter(
            (f) => parseInt(f.creditAmount) == 0
          );
          if (zeroAmountLineItems?.length > 0) {
            setError("lineItems.creditAmount", {
              message: "Amount Credit should be greater then 0",
            });
            actions.setToast({
              toastMessage: "Amount Credit should be greater then 0",
              toastState: true,
              variant: "error",
            });
          } else {
            clearErrors("creditAmount");
            clearErrors("lineItems.creditAmount");
          }
        }
      } else {
        const taxAmount =
          formValues?.lineItems?.find(
            (f) => f?.metadata[StripeMetaData.TAX_CHARGE] === "True"
          )?.productAmount ?? 0;
        getStripeInvoice(
          {
            invoice_id: stripeInvoiceId,
          },
          {
            onSuccess({ data }) {
              setSummaryFields((preStatus) => ({
                ...preStatus,
                amount_due: data?.amount_due > 0 ? data?.amount_due : 0,
                subtotal: data?.subtotal > 0 ? data?.subtotal - taxAmount : 0,
                totalTaxAmount: taxAmount,
                starting_balance:
                  data?.starting_balance !== 0 ? data?.starting_balance : 0,
                total: data?.total > 0 ? data?.total : 0,
              }));
              setValue("dueAmount", data?.amount_due);
              setIsTaxCalculate(false);
              setFetchSummaryInfo(false);
              setDeleteConfirmationDialog(false);
              setCustomerInvoice({
                ...data,
                lines: data?.lines?.data?.map((line) => {
                  return {
                    id: line?.id,
                    invoice_item: line?.invoice_item,
                    description: line?.description,
                    amount: line?.amount,
                    price_id: line?.price?.id,
                    product_id: line?.price?.product,
                  };
                }),
              });
            },
            onError(error: any) {
              console.log("Error Occured", error);
              setDeleteConfirmationDialog(false);
            },
          }
        );
      }
    }
  }, [fetchSummaryInfo]);

  useEffect(() => {
    if (stripeInvoiceCreditNotes) {
      setCreditNotes(
        stripeInvoiceCreditNotes?.reduce(
          (result, item) => [
            ...result,
            {
              number: item?.number,
              reason: item?.reason
                ? credit_note_reasons.find((f) => f.value === item?.reason)
                    ?.text
                : "Other",
              lines: item?.lines?.data?.filter(
                (f) => f.type === "invoice_line_item"
              ),
              total_credit_amount: item?.lines?.data
                ?.filter((f) => f.type === "invoice_line_item")
                ?.map((item) => item.amount)
                .reduce((prev, next) => prev + next),
            },
          ],
          []
        )
      );
    }
  }, [stripeInvoiceCreditNotes]);

  useEffect(() => {
    if (modalOpeningMode !== ModalModes.CREDIT_NOTE) {
      let taxAmount =
        customerInvoice?.lines?.find(
          (f) => f?.metadata?.[StripeMetaData.TAX_CHARGE] === "True"
        )?.amount ?? 0;
      taxAmount = invoiceTaxAmount + taxAmount;
      setSummaryFields((preStatus) => ({
        ...preStatus,
        amount_due:
          customerInvoice?.due_amount > 0 ? customerInvoice?.due_amount : 0,
        subtotal:
          customerInvoice?.subtotal > 0
            ? customerInvoice?.subtotal - taxAmount
            : 0,
        starting_balance:
          customerInvoice?.starting_balance !== 0
            ? customerInvoice?.starting_balance
            : 0,
        totalTaxAmount: taxAmount,
        total: customerInvoice?.total > 0 ? customerInvoice?.total : 0,
      }));
    }
  }, [invoiceTaxAmount]);

  const handleCreditOptionDropDownChange = (event) => {
    const selectedValue = event.target.value;
    setValue("isCreditOptionSelected", true);
    clearErrors("isCreditOptionSelected");
    if (selectedValue === "creditAmount") {
      if (showAlsoCreditToOption) {
        setValue("creditAmount", summaryFields.total);
        setValue("refundAmount", undefined);
      } else {
        const credit_amt = getValues("creditAmount");
        setValue("creditAmount", getValues("refundAmount"));
        setValue("refundAmount", credit_amt);
      }
    } else {
      if (showAlsoCreditToOption) {
        setValue("refundAmount", summaryFields.total);
        setValue("creditAmount", undefined);
      } else {
        const refund_amt = getValues("refundAmount");
        setValue("refundAmount", getValues("creditAmount"));
        setValue("creditAmount", refund_amt);
      }
    }
  };

  const handleValueBlur = (event, segregation) => {
    const first_amt =
      Number(
        (event.target.value ? event.target.value : "0").replace(
          /[^0-9.-]+/g,
          ""
        )
      ) * 100;
    const second_amt =
      segregation === "creditAmount"
        ? getValues("refundAmount")
          ? +getValues("refundAmount")
          : 0
        : getValues("creditAmount")
        ? +getValues("creditAmount")
        : 0;
    if (first_amt <= 0) {
      setError(segregation, { message: "Amount needs to be greater than 0." });
      actions.setToast({
        toastMessage: "Amount needs to be greater than 0.",
        toastState: true,
        variant: "error",
      });
    } else if (second_amt <= 0) {
      setError(
        segregation === "creditAmount" ? "refundAmount" : "creditAmount",
        { message: "Amount needs to be greater than 0." }
      );
      clearErrors(segregation);
      actions.setToast({
        toastMessage: "Amount needs to be greater than 0.",
        toastState: true,
        variant: "error",
      });
    } else {
      if (first_amt + second_amt > summaryFields?.total) {
        const message = `Destination amounts need to sum to the credit amount of ${(
          summaryFields?.total / 100
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}`;
        setError("creditAmount", { message: message });
        actions.setToast({
          toastMessage: message,
          toastState: true,
          variant: "error",
        });
      } else {
        clearErrors(segregation);
        clearErrors("creditAmount");
      }
    }
    setValue(segregation, first_amt);
  };

  const handleAlsoCreditToClick = () => {
    if (formValues?.isCreditOptionSelected) {
      setValue(firstSegregation, summaryFields.total);
      setShowAlsoCreditToOption(false);
    } else {
      setError("isCreditOptionSelected", {
        message: "Credit destination is required.",
      });
      actions.setToast({
        toastMessage: "Credit destination is required.",
        toastState: true,
        variant: "error",
      });
    }
  };

  const handleSecondSeggregationDelete = () => {
    setValue(secondSegregation, undefined);
    setValue(firstSegregation, summaryFields.total);
    setShowAlsoCreditToOption(true);
  };

  const renderConditionalLabel = () => {
    if (
      customerInvoice?.invoice_status === InvoiceDisplayStatus.PAID &&
      creditNotes?.length > 0
    ) {
      return "Adjusted Total";
    } else if (customerInvoice?.invoice_status === InvoiceDisplayStatus.PAID) {
      return "Paid Amount";
    } else {
      return "Due Amount";
    }
  };
  return (
    <>
      {isTaxCalculate ? (
        <Grid container theme={theme} spacing={2}>
          <Grid theme={theme} item xs={6}></Grid>
          <Grid theme={theme} item xs={6}>
            <Skeleton
              width={"100%"}
              height={20}
              theme={theme}
              sx={{ mb: 2 }}
            ></Skeleton>
            <Skeleton
              width={"100%"}
              height={20}
              theme={theme}
              sx={{ mb: 2 }}
            ></Skeleton>
            <Skeleton
              width={"100%"}
              height={20}
              theme={theme}
              sx={{ mb: 2 }}
            ></Skeleton>
            <Skeleton
              width={"100%"}
              height={20}
              theme={theme}
              sx={{ mb: 2 }}
            ></Skeleton>
            <Skeleton
              width={"100%"}
              height={20}
              theme={theme}
              sx={{ mb: 2 }}
            ></Skeleton>
          </Grid>
        </Grid>
      ) : (
        <Grid container theme={theme} spacing={2}>
          <Grid theme={theme} item xs={6}></Grid>
          <Grid theme={theme} item xs={6}>
            <Box theme={theme} className="sub-total">
              <Box
                theme={theme}
                className={`${
                  modalOpeningMode === ModalModes.CREDIT_NOTE
                    ? "row withBorder"
                    : "row"
                }`}
              >
                <Typography
                  theme={theme}
                  className="content-title"
                  variant="body2"
                  component="p"
                >
                  Sub Total
                </Typography>
                <Typography
                  theme={theme}
                  className="content-title"
                  variant="body2"
                  component="p"
                >
                  {(summaryFields
                    ? summaryFields.subtotal / 100
                    : 0
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
              </Box>
              <Box
                theme={theme}
                className={`${
                  modalOpeningMode === ModalModes.CREDIT_NOTE
                    ? "row withBorder"
                    : "row"
                }`}
              >
                <Typography
                  theme={theme}
                  className="content-title"
                  variant="body2"
                  component="p"
                >
                  Total Tax
                </Typography>
                <Typography
                  theme={theme}
                  className="content-title"
                  variant="body2"
                  component="p"
                >
                  {(summaryFields
                    ? summaryFields.totalTaxAmount / 100
                    : 0
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
              </Box>
              <Box
                theme={theme}
                className={`${
                  modalOpeningMode === ModalModes.CREDIT_NOTE
                    ? "row withBorder"
                    : "row"
                }`}
              >
                <Typography
                  theme={theme}
                  className="content-title bold"
                  variant="body2"
                  component="p"
                >
                  {modalOpeningMode === ModalModes.CREDIT_NOTE
                    ? "Total Amount To Be Credit"
                    : "Total Amount (Incl. Tax)"}
                </Typography>
                <Typography
                  theme={theme}
                  className="content-title bold"
                  variant="body2"
                  component="p"
                >
                  {(summaryFields
                    ? summaryFields.total / 100
                    : 0
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
              </Box>
              {summaryFields?.starting_balance !== undefined &&
                summaryFields?.starting_balance !== 0 && (
                  <Box
                    theme={theme}
                    className={`${
                      modalOpeningMode === ModalModes.CREDIT_NOTE
                        ? "row withBorder"
                        : "row"
                    }`}
                  >
                    <Typography
                      theme={theme}
                      className="content-title"
                      variant="body2"
                      component="p"
                    >
                      Customer Balance
                    </Typography>
                    <Typography
                      theme={theme}
                      className="content-title"
                      variant="body2"
                      component="p"
                    >
                      {(summaryFields
                        ? summaryFields?.starting_balance / 100
                        : 0
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </Typography>
                  </Box>
                )}
              {modalOpeningMode === ModalModes.CREDIT_NOTE && (
                <Box theme={theme} className="row withBorder">
                  <Typography
                    theme={theme}
                    className="content-title bold"
                    variant="body2"
                    component="p"
                  >
                    Invoice Amount
                  </Typography>
                  <Typography
                    theme={theme}
                    className="content-title bold"
                    variant="body2"
                    component="p"
                  >
                    {(summaryFields?.amount_due > 0
                      ? (summaryFields?.amount_due -
                          customerInvoice.post_payment_credit_notes_amount) /
                        100
                      : 0
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          {modalOpeningMode === ModalModes.VIEW && creditNotes?.length > 0 && (
            <Grid theme={theme} item xs={12}>
              <InvoiceModelWrap className="table-status">
                <DataTable
                  theme={theme}
                  theadChildren={<></>}
                  tbodyChildren={
                    <>
                      {creditNotes?.map((credit_note) => {
                        return credit_note.lines?.map((line, index) => {
                          return (
                            <TableRow className="child-tr" key={index}>
                              <TableCell className="td">
                                <Box
                                  theme={theme}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 0.4,
                                  }}
                                >
                                  <Link
                                    className="link"
                                    variant="body2"
                                    component={"a"}
                                  >
                                    {credit_note.number}
                                  </Link>
                                  <Typography
                                    theme={theme}
                                    variant="caption"
                                    component="span"
                                  >
                                    {" "}
                                    {line.description}
                                  </Typography>
                                </Box>
                                <Typography
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  className="status"
                                >
                                  {" "}
                                  {credit_note?.reason}
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                <Box theme={theme} className="fg-dark-grey">
                                  -
                                  {(line.amount / 100).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        });
                      })}
                    </>
                  }
                />
              </InvoiceModelWrap>
              <Box theme={theme} sx={{ mb: 2 }}>
                <hr />
              </Box>
            </Grid>
          )}

          <Grid theme={theme} item xs={6}></Grid>
          <Grid theme={theme} item xs={6}>
            <Box theme={theme} className="sub-total">
              <Box theme={theme} className="row">
                <Typography
                  theme={theme}
                  className="content-title bold"
                  variant="body2"
                  component="p"
                >
                  {renderConditionalLabel()}
                </Typography>
                <Typography
                  theme={theme}
                  className="content-title bold"
                  variant="body2"
                  component="p"
                >
                  {
                    modalOpeningMode === ModalModes.CREDIT_NOTE &&
                    summaryFields !== undefined
                      ? (
                          (summaryFields?.amount_due -
                            customerInvoice?.post_payment_credit_notes_amount -
                            summaryFields?.total) /
                          100
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : summaryFields &&
                        summaryFields !== undefined &&
                        customerInvoice !== undefined
                      ? (summaryFields
                          ? (summaryFields?.amount_due -
                              customerInvoice?.post_payment_credit_notes_amount) /
                            100
                          : 0
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : "$0.00" // Return a string, not just the number
                  }
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid theme={theme} item xs={6}></Grid>
          {modalOpeningMode === ModalModes.CREDIT_NOTE &&
            customerInvoice?.invoice_status === InvoiceDisplayStatus.PAID && (
              <Grid theme={theme} item xs={6}>
                <InvoicesWrap>
                  <Box theme={theme} className="invoice-table">
                    <Box theme={theme} className="sub-total">
                      <Box theme={theme} className="row">
                        <Box theme={theme} width={"100%"}>
                          <Box theme={theme} className="inputs" sx={{ mb: 1 }}>
                            <Box
                              theme={theme}
                              className={`${
                                errors?.isCreditOptionSelected?.message
                                  ? "error"
                                  : ""
                              }`}
                            >
                              <Select
                                theme={theme}
                                items={
                                  customerInvoice?.payment_method_last4 ===
                                  undefined
                                    ? creditNoteOptions.filter(
                                        (option) =>
                                          option.value !== "refundAmount"
                                      )
                                    : creditNoteOptions
                                }
                                value={firstSegregation}
                                onChange={(e: any) => {
                                  setFirstSegregation(e?.target?.value);
                                  setSecondSegregation(
                                    creditNoteOptions?.find(
                                      (f) =>
                                        f.value !== e?.target?.value &&
                                        f.value !== SELECT
                                    )?.value
                                  );
                                  handleCreditOptionDropDownChange(e);
                                }}
                                disablePortal={false}
                              />
                              {errors?.isCreditOptionSelected?.message && (
                                <div className="error">
                                  {errors?.isCreditOptionSelected?.message}
                                </div>
                              )}
                              {errors[firstSegregation]?.message && (
                                <div className="error">
                                  {errors[firstSegregation]?.message}
                                </div>
                              )}
                            </Box>
                            {showAlsoCreditToOption ? (
                              <Box
                                theme={theme}
                                sx={{
                                  width: "100%",
                                  textAlign: "end",
                                  marginTop: "7px",
                                }}
                              >
                                <Typography theme={theme} component="p">
                                  {(summaryFields
                                    ? summaryFields?.total / 100
                                    : 0
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </Typography>
                              </Box>
                            ) : (
                              <Box
                                theme={theme}
                                className={`input-price ${
                                  errors[firstSegregation]?.message
                                    ? "error"
                                    : ""
                                }`}
                              >
                                <Input
                                  theme={theme}
                                  fullWidth
                                  type="text"
                                  dynamic={false}
                                  masking={true}
                                  startAdornment={
                                    <Box className="endornment" theme={theme}>
                                      $
                                    </Box>
                                  }
                                  maskCurrency={true}
                                  value={summaryFields?.total / 100}
                                  onBlur={(e) =>
                                    handleValueBlur(e, firstSegregation)
                                  }
                                />
                              </Box>
                            )}
                          </Box>
                          {!showAlsoCreditToOption && (
                            <Box theme={theme} className="inputs">
                              <Box
                                theme={theme}
                                className={`${
                                  errors?.isCreditOptionSelected?.message
                                    ? "error"
                                    : ""
                                }`}
                              >
                                <Select
                                  theme={theme}
                                  placeholder={"Select how to credit"}
                                  items={creditNoteOptions}
                                  value={secondSegregation}
                                  onChange={(e: any) => {
                                    setSecondSegregation(e?.target?.value);
                                    setFirstSegregation(
                                      creditNoteOptions?.find(
                                        (f) =>
                                          f.value !== e?.target?.value &&
                                          f.value !== SELECT
                                      )?.value
                                    );
                                    handleCreditOptionDropDownChange(e);
                                  }}
                                  disablePortal={false}
                                />
                                {errors?.isCreditOptionSelected?.message && (
                                  <div className="error">
                                    {errors?.isCreditOptionSelected?.message}
                                  </div>
                                )}
                                {errors[secondSegregation]?.message && (
                                  <div className="error">
                                    {errors[secondSegregation]?.message}
                                  </div>
                                )}
                              </Box>
                              <Box
                                theme={theme}
                                className={`input-price ${
                                  errors[secondSegregation]?.message
                                    ? "error"
                                    : ""
                                }`}
                              >
                                <Input
                                  theme={theme}
                                  fullWidth
                                  type="text"
                                  dynamic={false}
                                  masking={true}
                                  startAdornment={
                                    <Box className="endornment" theme={theme}>
                                      $
                                    </Box>
                                  }
                                  maskCurrency={true}
                                  onBlur={(e) =>
                                    handleValueBlur(e, secondSegregation)
                                  }
                                />
                              </Box>
                              <Button
                                defaultBtn
                                theme={theme}
                                className="btn-delete"
                                iconText={<Icon name="DeleteIcon" />}
                                onClick={handleSecondSeggregationDelete}
                              />
                            </Box>
                          )}
                          {showAlsoCreditToOption &&
                            customerInvoice?.payment_method_last4 !==
                              undefined && (
                              <Link
                                className="link"
                                variant="body2"
                                component={"a"}
                                onClick={handleAlsoCreditToClick}
                              >
                                Also Credit to
                              </Link>
                            )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </InvoicesWrap>
              </Grid>
            )}
          {customerInvoice?.charge?.amount_refunded > 0 && (
            <Grid theme={theme} item xs={6}>
              <Box theme={theme} className="sub-total">
                <Box theme={theme} className="row">
                  <Typography
                    theme={theme}
                    className="content-title bold"
                    variant="body2"
                    component="p"
                    children="Refund Amount"
                  />
                  <Typography
                    theme={theme}
                    className="content-title bold"
                    variant="body2"
                    component="p"
                    children={(
                      customerInvoice?.charge?.amount_refunded / 100
                    )?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  />
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

const LineItems = ({
  theme,
  stripeCustomerId,
  formValues,
  control,
  fields,
  stripeInvoiceCreditNotes,
  append,
  update,
  setFetchSummaryInfo,
  modalOpeningMode,
  customerInvoice,
  setError,
  clearErrors,
  errors,
  contractData,
  isTaxCalculate,
  setIsTaxCalculate,
  setIsTaxCalculateError,
  setIsTaxApisLoading,
  setDeleteConfirmationDialog,
  setDeleteIndex,
}) => {
  const { mutate: getStripeProducts, isLoading: getStripeProductsLoading } =
    useStripeProductSearch();
  const { mutate: getStripePrices, isLoading: getStripePricesLoading } =
    useStripePriceList();
  const { mutate: saveStripePrice, isLoading: saveStripePriceLoading } =
    useSaveStripePrice();
  const { mutate: saveInvoiceItem, isLoading: saveInvoiceItemLoading } =
    useSaveInvoiceItem();
  const { mutate: getTaxAgainstStripeProduct, isLoading: isTaxLoading } =
    useGetTaxAgainstStripeProduct();
  const { mutate: updateInvoice } = useUpdateInvoice();

  const [stripeProducts, setStripeProducts] = useState<any[]>();
  const [stripeProductPrices, setStripeProductPrices] = useState<any[]>();
  const [creditNoteInvoiceLineItems, setCreditNoteInvoiceLineItems] =
    useState<any[]>();

  const { actions } = useStoreContext();

  useEffect(() => {
    setIsTaxApisLoading(
      isTaxLoading || saveInvoiceItemLoading || saveStripePriceLoading
    );
  }, [isTaxLoading, saveInvoiceItemLoading, saveStripePriceLoading]);

  useEffect(() => {
    if (stripeInvoiceCreditNotes) {
      setCreditNoteInvoiceLineItems(
        stripeInvoiceCreditNotes?.reduce(
          (result, item) => [
            ...result,
            ...item?.lines?.data?.filter((f) => f.type === "invoice_line_item"),
          ],
          []
        ) ?? []
      );
    }
  }, [stripeInvoiceCreditNotes]);

  const updateFieldInLoop = async () => {
    for (let index = 0; index < formValues?.lineItems?.length; index++) {
      const item = formValues?.lineItems?.[index];
      const creditNoteInvoiceItem = creditNoteInvoiceLineItems?.filter(
        (f) => f.invoice_line_item === item.id
      );
      const creditedAmount =
        creditNoteInvoiceItem?.length > 0
          ? creditNoteInvoiceItem?.reduce(
              (accumulator, currentItem) => accumulator + currentItem.amount,
              0
            )
          : 0;
      await update(index, {
        ...item,
        creditedAmount: creditedAmount ?? 0,
        productAmount: item.productAmount - (creditedAmount ?? 0),
        creditAmount:
          item.productAmount - (creditedAmount ?? 0) > 0
            ? (item.productAmount - (creditedAmount ?? 0)) / 100
            : 0,
      });
    }
    setFetchSummaryInfo(true);
  };

  useEffect(() => {
    if (modalOpeningMode === ModalModes.CREDIT_NOTE) {
      if (
        creditNoteInvoiceLineItems &&
        (customerInvoice?.post_payment_credit_notes_amount !== 0 ||
          customerInvoice?.pre_payment_credit_notes_amount !== 0)
      ) {
        updateFieldInLoop();
      } else if (
        customerInvoice?.post_payment_credit_notes_amount === 0 &&
        customerInvoice?.pre_payment_credit_notes_amount === 0
      ) {
        updateFieldInLoop();
      }
    }
  }, [creditNoteInvoiceLineItems, modalOpeningMode]);

  useEffect(() => {
    if (isTaxCalculate) {
      calculateTax();
    }
  }, [isTaxCalculate, formValues.lineItems.length]);

  useEffect(() => {
    if (
      formValues.lineItems.length > 0 &&
      customerInvoice?.invoice_status === InvoiceDisplayStatus.DRAFT &&
      formValues.lineItems.find(
        (f) =>
          f.productId !== SELECT &&
          f.producAmount !== SELECT &&
          modalOpeningMode !== ModalModes.VIEW
      )
    ) {
      calculateTax();
    }
  }, []);

  const handleLineItemDropdownOpen = () => {
    let metadata = "";
    if (
      contractData?.final_bill_invoice_id === customerInvoice?.id &&
      contractData?.returns?.return_type === ReturnTypes.EARLY_RETURN
    ) {
      metadata = StripeMetaData.EARLY_RETURN;
    } else if (
      contractData?.final_bill_invoice_id === customerInvoice?.id &&
      contractData?.returns?.return_type === ReturnTypes.STANDARD_RETURN
    ) {
      metadata = StripeMetaData.STANDARD_RETURN;
    } else {
      metadata = StripeMetaData.AD_HOC_INVOICE;
    }
    getStripeProducts(
      { query_params: `query=metadata["${metadata}"]:"true"&limit=100` },
      {
        onSuccess(data) {
          setStripeProducts(data);
        },
        onError(error: any) {
          console.log("Error Occured", error);
        },
      }
    );
  };

  const handleLineItemDropdownChange = (product_id, index) => {
    getStripePrices(
      { product: product_id },
      {
        onSuccess({ data }) {
          setStripeProductPrices(data?.data);
          const lineItems = formValues?.lineItems;
          const selectedProduct =
            product_id !== SELECT
              ? stripeProducts?.find((f) => f.id === product_id)
              : null;
          update(index, {
            ...lineItems[index],
            productName: selectedProduct ? selectedProduct.name : "",
            productAmountId: SELECT,
            productAmount: SELECT,
          });
        },
        onError(error: any) {
          console.log("Error Occured", error);
        },
      }
    );
  };

  const handleLineItemPriceChange = (price_id, index) => {
    const lineItems = formValues?.lineItems;
    const selectedProductPrice =
      price_id !== SELECT
        ? stripeProductPrices?.find((f) => f.id === price_id)
        : null;

    const isValidPrice =
      selectedProductPrice &&
      selectedProductPrice.unit_amount_decimal > 0 &&
      selectedProductPrice.unit_amount_decimal <= 99999999;

    update(index, {
      ...lineItems[index],
      productAmount: isValidPrice
        ? selectedProductPrice.unit_amount_decimal
        : SELECT,
    });
  };

  const handleRemoveNewPriceClick = (index) => {
    const lineItems = formValues?.lineItems;
    const selectedProductPrice =
      lineItems[index].productAmountId !== SELECT
        ? stripeProductPrices?.find(
            (f) => f.id === lineItems[index].productAmountId
          )
        : null;
    update(index, {
      ...lineItems[index],
      addNewPrice: false,
      productAmount: selectedProductPrice
        ? selectedProductPrice.unit_amount_decimal
        : SELECT,
    });
  };

  const handleSaveLineItemClick = (index) => {
    const productAmount = formValues?.lineItems[index]?.productAmount;
    const amount = productAmount.includes(".")
      ? productAmount
      : productAmount / 100;
    if (amount <= 0) {
      actions.setToast({
        toastMessage:
          "The amount of invoice line item should be greater than zero",
        toastState: true,
        variant: "error",
      });
    } else if (amount <= 999999.99) {
      calculateTax("save", index);
    } else {
      actions.setToast({
        toastMessage: "This value must be less than or equal to 999,999.99",
        toastState: true,
        variant: "error",
      });
    }
  };

  const handleSaveLineItem = (index) => {
    const lineItems = formValues?.lineItems;
    const descriptionData = formValues?.lineItems[index]?.description
      ? formValues?.lineItems[index]?.productName +
        "-" +
        formValues?.lineItems[index]?.description
      : formValues?.lineItems[index]?.productName;
    const descriptionLength = descriptionData?.valueOf()?.length;
    const productAmount = formValues?.lineItems[index]?.productAmount;
    const amount = productAmount.includes(".")
      ? productAmount
      : productAmount / 100;
    if (lineItems[index].addNewPrice && parseFloat(amount) <= 999999.99) {
      saveStripePrice(
        {
          currency: "usd",
          unit_amount_decimal:
            lineItems[index].productAmount && lineItems[index].productAmount > 0
              ? Math.round(lineItems[index].productAmount * 100)
              : 0,
          product: lineItems[index].productId,
        },
        {
          onSuccess({ data: stripePrice }) {
            if (descriptionLength !== null && descriptionLength <= 500) {
              if (productAmount != 0) {
                saveInvoiceItem(
                  {
                    customer: lineItems[index].invoice_item
                      ? undefined
                      : stripeCustomerId,
                    invoice: lineItems[index].invoice_item
                      ? undefined
                      : formValues?.invoiceId,
                    invoice_item: lineItems[index].invoice_item
                      ? lineItems[index].invoice_item
                      : undefined,
                    price: stripePrice?.id,
                    description: descriptionData,
                  },
                  {
                    onSuccess({ data: invoice_item }) {
                      update(index, {
                        ...lineItems[index],
                        modify: false,
                        addNewPrice: false,
                        productAmount: stripePrice?.unit_amount_decimal,
                        productAmountId: stripePrice?.id,
                        invoice_item:
                          lineItems[index].invoice_item || invoice_item?.id,
                      });
                      setFetchSummaryInfo(true);
                      // calculateTax();
                      // setIsTaxCalculate(true);
                    },
                    onError(error: any) {
                      console.log("Error Occured", error);
                    },
                  }
                );
              } else {
                actions.setToast({
                  toastMessage:
                    "The amount of invoice line item should be greater than zero",
                  toastState: true,
                  variant: "error",
                });
              }
            } else {
              setError("lineItems.${index}.description", {
                message:
                  "Invoice Additional Description must be at most 500 characters",
              });
              actions.setToast({
                toastMessage:
                  "Invoice Additional Description must be at most 500 characters",
                toastState: true,
                variant: "error",
              });
            }
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error?.detail?.message?.toString(),
              toastState: true,
              variant: "error",
            });
          },
        }
      );
    } else {
      if (descriptionLength !== null && descriptionLength <= 500) {
        if (productAmount != 0 && parseFloat(amount) <= 999999.99) {
          saveInvoiceItem(
            {
              customer: lineItems[index].invoice_item
                ? undefined
                : stripeCustomerId,
              invoice: lineItems[index].invoice_item
                ? undefined
                : formValues?.invoiceId,
              invoice_item: lineItems[index].invoice_item
                ? lineItems[index].invoice_item
                : undefined,
              price: lineItems[index].productAmountId,
              description: descriptionData,
            },
            {
              onSuccess({ data }) {
                update(index, {
                  ...lineItems[index],
                  modify: false,
                  invoice_item: lineItems[index].invoice_item || data?.id,
                });
                setFetchSummaryInfo(true);
                // calculateTax();
                // setIsTaxCalculate(true);
              },
              onError(error: any) {
                console.log("Error Occured", error);
              },
            }
          );
        } else {
          actions.setToast({
            toastMessage: "This value must be less than or equal to 999,999.99",
            toastState: true,
            variant: "error",
          });
        }
      } else {
        setError("lineItems.${index}.description", {
          message:
            "Invoice Additional Description must be at most 500 characters",
        });
        actions.setToast({
          toastMessage:
            "Invoice Additional Description must be at most 500 characters",
          toastState: true,
          variant: "error",
        });
      }
    }
  };

  const handleEditLineItemClick = (index) => {
    const lineItems = formValues?.lineItems;
    getStripeProducts(
      {
        query_params: `query=metadata["${StripeMetaData.AD_HOC_INVOICE}"]:"true"&limit=100`,
      },
      {
        onSuccess(data) {
          setStripeProducts(data);
        },
        onError(error: any) {
          console.log("Error Occured", error);
        },
      }
    );
    getStripePrices(
      { product: lineItems[index].productId },
      {
        onSuccess({ data }) {
          setStripeProductPrices(data?.data);
        },
        onError(error: any) {
          console.log("Error Occured", error);
        },
      }
    );
    update(index, {
      ...lineItems[index],
      modify: true,
    });
  };

  const handleAddNewPriceClick = (index) => {
    const lineItems = formValues?.lineItems;
    update(index, {
      ...lineItems[index],
      addNewPrice: true,
      productAmount: null,
    });
  };

  const taxAPIErrorMessageHandling = (message: string) => {
    const errorMessageString = message; //data?.data?.errorMessage?.toString();
    let missingProductIds = errorMessageString
      .substring(
        errorMessageString.indexOf("[") + 1,
        errorMessageString.lastIndexOf("]")
      )
      ?.split(",");
    missingProductIds = missingProductIds?.map((s) => s.trim());
    const missingProductNames = stripeProducts
      .filter((item) => missingProductIds.includes(item?.id))
      ?.map((item) => item.name);
    return missingProductNames ? missingProductNames.join(", ") : "";
  };
  const calculateTax = (clickMode = "", lineItemIndex = 0) => {
    const lineItems = formValues?.lineItems?.filter(
      (f) => f?.metadata?.[StripeMetaData.TAX_CHARGE] != "True"
    );
    const customerAddress =
      contractData?.customer?.customer_addresses?.find(
        (f) => f.address_type === "Residential"
      ) ?? {};
    getTaxAgainstStripeProduct(
      {
        lines:
          lineItems?.length > 0
            ? lineItems?.map((item, index) => ({
                productId: `${item?.productId}_${index}`, // prod_OHTk9Y814Y7oO5,
                productType: item?.productName
                  ?.trim()
                  ?.toUpperCase()
                  ?.replace(/ /g, "_"),
                price:
                  modalOpeningMode === ModalModes.CREDIT_NOTE
                    ? item?.creditAmount * 100
                    : item.addNewPrice
                    ? Math.round(item?.productAmount * 100)
                    : item?.productAmount,
              }))
            : [],
        vin: contractData?.assets?.[0]?.vin ?? "",
        address: {
          city: customerAddress?.city ?? "",
          state: customerAddress?.state_name ?? "",
          line1: customerAddress?.address_line_1 ?? "",
          line2: customerAddress?.address_line_2 ?? "",
          zipCode: customerAddress?.zip_code ?? "", // "90003"
        },
      },
      {
        onSuccess(data) {
          if (data?.data?.errorCode) {
            switch (data?.data?.errorCode) {
              case "MPC001":
                /* eslint-disable no-case-declarations */
                const missingProductsName = taxAPIErrorMessageHandling(
                  data?.data?.errorMessage?.toString()
                );
                actions.setToast({
                  toastMessage: missingProductsName
                    ? `Product ${missingProductsName} does not exist`
                    : "An error occurred while calculating tax",
                  toastState: true,
                  variant: "error",
                });
                break;
              default:
                actions.setToast({
                  toastMessage: data?.data?.errorMessage?.toString(),
                  toastState: true,
                  variant: "error",
                });
                break;
            }
            setFetchSummaryInfo(true);
            setIsTaxCalculateError(true);
          } else {
            const totalTaxAmount = data?.data?.totalTaxAmount;
            const quoteId = data?.data?.quoteId;
            const taxLineItemIndex = formValues?.lineItems?.findIndex(
              (f) => f?.metadata?.[StripeMetaData.TAX_CHARGE] === "True"
            );
            if (taxLineItemIndex > -1) {
              if (modalOpeningMode === ModalModes.CREDIT_NOTE) {
                update(taxLineItemIndex, {
                  ...formValues?.lineItems[taxLineItemIndex],
                  creditAmount: totalTaxAmount / 100,
                });
                updateInvoice({
                  invoice_id: formValues?.invoiceId,
                  metadata: {
                    quote_id: quoteId,
                  },
                });
                setFetchSummaryInfo(true);
                setIsTaxCalculateError(false);
              } else {
                addTaxPriceAndLineItem(
                  lineItemIndex,
                  clickMode,
                  formValues?.lineItems[taxLineItemIndex]?.productId,
                  totalTaxAmount,
                  quoteId,
                  taxLineItemIndex
                );
              }
            } else {
              getStripeProducts(
                {
                  query_params: `query=metadata["${StripeMetaData.TAX_CHARGE}"]:"true"&limit=100`,
                },
                {
                  onSuccess(data) {
                    addTaxPriceAndLineItem(
                      lineItemIndex,
                      clickMode,
                      data?.[0]?.id,
                      totalTaxAmount,
                      quoteId
                    );
                  },
                  onError(error: any) {
                    console.log("Error Occured", error);
                    setFetchSummaryInfo(true);
                    setIsTaxCalculateError(true);
                    actions.setToast({
                      toastMessage: "An error occurred while calculating tax",
                      toastState: true,
                      variant: "error",
                    });
                  },
                }
              );
            }
          }
        },
        onError(error: any) {
          console.log("Error Occured", error);
          setFetchSummaryInfo(true);
          setIsTaxCalculateError(true);
          actions.setToast({
            toastMessage: "An error occurred while calculating tax",
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const addTaxPriceAndLineItem = (
    lineItemIndex,
    clickMode,
    productId,
    amount,
    quoteId,
    index = -1
  ) => {
    saveStripePrice(
      {
        currency: "usd",
        unit_amount_decimal: amount,
        product: productId,
      },
      {
        onSuccess({ data: stripePrice }) {
          saveInvoiceItem(
            {
              customer: formValues?.lineItems[index]?.invoice_item
                ? undefined
                : stripeCustomerId,
              invoice: formValues?.lineItems[index]?.invoice_item
                ? undefined
                : formValues?.invoiceId,
              invoice_item: formValues?.lineItems[index]?.invoice_item
                ? formValues?.lineItems[index].invoice_item
                : undefined,
              price: stripePrice?.id,
              metadata: {
                [StripeMetaData.TAX_CHARGE]: true,
              },
            },
            {
              onSuccess({ data: invoice_item }) {
                // setIsTaxCalculate(false)
                if (clickMode === "save") {
                  handleSaveLineItem(lineItemIndex);
                }
                if (index > -1) {
                  update(index, {
                    ...formValues?.lineItems[index],
                    invoice_item: invoice_item?.id,
                    productAmountId: invoice_item?.price?.id,
                    productAmount: invoice_item?.amount,
                  });
                } else {
                  append({
                    invoice_item: invoice_item?.id,
                    productId: invoice_item?.price?.product,
                    productName: invoice_item?.description,
                    description: invoice_item?.description,
                    productAmountId: invoice_item?.price?.id,
                    productAmount: invoice_item?.amount,
                    metadata: invoice_item?.metadata,
                  });
                }
                updateInvoice({
                  invoice_id: formValues?.invoiceId,
                  metadata: {
                    quote_id: quoteId,
                  },
                });
                setFetchSummaryInfo(true);
                setIsTaxCalculateError(false);
              },
              onError(error: any) {
                console.log("Error Occured", error);
                setFetchSummaryInfo(true);
                setIsTaxCalculateError(true);
                actions.setToast({
                  toastMessage: "An error occurred while calculating tax",
                  toastState: true,
                  variant: "error",
                });
              },
            }
          );
        },
        onError(error: any) {
          console.log("Error Occured", error);
          setFetchSummaryInfo(true);
          setIsTaxCalculateError(true);
          actions.setToast({
            toastMessage: "An error occurred while calculating tax",
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const lineItemActionButtons = (index) => {
    switch (true) {
      case !(
        saveInvoiceItemLoading ||
        saveStripePriceLoading ||
        isTaxLoading
      ) &&
        formValues?.lineItems[index].modify &&
        formValues?.lineItems[index].addNewPrice:
        return (
          <>
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="CloseBlack" />}
              onClick={() => {
                handleRemoveNewPriceClick(index);
              }}
            ></Button>
            {formValues?.lineItems[index].productAmount && (
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="TickIcon" />}
                onClick={() => {
                  handleSaveLineItemClick(index);
                }}
              ></Button>
            )}
          </>
        );
      case !(
        saveInvoiceItemLoading ||
        saveStripePriceLoading ||
        isTaxLoading
      ) &&
        formValues?.lineItems[index].modify &&
        formValues?.lineItems[index].productId !== SELECT &&
        formValues?.lineItems[index].productAmountId !== SELECT:
        return (
          <Button
            theme={theme}
            defaultBtn
            iconText={<Icon name="TickIcon" />}
            onClick={() => {
              handleSaveLineItemClick(index);
            }}
          ></Button>
        );
      case (saveInvoiceItemLoading || saveStripePriceLoading || isTaxLoading) &&
        formValues?.lineItems[index].modify:
        return <CircleLoader theme={theme} size="xs" />;
    }
  };
  return (
    <DataTable
      theme={theme}
      theadChildren={
        <>
          <TableRow>
            <TableCell className="desc">Description</TableCell>
            <TableCell className="desc">Additional Details</TableCell>
            <TableCell>Amount</TableCell>
            {modalOpeningMode === ModalModes.CREDIT_NOTE ? (
              <TableCell>Credit Amount</TableCell>
            ) : (
              modalOpeningMode !== ModalModes.VIEW && (
                <TableCell className="action-cell fixed-cell space-fixed"></TableCell>
              )
            )}
          </TableRow>
        </>
      }
      tbodyChildren={
        <>
          {fields?.map((field, index) => {
            return (
              <TableRow className="child-tr" key={field.id}>
                <TableCell>
                  {formValues?.lineItems[index].modify ? (
                    <Controller
                      name={`lineItems.${index}.productId`}
                      control={control}
                      defaultValue={SELECT}
                      render={({ field: { value, onChange } }) => (
                        <Select
                          disablePortal={false}
                          theme={theme}
                          value={value}
                          onOpen={handleLineItemDropdownOpen}
                          onChange={(e) => {
                            onChange(e);
                            handleLineItemDropdownChange(
                              e?.target?.value,
                              index
                            );
                          }}
                          isLoading={getStripeProductsLoading}
                          items={
                            stripeProducts
                              ? [
                                  { text: "Select", value: SELECT },
                                  ...stripeProducts.map((item) => ({
                                    text: item.name,
                                    value: item.id,
                                  })),
                                ]
                              : [{ text: "Select", value: SELECT }]
                          }
                        />
                      )}
                    />
                  ) : (
                    formValues?.lineItems[index].productName
                  )}
                </TableCell>
                <TableCell>
                  {formValues?.lineItems[index].modify ? (
                    <Controller
                      name={`lineItems.${index}.description`}
                      control={control}
                      render={({ field }) => (
                        <Textarea
                          id={""}
                          theme={theme}
                          {...field}
                          label={"Additional Details"}
                          type="text"
                          placeholder="Write a Additional Details"
                          rows={1}
                          fullWidth
                          onBlur={() => {
                            clearErrors("lineItems.${index}.description");
                          }}
                        />
                      )}
                    />
                  ) : (
                    formValues?.lineItems[index].description
                  )}
                </TableCell>
                <TableCell className="editable-cell">
                  <Box theme={theme} className="editable-field-wrap">
                    {formValues?.lineItems[index].modify ? (
                      formValues?.lineItems[index].addNewPrice ? (
                        <Controller
                          name={`lineItems.${index}.productAmount`}
                          control={control}
                          render={({ field }) => (
                            <Input
                              theme={theme}
                              fullWidth
                              type="decimal"
                              placeholder="Type here..."
                              {...field}
                              maskNumeric
                              masking
                              maskDecimalScale={2}
                            />
                          )}
                        />
                      ) : (
                        <Controller
                          name={`lineItems.${index}.productAmountId`}
                          control={control}
                          defaultValue={SELECT}
                          render={({ field: { value, onChange } }) => (
                            <Select
                              disablePortal={false}
                              theme={theme}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                handleLineItemPriceChange(
                                  e?.target?.value,
                                  index
                                );
                              }}
                              isLoading={getStripePricesLoading}
                              items={
                                stripeProductPrices
                                  ? [
                                      { text: "Select", value: SELECT },
                                      {
                                        text: (
                                          <Link
                                            className="link"
                                            variant="body2"
                                            component={"a"}
                                            onClick={() => {
                                              handleAddNewPriceClick(index);
                                            }}
                                          >
                                            Add new price
                                          </Link>
                                        ),
                                        value: undefined,
                                      },
                                      ...stripeProductPrices.map((item) => ({
                                        text: (item.unit_amount_decimal > 0
                                          ? item.unit_amount_decimal / 100
                                          : 0
                                        )?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        }),
                                        value: item.id,
                                      })),
                                    ]
                                  : [{ text: "Select", value: SELECT }]
                              }
                            />
                          )}
                        />
                      )
                    ) : formValues?.lineItems[index].productAmount ===
                      SELECT ? (
                      "-"
                    ) : (
                      (formValues?.lineItems[index].productAmount > 0
                        ? formValues?.lineItems[index].productAmount / 100
                        : 0
                      )?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    )}

                    <Box theme={theme} className="action-btn">
                      {lineItemActionButtons(index)}
                    </Box>
                  </Box>
                </TableCell>
                {modalOpeningMode === ModalModes.CREDIT_NOTE ? (
                  <TableCell className="editable-cell">
                    {formValues?.lineItems[index]?.metadata[
                      StripeMetaData.TAX_CHARGE
                    ] === "True" ? (
                      (formValues?.lineItems[index].creditAmount > 0
                        ? formValues?.lineItems[index].creditAmount
                        : 0
                      )?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    ) : (
                      <Controller
                        name={`lineItems.${index}.creditAmount`}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Input
                            theme={theme}
                            fullWidth
                            masking={true}
                            startAdornment={
                              <Box className="endornment" theme={theme}>
                                $
                              </Box>
                            }
                            maskCurrency={true}
                            value={value}
                            onChange={(value) => {
                              onChange(value);
                              if (
                                parseInt(value) >
                                formValues?.lineItems[index].productAmount / 100
                              ) {
                                setError(`lineItems.${index}.creditAmount`, {
                                  message:
                                    "Amount should be less then due amount",
                                });
                              } else {
                                clearErrors(`lineItems.${index}.creditAmount`);
                                clearErrors("lineItems.creditAmount");
                              }
                            }}
                            type="text"
                            onBlur={() => {
                              if (
                                parseInt(value) <=
                                formValues?.lineItems[index].productAmount / 100
                              ) {
                                // setFetchSummaryInfo(true);
                                // calculateTax();
                                setIsTaxCalculate(true);
                              }
                            }}
                            error={
                              errors?.lineItems?.[index]?.creditAmount?.message
                            }
                            {...field}
                          />
                        )}
                      />
                    )}
                  </TableCell>
                ) : (
                  <TableCell className="action-cell fixed-cell space-fixed">
                    {modalOpeningMode !== ModalModes.VIEW &&
                      !(
                        formValues?.lineItems[index]?.metadata?.[
                          StripeMetaData.TAX_CHARGE
                        ] === "True"
                      ) &&
                      !(
                        formValues?.lineItems[index]?.metadata?.[
                          StripeMetaData.EARLY_TERMINATION_FEE
                        ] === "true"
                      ) && (
                        <Menu
                          theme={theme}
                          options={[
                            {
                              optionText: "Edit",
                              optionValue: "edit",
                              disabled: formValues?.lineItems?.some(
                                (s) => s.modify === true
                              ),
                            },
                            {
                              optionText: "Delete",
                              optionValue: "delete",
                            },
                          ]}
                          handleOptionClick={(_event, _key, value) => {
                            switch (value) {
                              case "delete":
                                setDeleteConfirmationDialog(true);
                                setDeleteIndex(index);
                                break;
                              case "edit":
                                handleEditLineItemClick(index);
                                break;
                              case "view":
                                break;
                            }
                          }}
                          render={(onMenuSelection) => (
                            <Button
                              defaultBtn
                              theme={theme}
                              iconText={<Icon name="MoreIcon" />}
                              onClick={onMenuSelection}
                            />
                          )}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        />
                      )}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </>
      }
    />
  );
};

export const InvoiceModalComponent: FC<{
  openInvoiceDialog: boolean;
  setOpenInvoiceDialog;
  modalOpeningMode: ModalModes;
  setModalOpeningMode: any;
  contractData: any;
  customerInvoice: ICustomerInvoice;
  handleDeleteInvoice?: any;
  handleOpenInvoiceDialog?: any;
  handleVoidInvoice?: any;
  handleOpenHistoryDialog?: any;
  editInvoice?: boolean;
  deletePInvoice?: boolean;
  voidPInvoice?: boolean;
  creditPInvoice?: boolean;
  setCustomerInvoice?: any;
  isSendInvoiceAllow?: boolean;
  handleOpenRefundDialog?: any;
}> = ({
  openInvoiceDialog,
  setOpenInvoiceDialog,
  modalOpeningMode,
  setModalOpeningMode,
  contractData,
  customerInvoice,
  handleDeleteInvoice,
  handleVoidInvoice,
  handleOpenInvoiceDialog,
  handleOpenHistoryDialog,
  editInvoice,
  deletePInvoice,
  setCustomerInvoice,
  voidPInvoice,
  creditPInvoice,
  handleOpenRefundDialog,
}) => {
  interface IFormInputs {
    invoiceId: string;
    invoiceStatus: string;
    createdDate: Date;
    duedate: Date;
    memo: string;
    internal_notes: string;
    creditAmount: string;
    refundAmount: string;
    isCreditOptionSelected: boolean;
    creditNoteReason?: string;
    dueAmount?: string;
    lineItems: {
      id: string;
      invoice_item: string;
      productId: string;
      productName: string;
      productAmountId: string;
      productAmount: string;
      metadata: {};
      creditedAmount?: string;
      creditAmount?: string;
      modify: boolean;
      addNewPrice: boolean;
      description: string;
    }[];
  }
  const { actions } = useStoreContext();
  const theme = useTheme();
  const sendInvoicePermission = useHasPermissions(
    ccpPermissions.FIELDS.DISABLE_SEND_INVOICE_BUTTON,
    PermissionTypes.DISABLED
  );
  const historyInvoicePermission = useHasPermissions(
    ccpPermissions.FIELDS.INVOICE_HISTORY,
    PermissionTypes.DISABLED
  );
  const refundPermission = useHasPermissions(
    ccpPermissions.FIELDS.DISABLE_REFUND,
    PermissionTypes.VIEW
  );
  const [fetchSummaryInfo, setFetchSummaryInfo] = useState<boolean>(false);
  const [closeInvoiceDialog, setCloseInvoiceDialog] = useState<boolean>(false);
  const [stripeInvoiceCreditNotes, setStripeInvoiceCreditNotes] =
    useState<any[]>();
  const [sendInvoiceDialog, setSendInvoiceDialog] = useState<boolean>(false);
  const [isSendInvoiceAllow, setIsSendInvoiceAllow] = useState(false);
  const [isTaxCalculate, setIsTaxCalculate] = useState<boolean>(false);
  const [isTaxCalculateError, setIsTaxCalculateError] =
    useState<boolean>(false);
  const [isRefundAllow, setIsRefundAllow] = useState(false);
  const [isInvoiceHistoryAllow, setIsInvoiceHistoryAllow] = useState(false);
  const [invoiceTaxAmount, setInvoiceTaxAmount] = useState(0);
  const [isTaxApisLoading, setIsTaxApisLoading] = useState(0);
  const [isDueDateEdit, setDueDateEditComponent] = useState(false);
  const [isMemoEdit, setIsMemoEditComponent] = useState(false);
  const [isInternalNoteEdit, setIsInternalNoteEditComponent] = useState(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const dueDatePermission = useHasPermissions(
    ccpPermissions.FIELDS.DISABLE_DUE_DATE,
    PermissionTypes.DISABLED
  );
  const [isDueDateEditAllow, setIsDueDateEditAllow] = useState(false);

  const isSubscriptionPaused =
    contractData?.subscription_status === SubscriptionStatus.PAUSED;
  const isFinalBillInvoice =
    contractData?.final_bill_invoice_id === customerInvoice?.id;
  const form = useForm<IFormInputs>({
    defaultValues: {
      invoiceId: customerInvoice?.id,
      invoiceStatus: customerInvoice?.invoice_status,
      createdDate: customerInvoice?.created_date
        ? new Date(
            utcToLocal(
              new Date(customerInvoice?.created_date * 1000).toISOString(),
              dateFormatSearch
            )
          )
        : null,
      duedate: customerInvoice?.due_date
        ? new Date(
            utcToLocal(
              new Date(customerInvoice?.due_date * 1000).toISOString(),
              dateFormatSearch
            )
          )
        : new Date(new Date().setDate(new Date().getDate() + 10)),
      memo: customerInvoice?.description ?? "",
      internal_notes: customerInvoice?.metadata?.internal_notes ?? "",
      isCreditOptionSelected:
        customerInvoice?.invoice_status !== InvoiceDisplayStatus.PAID,

      lineItems: customerInvoice?.lines?.map((line) => {
        return {
          id: line.id,
          invoice_item: line.invoice_item,
          productId: line.product_id,
          productName: line?.description?.split("-")[0],
          description: line?.description?.split("-")[1],
          productAmountId: line.price_id,
          productAmount: line.amount?.toString(),
          metadata: line.metadata,
          creditedAmount: "0",
          creditAmount: "0",
          modify: false,
          addNewPrice: false,
        };
      }),
    },
  });
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = form;
  const { fields, append, remove, update, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "lineItems",
  });
  const formValues = watch();
  const { mutate: saveInvoice, isLoading: saveInvoiceLoading } =
    useSaveInvoice();
  const { mutate: updateInvoice, isLoading: updateInvoiceLoading } =
    useUpdateInvoice();
  const { mutate: deleteInvoice, isLoading: deleteInvoiceLoading } =
    useDeleteStripeInvoice();
  const { mutate: sendInvoice, isLoading: sendInvoiceLoading } =
    useSendStripeInvoice();
  const { mutate: getStripeInvoiceCreditNotes } = useStripeInvoiceCreditNotes();
  const { mutate: saveCreditNotes, isLoading: saveCreditNotesLoading } =
    useSaveCreditNote();
  const { mutate: proceedReturnRequest } = useProceedReturnRequest();
  const { mutateAsync: getStripeProductsById } = useGetStripeProductById();
  const { mutate: deleteInvoiceItem, isLoading: deleteLineItemLoading } =
    useDeleteInvoiceItem();

  useEffect(() => {
    setIsSendInvoiceAllow(sendInvoicePermission);
  }, [sendInvoicePermission]);

  useEffect(() => {
    setIsDueDateEditAllow(dueDatePermission);
  }, [dueDatePermission]);
  useEffect(() => {
    setIsRefundAllow(refundPermission);
  }, [refundPermission]);
  useEffect(() => {
    setIsInvoiceHistoryAllow(historyInvoicePermission);
  }, [historyInvoicePermission]);

  useEffect(() => {
    console.log("customerInvoice: ", customerInvoice);
    if (
      customerInvoice &&
      (customerInvoice?.post_payment_credit_notes_amount !== 0 ||
        customerInvoice?.pre_payment_credit_notes_amount !== 0)
    ) {
      getStripeInvoiceCreditNotes(
        {
          invoice_id: formValues?.invoiceId,
          credit_notes_status: "issued",
        },
        {
          onSuccess({ data }) {
            setStripeInvoiceCreditNotes(data?.data);
          },
          onError(error: any) {
            console.log("Error Occured", error);
          },
        }
      );
    }
  }, [customerInvoice]);

  const handleMemoOnClick = () => {
    const memoData = getValues("memo");
    if (memoData) {
      const memoLength = memoData?.valueOf()?.length;
      if (memoLength !== null && memoLength <= 500) {
        if (getValues("invoiceId")) {
          updateInvoice(
            {
              invoice_id: getValues("invoiceId"),
              description: getValues("memo") ? getValues("memo") : undefined,
            },
            {
              onSuccess() {
                setIsMemoEditComponent(false);
                actions.setToast({
                  toastMessage: "Invoice Memo updated",
                  toastState: true,
                });
              },
              onError() {
                setError("memo", {
                  message: "Invoice Memo must be at most 500 characters",
                });
                actions.setToast({
                  toastMessage:
                    "Cannot update memo in the invoice. Error details: Invoice Memo must be at most 500 characters",
                  toastState: true,
                  variant: "error",
                });
              },
            }
          );
        } else {
          handleSaveInvoice();
        }
      } else {
        setError("memo", {
          message: "Invoice Memo must be at most 500 characters",
        });
        actions.setToast({
          toastMessage:
            "Cannot update memo in the invoice. Error details: Invoice Memo must be at most 500 characters",
          toastState: true,
          variant: "error",
        });
      }
    }
  };

  const handleInternalNotesOnClick = () => {
    const internalNotesData = getValues("internal_notes");
    if (internalNotesData) {
      const internalNotesLength = internalNotesData?.valueOf()?.length;
      if (internalNotesLength !== null && internalNotesLength <= 500) {
        if (getValues("invoiceId")) {
          updateInvoice(
            {
              invoice_id: getValues("invoiceId"),
              metadata: {
                internal_notes: getValues("internal_notes")
                  ? getValues("internal_notes")
                  : undefined,
              },
            },
            {
              onSuccess() {
                setIsInternalNoteEditComponent(false);
                actions.setToast({
                  toastMessage: "Internal notes are updated",
                  toastState: true,
                });
              },
              onError() {
                setError("internal_notes", {
                  message: "Internal notes must be at most 500 characters",
                });
                actions.setToast({
                  toastMessage:
                    "Cannot update internal notes in the invoice. Error details: Internal notes must be at most 500 characters",
                  toastState: true,
                  variant: "error",
                });
              },
            }
          );
        } else {
          handleSaveInvoice();
        }
      } else {
        setError("internal_notes", {
          message: "Internal notes must be at most 500 characters",
        });
        actions.setToast({
          toastMessage:
            "Cannot update internal notes in the invoice. Error details: Internal notes must be at most 500 characters",
          toastState: true,
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (
      customerInvoice?.invoice_type === InvoiceDisplayType.FIRST_INVOICE ||
      customerInvoice?.invoice_type === InvoiceDisplayType.MONTHLY_INVOICE
    ) {
      stripeProducts();
    }
  }, [customerInvoice]);
  const stripeProducts = async () => {
    const invoiceItemPromise = customerInvoice?.lines?.map(
      async (invoiceItem) => {
        return getStripeProductsById({ product_id: invoiceItem?.product_id });
      }
    );
    const products = await Promise.all(invoiceItemPromise);
    const invoiceTaxAmountSum = products?.reduce(
      async (accumulatorPromise, stripeProduct) => {
        const accumulator = await accumulatorPromise;
        if (stripeProduct?.metadata?.tax === "true") {
          const productAmount =
            customerInvoice?.lines?.find(
              (f) => f.product_id === stripeProduct.id
            )?.amount || 0;
          return accumulator + productAmount;
        }
        return accumulator;
      },
      Promise.resolve(0)
    );
    // Wait for the sum to be calculated
    const totalInvoiceTaxAmount = await invoiceTaxAmountSum;
    // Now you can use the totalInvoiceTaxAmount as needed
    setInvoiceTaxAmount(invoiceTaxAmount + totalInvoiceTaxAmount);
  };
  const handleDueDateOnClick = () => {
    const dueDate = getValues("duedate");
    if (dueDate?.getFullYear() !== 1970) {
      if (getValues("invoiceId")) {
        updateInvoice(
          {
            invoice_id: getValues("invoiceId"),
            due_date: dueDate
              ? Math.floor(new Date(dueDate)?.getTime() / 1000)
              : Math.floor(
                  new Date(
                    new Date()?.setDate(new Date()?.getDate() + 10)
                  )?.getTime() / 1000
                ), // Fetch from due date field
          },
          {
            onSuccess() {
              setDueDateEditComponent(false);
              actions.setToast({
                toastMessage: "Invoice due date updated",
                toastState: true,
              });
            },
            onError(err) {
              actions.setToast({
                toastMessage: `Cannot update due date in the invoice. ${
                  err?.detail?.message &&
                  `Error details: ${err?.detail?.message}`
                }`,
                toastState: true,
                variant: "error",
              });
            },
          }
        );
      } else {
        handleSaveInvoice();
      }
      clearErrors("duedate");
    } else {
      setError("duedate", {
        message: "Due date is required to create invoice",
      });
    }
  };

  const handleCloseInvoiceModal = () => {
    const invoiceDueAmount = getValues("dueAmount");
    if (Number(invoiceDueAmount) !== 0) {
      if (
        !getValues("invoiceId") ||
        modalOpeningMode === ModalModes.VIEW ||
        modalOpeningMode === ModalModes.CREDIT_NOTE ||
        isSubscriptionPaused ||
        isFinalBillInvoice
      ) {
        setOpenInvoiceDialog(false);
      } else {
        setCloseInvoiceDialog(true);
      }
    } else {
      actions.setToast({
        toastMessage: "Total invoice value must be greater than $0",
        toastState: true,
        variant: "error",
        showIcon: true,
      });
    }
  };

  const handleSaveInvoice = (clickMode = "") => {
    saveInvoice(
      {
        customer: contractData?.customer?.stripe_customer_id, // Stripe Customer Id which is fetch from contractData.
        metadata: {
          stripe_subscription_id: contractData?.vendor_subscription_id, // Stripe subscriptioId which is fetch from contractData.vendor_subscription_id
          otoz_customer_id: contractData?.customer?.identifier, // Netsol Customer Id which is fetch from contractData.
          otoz_subscription_id: contractData?.identifier, // Netsol Subscription Id which is fetch from contractData.
          autonation_user_guid: contractData?.customer_reference_id, // Autonation Customer Id which is fetch from contractData.
          autonation_subscription_id: contractData?.external_subscription_id, // Autonation Subscription Id which is fetch from contractData.
          internal_notes: getValues("internal_notes")
            ? getValues("internal_notes")
            : undefined,
        },
        collection_method: "send_invoice", // Always sent 'send_invoice'
        due_date: getValues("duedate")
          ? Math.floor(new Date(getValues("duedate")).getTime() / 1000)
          : Math.floor(
              new Date(
                new Date().setDate(new Date().getDate() + 10)
              ).getTime() / 1000
            ), // Fetch from due date field
        description: getValues("memo") ? getValues("memo") : undefined,
      },
      {
        onSuccess({ data }) {
          const invoiceDueAmount = data as unknown as any;
          setValue("invoiceId", data?.id);
          setValue("dueAmount", invoiceDueAmount?.amount_due);
          setValue(
            "createdDate",
            new Date(
              utcToLocal(
                new Date(data?.created * 1000)?.toISOString(),
                dateFormatSearch
              )
            )
          );
          setModalOpeningMode(ModalModes.EDIT);
          actions.setToast({
            toastMessage:
              "ATTENTION: A new invoice has been created for this customer.",
            toastState: true,
            showIcon: true,
            variant: "error",
          });
          if (clickMode == "AddNewLineItem") {
            if (getValues("invoiceId")) {
              append({
                id: null,
                invoice_item: null,
                productId: SELECT,
                productName: SELECT,
                productAmountId: SELECT,
                productAmount: SELECT,
                metadata: {},
                modify: true,
                addNewPrice: false,
                description: "",
              });
            }
          }
        },
        onError() {
          actions.setToast({
            toastMessage: "Something went wrong",
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const handleAddNewLineItem = () => {
    if (modalOpeningMode === ModalModes.ADD) {
      handleSaveInvoice("AddNewLineItem");
    } else {
      insert(
        formValues?.lineItems?.length > 0
          ? formValues?.lineItems?.length - 1
          : 0,
        {
          id: null,
          invoice_item: null,
          productId: SELECT,
          productName: SELECT,
          productAmountId: SELECT,
          productAmount: SELECT,
          metadata: {},
          modify: true,
          addNewPrice: false,
          description: "",
        }
      );
      setModalOpeningMode(ModalModes.EDIT);
    }
  };

  const handleDiscardInvoice = () => {
    deleteInvoice(
      {
        invoice_id: formValues.invoiceId,
      },
      {
        onSuccess() {
          setOpenInvoiceDialog(false);
          actions.setToast({
            toastMessage: "Invoice discarded",
            toastState: true,
          });
        },
        onError(error: any) {
          console.log("Error Occured", error);
          actions.setToast({
            toastMessage: error?.detail?.message?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const handleSendInvoice = () => {
    const invoiceDueAmount = customerInvoice as unknown as any;
    if (contractData?.final_bill_invoice_id === formValues?.invoiceId || invoiceDueAmount?.amount_due >= 50) {
      sendInvoice(
        {
          invoice_id: formValues.invoiceId,
        },
        {
          onSuccess() {
            setOpenInvoiceDialog(false);
            setSendInvoiceDialog(false);
            actions.setToast({
              toastMessage: "Invoice sent",
              toastState: true,
            });
            if (isFinalBillInvoice) {
              proceedReturnRequest(
                {
                  updated_by: contractData?.customer?.email,
                  identifier: contractData?.returns.identifier,
                },
                {
                  onSuccess() {
                    actions.setToast({
                      toastMessage: "Return submitted successfully",
                      toastState: true,
                    });
                  },
                  onError(error) {
                    actions.setToast({
                      toastMessage: error?.detail?.message?.toString(),
                      toastState: true,
                      variant: "error",
                    });
                  },
                }
              );
            }
          },
          onError(error: any) {
            console.log("Error Occured", error);
            actions.setToast({
              toastMessage: error?.detail?.message?.toString(),
              toastState: true,
              variant: "error",
            });
          },
        }
      );
    } else {
      setSendInvoiceDialog(false);

      actions.setToast({
        toastMessage:
          "Total invoice value must be greater than $.50 to be sent to the customer.",
        toastState: true,
        variant: "error",
        showIcon: true,
      });
    }
  };
  const handleIssueCreditNote = () => {
    if (
      formValues?.creditNoteReason &&
      formValues?.creditNoteReason !== SELECT &&
      formValues?.isCreditOptionSelected &&
      Object.keys(errors)?.length === 0
    ) {
      saveCreditNotes(
        {
          lines: formValues?.lineItems?.filter(
            (f) => parseInt(f.creditAmount) > 0
          ),
          invoice_id: customerInvoice?.id,
          reason:
            formValues?.creditNoteReason === "Other"
              ? undefined
              : formValues?.creditNoteReason,
          credit_amount:
            customerInvoice?.invoice_status === InvoiceDisplayStatus.PAID
              ? formValues?.creditAmount
              : undefined,
          refund_amount:
            customerInvoice?.invoice_status === InvoiceDisplayStatus.PAID
              ? formValues?.refundAmount
              : undefined,
        },
        {
          onSuccess() {
            setOpenInvoiceDialog(false);
            actions.setToast({
              toastMessage: "Credit note has been issued.",
              toastState: true,
            });
          },
          onError(error) {
            actions.setToast({
              toastMessage: error?.detail?.message?.toString(),
              toastState: true,
              variant: "error",
            });
          },
        }
      );
    } else {
      if (formValues?.creditNoteReason === SELECT) {
        setError("creditNoteReason", {
          message: "Credit Note Reason is required to issue credit note",
        });
        actions.setToast({
          toastMessage: "Credit Note Reason is required to issue credit note",
          toastState: true,
          variant: "error",
        });
      }
      if (!formValues?.isCreditOptionSelected) {
        setError("isCreditOptionSelected", {
          message: "Credit destination is required.",
        });
        actions.setToast({
          toastMessage: "Credit destination is required.",
          toastState: true,
          variant: "error",
        });
      }
    }
  };

  const returnModalTitle = (modelOpeningMode: ModalModes) => {
    switch (modelOpeningMode) {
      case ModalModes.ADD:
        return "Create Invoice";
      case ModalModes.EDIT:
        return "Edit Invoice";
      case ModalModes.VIEW:
        return "View Invoice";
      case ModalModes.CREDIT_NOTE:
        return "Credit Note";
    }
  };

  const getSendInvoicePopupText = () => {
    switch (true) {
      case closeInvoiceDialog:
        return "Are you sure you want to discard invoice";
      case deleteConfirmationDialog:
        return "Are you sure you want to delete this item?";
      case sendInvoiceDialog:
        return isSubscriptionPaused
          ? "Are you sure you want to send an invoice to this paused subscription"
          : "Are you sure you want to send invoice";
    }
  };

  const handleDeleteLineItemClick = (index) => {
    const lineItems = formValues?.lineItems;
    if (lineItems[index].invoice_item) {
      deleteInvoiceItem(
        {
          invoice_item: lineItems[index].invoice_item,
        },
        {
          onSuccess({ data }) {
            if (data?.deleted) {
              if (lineItems?.length === 2) {
                const taxLineItemIndex = formValues?.lineItems?.findIndex(
                  (f) => f?.metadata?.[StripeMetaData.TAX_CHARGE] === "True"
                );
                if (taxLineItemIndex > -1) {
                  deleteInvoiceItem(
                    {
                      invoice_item: lineItems[taxLineItemIndex].invoice_item,
                    },
                    {
                      onSuccess({ data: taxLineItemDeleteData }) {
                        if (taxLineItemDeleteData?.deleted) {
                          remove();
                          setFetchSummaryInfo(true);
                        }
                      },
                    }
                  );
                } else {
                  remove(index);
                  setIsTaxCalculate(true);
                }
              } else {
                if (lineItems?.length === 1) {
                  remove(index);
                  setFetchSummaryInfo(true);
                } else {
                  remove(index);
                  setIsTaxCalculate(true);
                }
              }
            }
          },
          onError(error: any) {
            console.log("Error Occured", error);
          },
        }
      );
    } else {
      remove(index);
      setDeleteConfirmationDialog(false);
    }
  };

  return (
    <>
      <InvoiceModelBlurWrap className="invoice-modal-blur-wrap">
        <Dialog
          theme={theme}
          size="xl"
          variant={undefined}
          title={returnModalTitle(modalOpeningMode)}
          disablePortal
          open={openInvoiceDialog}
          onCloseDialog={handleCloseInvoiceModal}
          children={
            <>
              <InvoicesWrap>
                <Box theme={theme}>
                  <Box theme={theme} className="invoice-header">
                    <Grid theme={theme} container spacing={2}>
                      <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
                        <Typography
                          theme={theme}
                          className={
                            isDueDateEdit || isMemoEdit || isInternalNoteEdit
                              ? "content-title enableBlur"
                              : "content-title disableBlur"
                          }
                          variant="h3"
                          component="h3"
                        >
                          {customerInvoice?.invoice_number
                            ? `Invoice Number - ${customerInvoice?.invoice_number}`
                            : "New Invoice"}
                        </Typography>
                      </Grid>
                      <Grid
                        theme={theme}
                        item
                        xs={12}
                        lg={5}
                        sm={12}
                        md={6}
                        textAlign="right"
                      >
                        <Box
                          theme={theme}
                          display={"flex"}
                          justifyContent={"flex-end"}
                          gap={"16px"}
                        >
                          {modalOpeningMode === ModalModes.VIEW &&
                            !!customerInvoice?.invoice_pdf && (
                              <Button
                                primary
                                theme={theme}
                                text={"Download"}
                                startIcon={<Icon name="SaveIcon" />}
                                type="submit"
                                onClick={() => {
                                  window.open(customerInvoice?.invoice_pdf);
                                }}
                              />
                            )}
                          {modalOpeningMode === ModalModes.VIEW && (
                            <Menu
                              theme={theme}
                              disablePortal
                              options={
                                voidPInvoice &&
                                deletePInvoice &&
                                isRefundAllow &&
                                creditPInvoice
                                  ? [
                                      {
                                        optionText: "Edit Invoice",
                                        optionValue: "edit",
                                        disabled:
                                          customerInvoice?.invoice_status !==
                                            InvoiceDisplayStatus.DRAFT ||
                                          !editInvoice ||
                                          customerInvoice.invoice_type !==
                                            InvoiceDisplayType.ADHOC_INVOICE,
                                      },
                                      {
                                        optionText: "Delete Invoice",
                                        optionValue: "delete",
                                        disabled:
                                          customerInvoice?.invoice_status !==
                                            InvoiceDisplayStatus.DRAFT ||
                                          isFinalBillInvoice,
                                      },
                                      {
                                        optionText: "Void Invoice",
                                        optionValue: "void",
                                        disabled:
                                          customerInvoice.invoice_status ===
                                            InvoiceDisplayStatus.DRAFT ||
                                          customerInvoice.invoice_status ===
                                            InvoiceDisplayStatus.PAID ||
                                          customerInvoice.invoice_status ===
                                            InvoiceDisplayStatus.VOID ||
                                          customerInvoice.invoice_status ===
                                            InvoiceDisplayStatus.UNCOLLECTIBLE ||
                                          isFinalBillInvoice,
                                      },
                                      {
                                        optionText: "Invoice History",
                                        optionValue: "history",
                                        disabled: !isInvoiceHistoryAllow,
                                      },
                                      {
                                        optionText: "Credit Note",
                                        optionValue: "credit_note",
                                        disabled:
                                          customerInvoice.invoice_status ===
                                            InvoiceDisplayStatus.DRAFT ||
                                          customerInvoice.invoice_status ===
                                            InvoiceDisplayStatus.VOID ||
                                          customerInvoice.invoice_status ===
                                            InvoiceDisplayStatus.PENDING ||
                                          customerInvoice.invoice_status ===
                                            InvoiceDisplayStatus.UNCOLLECTIBLE ||
                                          isFinalBillInvoice,
                                      },
                                      {
                                        optionText: "Refund Invoice",
                                        optionValue: "refund",
                                        disabled:
                                          customerInvoice.invoice_status !==
                                            InvoiceDisplayStatus.PAID ||
                                          !isRefundAllow,
                                      },
                                    ]
                                  : [
                                      {
                                        optionText: "Edit Invoice",
                                        optionValue: "edit",
                                        disabled:
                                          customerInvoice?.invoice_status !==
                                            InvoiceDisplayStatus.DRAFT ||
                                          !editInvoice ||
                                          customerInvoice.invoice_type !==
                                            InvoiceDisplayType.ADHOC_INVOICE,
                                      },
                                      {
                                        optionText: "Invoice History",
                                        optionValue: "history",
                                        disabled: !isInvoiceHistoryAllow,
                                      },
                                    ]
                              }
                              handleOptionClick={(_event, _key, value) => {
                                switch (value) {
                                  case "delete":
                                    handleDeleteInvoice(customerInvoice?.id);
                                    break;
                                  case "edit":
                                    handleOpenInvoiceDialog(
                                      false,
                                      false,
                                      customerInvoice
                                    );
                                    break;
                                  case "void":
                                    handleVoidInvoice(customerInvoice?.id);
                                    break;
                                  case "history":
                                    setOpenInvoiceDialog(false);
                                    handleOpenHistoryDialog(customerInvoice);
                                    break;
                                  case "credit_note":
                                    handleOpenInvoiceDialog(
                                      ModalModes.CREDIT_NOTE,
                                      customerInvoice
                                    );
                                    break;
                                  case "refund":
                                    setOpenInvoiceDialog(false);
                                    handleOpenRefundDialog(customerInvoice);
                                    break;
                                }
                              }}
                              render={(onMenuSelection) => (
                                <Button
                                  theme={theme}
                                  secondary
                                  iconText={<Icon name="MoreIcon" />}
                                  id="programSaveAs"
                                  onClick={onMenuSelection}
                                />
                              )}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                      <Grid theme={theme} item lg={12} md={12} sm={12} xs={12}>
                        <Box theme={theme} className="new-invoice-detail">
                          <Box theme={theme} className="detail">
                            <Box theme={theme} display={"flex"} gap={"4px"}>
                              <Typography
                                theme={theme}
                                className={
                                  isDueDateEdit ||
                                  isMemoEdit ||
                                  isInternalNoteEdit
                                    ? "text-600 enableBlur"
                                    : "text-600 disableBlur"
                                }
                                variant="body1"
                                component="span"
                              >
                                Invoice Status:
                              </Typography>
                              <Typography
                                theme={theme}
                                className={
                                  isDueDateEdit ||
                                  isMemoEdit ||
                                  isInternalNoteEdit
                                    ? "content-title enableBlur"
                                    : "content-title disableBlur"
                                }
                                variant="body1"
                                component="span"
                              >
                                {customerInvoice?.invoice_status || "Draft"}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            theme={theme}
                            className={
                              isDueDateEdit || isMemoEdit || isInternalNoteEdit
                                ? "detail enableBlur"
                                : "detail disableBlur"
                            }
                          >
                            <Box theme={theme} display={"flex"} gap={"4px"}>
                              <Typography
                                theme={theme}
                                className={
                                  isDueDateEdit
                                    ? "text-600 enableBlur"
                                    : "text-600 disableBlur"
                                }
                                variant="body1"
                                component="span"
                              >
                                Creation Date:
                              </Typography>
                              <Typography
                                theme={theme}
                                className={
                                  isDueDateEdit
                                    ? "content-title enableBlur"
                                    : "content-title disableBlur"
                                }
                                variant="body1"
                                component="span"
                              >
                                {utcToLocal(
                                  getValues("createdDate")?.toISOString(),
                                  dateFormatSearch
                                ) ?? "-"}
                              </Typography>
                            </Box>
                          </Box>
                          {isDueDateEdit === false ? (
                            <Box
                              theme={theme}
                              className={
                                isDueDateEdit ||
                                isMemoEdit ||
                                isInternalNoteEdit
                                  ? "detail enableBlur"
                                  : "detail disableBlur"
                              }
                            >
                              <Box theme={theme} display={"flex"} gap={"4px"}>
                                <Typography
                                  theme={theme}
                                  className={
                                    isDueDateEdit ||
                                    isMemoEdit ||
                                    isInternalNoteEdit
                                      ? "text-600 enableBlur"
                                      : "text-600 disableBlur"
                                  }
                                  variant="body1"
                                  component="span"
                                >
                                  Due Date:
                                </Typography>
                                <Typography
                                  theme={theme}
                                  className={
                                    isDueDateEdit ||
                                    isMemoEdit ||
                                    isInternalNoteEdit
                                      ? "content-title enableBlur"
                                      : "content-title disableBlur"
                                  }
                                  variant="body1"
                                  component="span"
                                >
                                  {utcToLocal(
                                    getValues("duedate")?.toISOString(),
                                    dateFormatSearch
                                  ) ?? "-"}
                                </Typography>
                              </Box>
                              {modalOpeningMode !== ModalModes.VIEW && (
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  startIcon={<Icon name="EditIcon" />}
                                  type="submit"
                                  onClick={() => {
                                    setDueDateEditComponent(true);
                                  }}
                                />
                              )}
                            </Box>
                          ) : (
                            <Box theme={theme} className="detail">
                              <Controller
                                name="duedate"
                                control={control}
                                render={({ field: { value } }) => (
                                  <DatePicker
                                    theme={theme}
                                    label={"Due Date"}
                                    value={value}
                                    convertDateLocalToUTC={false}
                                    disabled={
                                      saveInvoiceLoading ||
                                      updateInvoiceLoading ||
                                      modalOpeningMode === ModalModes.VIEW ||
                                      !isDueDateEditAllow
                                    }
                                    onChange={(e: dayjs.Dayjs) =>
                                      setValue("duedate", e?.toDate())
                                    }
                                    minDate={dayjs(new Date())}
                                    error={errors?.duedate?.message}
                                  />
                                )}
                              />
                              <Button
                                defaultBtn
                                theme={theme}
                                startIcon={<Icon name="CloseBlack" />}
                                type="button"
                                onClick={() => {
                                  setDueDateEditComponent(false);
                                  setValue(
                                    "duedate",
                                    customerInvoice?.due_date
                                      ? new Date(
                                          utcToLocal(
                                            new Date(
                                              customerInvoice?.due_date * 1000
                                            ).toISOString(),
                                            dateFormatSearch
                                          )
                                        )
                                      : new Date(
                                          new Date().setDate(
                                            new Date().getDate() + 10
                                          )
                                        )
                                  );
                                }}
                              />
                              <Button
                                defaultBtn
                                theme={theme}
                                startIcon={<Icon name="TickIcon" />}
                                type="submit"
                                onClick={() => {
                                  handleDueDateOnClick();
                                }}
                              />
                            </Box>
                          )}
                          {modalOpeningMode !== ModalModes.CREDIT_NOTE &&
                            (isMemoEdit === false ? (
                              <Box
                                theme={theme}
                                className={
                                  isDueDateEdit ||
                                  isMemoEdit ||
                                  isInternalNoteEdit
                                    ? "detail enableBlur"
                                    : "detail disableBlur"
                                }
                              >
                                <Box theme={theme} display={"flex"} gap={"4px"}>
                                  <Typography
                                    theme={theme}
                                    className={
                                      isDueDateEdit ||
                                      isMemoEdit ||
                                      isInternalNoteEdit
                                        ? "text-600 enableBlur"
                                        : "text-600 disableBlur"
                                    }
                                    variant="body1"
                                    component="span"
                                  >
                                    Notes to Customer:
                                  </Typography>
                                  <Typography
                                    theme={theme}
                                    className={
                                      isDueDateEdit ||
                                      isMemoEdit ||
                                      isInternalNoteEdit
                                        ? "content-title enableBlur"
                                        : "content-title disableBlur"
                                    }
                                    variant="body1"
                                    component="span"
                                  >
                                    {getValues("memo") || "-"}
                                  </Typography>
                                </Box>
                                {modalOpeningMode !== ModalModes.VIEW && (
                                  <Button
                                    defaultBtn
                                    theme={theme}
                                    startIcon={<Icon name="EditIcon" />}
                                    type="submit"
                                    onClick={() => {
                                      setIsMemoEditComponent(true);
                                    }}
                                  />
                                )}
                              </Box>
                            ) : (
                              <Box
                                theme={theme}
                                className="detail memo-textarea"
                                alignItems={"flex-start"}
                              >
                                <Controller
                                  name="memo"
                                  defaultValue={""}
                                  control={control}
                                  render={({ field }) => (
                                    <Textarea
                                      theme={theme}
                                      label={"Notes to Customer"}
                                      fullWidth
                                      disabled={
                                        saveInvoiceLoading ||
                                        updateInvoiceLoading ||
                                        modalOpeningMode === ModalModes.VIEW
                                      }
                                      type="text"
                                      id="memoTextDescription"
                                      name="memoDescription"
                                      placeholder="Write a memo.."
                                      rows={3}
                                      {...field}
                                      value={field.value ?? ""}
                                      onBlur={() => {
                                        clearErrors("memo");
                                      }}
                                      error={errors?.memo?.message}
                                    />
                                  )}
                                />
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  startIcon={<Icon name="CloseBlack" />}
                                  type="submit"
                                  onClick={() => {
                                    setIsMemoEditComponent(false);
                                    setValue(
                                      "memo",
                                      customerInvoice?.description
                                    );
                                    clearErrors("memo");
                                  }}
                                />
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  startIcon={<Icon name="TickIcon" />}
                                  type="submit"
                                  onClick={() => {
                                    handleMemoOnClick();
                                  }}
                                />
                              </Box>
                            ))}

                          {modalOpeningMode !== ModalModes.CREDIT_NOTE &&
                            (isInternalNoteEdit === false ? (
                              <Box
                                theme={theme}
                                className={
                                  isDueDateEdit ||
                                  isMemoEdit ||
                                  isInternalNoteEdit
                                    ? "detail enableBlur"
                                    : "detail disableBlur"
                                }
                              >
                                <Box theme={theme} display={"flex"} gap={"4px"}>
                                  <Typography
                                    theme={theme}
                                    className={
                                      isDueDateEdit ||
                                      isMemoEdit ||
                                      isInternalNoteEdit
                                        ? "text-600 enableBlur"
                                        : "text-600 disableBlur"
                                    }
                                    variant="body1"
                                    component="span"
                                  >
                                    Internal Notes:
                                  </Typography>
                                  <Typography
                                    theme={theme}
                                    className={
                                      isDueDateEdit ||
                                      isMemoEdit ||
                                      isInternalNoteEdit
                                        ? "content-title enableBlur"
                                        : "content-title disableBlur"
                                    }
                                    variant="body1"
                                    component="span"
                                  >
                                    {getValues("internal_notes") || "-"}
                                  </Typography>
                                </Box>
                                {modalOpeningMode !== ModalModes.VIEW && (
                                  <Button
                                    defaultBtn
                                    theme={theme}
                                    startIcon={<Icon name="EditIcon" />}
                                    type="submit"
                                    onClick={() => {
                                      setIsInternalNoteEditComponent(true);
                                    }}
                                  />
                                )}
                              </Box>
                            ) : (
                              <Box
                                theme={theme}
                                className="detail memo-textarea"
                                alignItems={"flex-start"}
                              >
                                <Controller
                                  name="internal_notes"
                                  defaultValue={""}
                                  control={control}
                                  render={({ field }) => (
                                    <Textarea
                                      theme={theme}
                                      label={"Internal Notes"}
                                      fullWidth
                                      disabled={
                                        saveInvoiceLoading ||
                                        updateInvoiceLoading ||
                                        modalOpeningMode === ModalModes.VIEW
                                      }
                                      type="text"
                                      id="memoTextDescription"
                                      name="memoDescription"
                                      placeholder="Write a note.."
                                      rows={3}
                                      {...field}
                                      value={field.value ?? ""}
                                      onBlur={() => {
                                        clearErrors("internal_notes");
                                      }}
                                      error={errors?.internal_notes?.message}
                                    />
                                  )}
                                />
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  startIcon={<Icon name="CloseBlack" />}
                                  type="submit"
                                  onClick={() => {
                                    setIsInternalNoteEditComponent(false);
                                    setValue(
                                      "internal_notes",
                                      customerInvoice?.description
                                    );
                                    clearErrors("internal_notes");
                                  }}
                                />
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  startIcon={<Icon name="TickIcon" />}
                                  type="submit"
                                  onClick={() => {
                                    handleInternalNotesOnClick();
                                  }}
                                />
                              </Box>
                            ))}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography
                    theme={theme}
                    sx={{ mt: 3 }}
                    className="content-title"
                    variant="body2"
                    component="p"
                  >
                    {(isSubscriptionPaused || isFinalBillInvoice) &&
                      "This invoice is being created as Draft and will not be sent for payment at the moment."}
                  </Typography>

                  {/* Invoice Status : Draft */}
                  <Grid container theme={theme} spacing={2}>
                    {modalOpeningMode === ModalModes.CREDIT_NOTE && (
                      <Grid theme={theme} item xs={4} lg={4}>
                        <Controller
                          name="creditNoteReason"
                          control={control}
                          defaultValue={SELECT}
                          render={({ field: { value, onChange } }) => (
                            <Select
                              theme={theme}
                              label={"Reason"}
                              placeholder={"Select"}
                              items={credit_note_reasons}
                              value={value}
                              selectError={errors?.creditNoteReason?.message}
                              onChange={(e) => {
                                onChange(e as any);
                                clearErrors("creditNoteReason");
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>

                  {/* Item Grid */}
                  <Box
                    theme={theme}
                    sx={{ mt: 3 }}
                    className={
                      isDueDateEdit || isMemoEdit || isInternalNoteEdit
                        ? "invoice-table enableBlur"
                        : "invoice-table disableBlur"
                    }
                  >
                    {fields?.length > 0 && (
                      <>
                        <LineItems
                          theme={theme}
                          control={control}
                          stripeCustomerId={
                            contractData?.customer?.stripe_customer_id
                          }
                          formValues={formValues}
                          fields={fields}
                          stripeInvoiceCreditNotes={stripeInvoiceCreditNotes}
                          append={append}
                          update={update}
                          // remove={remove}
                          setFetchSummaryInfo={setFetchSummaryInfo}
                          modalOpeningMode={modalOpeningMode}
                          customerInvoice={customerInvoice}
                          setError={setError}
                          clearErrors={clearErrors}
                          errors={errors}
                          contractData={contractData}
                          isTaxCalculate={isTaxCalculate}
                          setIsTaxCalculate={setIsTaxCalculate}
                          setIsTaxCalculateError={setIsTaxCalculateError}
                          setIsTaxApisLoading={setIsTaxApisLoading}
                          setDeleteConfirmationDialog={
                            setDeleteConfirmationDialog
                          }
                          setDeleteIndex={setDeleteIndex}
                        />
                        <Box theme={theme} sx={{ mb: 3 }}>
                          <hr />
                        </Box>
                      </>
                    )}
                    {/* Add New Line Item */}
                    {modalOpeningMode !== ModalModes.VIEW &&
                      modalOpeningMode !== ModalModes.CREDIT_NOTE && (
                        <>
                          <Button
                            disabled={
                              saveInvoiceLoading ||
                              updateInvoiceLoading ||
                              formValues?.lineItems?.some(
                                (s) => s.modify === true
                              ) ||
                              Object.keys(errors).length ||
                              isTaxCalculate ||
                              isTaxApisLoading
                            }
                            theme={theme}
                            secondary
                            text={"Add New Line Item"}
                            onClick={handleAddNewLineItem}
                            fullWidth={true}
                            endIcon={
                              saveInvoiceLoading &&
                              updateInvoiceLoading && (
                                <img src={DisableLoader} alt="Loader" />
                              )
                            }
                          />
                          <Box theme={theme} sx={{ mt: 3, mb: 3 }}>
                            <hr />
                          </Box>
                        </>
                      )}

                    {/* Invoice Summary */}
                    <InvoiceSummary
                      formValues={formValues}
                      theme={theme}
                      customerInvoice={customerInvoice}
                      stripeInvoiceId={formValues?.invoiceId}
                      fetchSummaryInfo={fetchSummaryInfo}
                      setFetchSummaryInfo={setFetchSummaryInfo}
                      setCustomerInvoice={setCustomerInvoice}
                      modalOpeningMode={modalOpeningMode}
                      stripeInvoiceCreditNotes={stripeInvoiceCreditNotes}
                      setError={setError}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      getValues={getValues}
                      errors={errors}
                      isTaxCalculate={isTaxCalculate}
                      setIsTaxCalculate={setIsTaxCalculate}
                      invoiceTaxAmount={invoiceTaxAmount}
                      setDeleteConfirmationDialog={setDeleteConfirmationDialog}
                    />

                    <Box theme={theme}>
                      <hr />
                    </Box>
                  </Box>
                </Box>
              </InvoicesWrap>
            </>
          }
          customFooter={
            modalOpeningMode !== ModalModes.VIEW ? (
              <>
                <InvoiceModelWrap
                  className={`dialog-footer ${
                    (isSubscriptionPaused || isFinalBillInvoice) &&
                    "create-invoice"
                  }`}
                >
                  <Grid
                    container
                    theme={theme}
                    spacing={2}
                    className={
                      isDueDateEdit || isMemoEdit || isInternalNoteEdit
                        ? "content-title enableBlur"
                        : "content-title disableBlur"
                    }
                  >
                    {(isSubscriptionPaused || isFinalBillInvoice) && (
                      <Grid
                        theme={theme}
                        item
                        xs={isSubscriptionPaused ? 4 : 6}
                        textAlign={"right"}
                      >
                        <Button
                          theme={theme}
                          className="discard-invoice"
                          primary
                          text="Save Invoice as Draft"
                          onClick={handleCloseInvoiceModal}
                        />
                      </Grid>
                    )}
                    {modalOpeningMode !== ModalModes.CREDIT_NOTE ? (
                      <>
                        {!isFinalBillInvoice && (
                          <Grid
                            theme={theme}
                            item
                            xs={isSubscriptionPaused ? 4 : 6}
                            textAlign={"right"}
                          >
                            <Button
                              disabled={
                                deleteInvoiceLoading ||
                                sendInvoiceLoading ||
                                !formValues?.invoiceId
                              }
                              theme={theme}
                              secondary
                              text="Discard Invoice"
                              className="discard-invoice"
                              onClick={handleDiscardInvoice}
                              endIcon={
                                deleteInvoiceLoading && (
                                  <img src={DisableLoader} alt="Loader" />
                                )
                              }
                            />
                          </Grid>
                        )}
                        <Grid
                          theme={theme}
                          item
                          xs={isSubscriptionPaused ? 4 : 6}
                        >
                          <Button
                            disabled={
                              deleteInvoiceLoading ||
                              sendInvoiceLoading ||
                              !formValues?.invoiceId ||
                              (!customerInvoice?.lines?.length &&
                                !isFinalBillInvoice) ||
                              Object.keys(errors)?.length ||
                              !isSendInvoiceAllow ||
                              isTaxCalculate ||
                              isTaxCalculateError
                            }
                            theme={theme}
                            primary={
                              !isSubscriptionPaused || !isFinalBillInvoice
                            }
                            secondary={
                              isSubscriptionPaused || isFinalBillInvoice
                            }
                            className="discard-invoice"
                            text="Send Invoice"
                            onClick={() => setSendInvoiceDialog(true)}
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid theme={theme} item xs={12}>
                        <Button
                          disabled={
                            saveCreditNotesLoading ||
                            errors?.creditNoteReason ||
                            errors?.lineItems
                          }
                          theme={theme}
                          primary
                          text="Issue Credit Note"
                          onClick={handleIssueCreditNote}
                          endIcon={
                            saveCreditNotesLoading && (
                              <img src={DisableLoader} alt="Loader" />
                            )
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </InvoiceModelWrap>
              </>
            ) : (
              <></>
            )
          }
        />
      </InvoiceModelBlurWrap>
      <Dialog
        theme={theme}
        size="xs"
        variant="confirmation"
        open={
          closeInvoiceDialog || sendInvoiceDialog || deleteConfirmationDialog
        }
        children={
          <>
            <Box theme={theme} textAlign="center">
              <Box theme={theme} className="dialog-icon">
                <Icon name="ErrorTriangle" />
              </Box>
              <Box theme={theme} mt={4}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="h2"
                  component="h2"
                >
                  {getSendInvoicePopupText()}
                </Typography>
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="body2"
                  component="span"
                >
                  {closeInvoiceDialog
                    ? "Once discard, you cannot undo this action."
                    : deleteConfirmationDialog
                    ? "All changes are final and cannot be undone."
                    : "It will be sent to customer for payment"}
                </Typography>
              </Box>
            </Box>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  text="Cancel"
                  onClick={() => {
                    setCloseInvoiceDialog(false);
                    setSendInvoiceDialog(false);
                    setDeleteConfirmationDialog(false);
                    setDeleteIndex(null);
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  theme={theme}
                  // disabled={
                  //   deleteInvoiceLoading ||
                  //   sendInvoiceLoading ||
                  //   deleteLineItemLoading
                  // }
                  primary
                  text={
                    closeInvoiceDialog
                      ? "Discard"
                      : isSubscriptionPaused
                      ? "Yes"
                      : deleteConfirmationDialog
                      ? "Save"
                      : "Send"
                  }
                  onClick={
                    closeInvoiceDialog
                      ? handleDiscardInvoice
                      : deleteConfirmationDialog
                      ? () => {
                          handleDeleteLineItemClick(deleteIndex);
                        }
                      : handleSendInvoice
                  }
                  endIcon={
                    (deleteInvoiceLoading ||
                      sendInvoiceLoading ||
                      deleteLineItemLoading) && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};
