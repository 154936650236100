import { useTheme } from "@mui/material";
import { Box, DatePicker, Grid, Input, Select, Textarea } from "@ntpkunity/controls";
import { useEffect, useState, FC } from "react";
import { Controller } from "react-hook-form";
import { ReasonType, SubscriptionStatus } from "enums";
import { ReturnTypes } from "enums/ReturnTypes";
import { useGetUnwindConfiguredReasons, useGetTenantConfiguredInformation } from "common/hooks/useConfigurationData";
import { BuyOut } from "../../../../../shared/BuyOut/buyOut";
import { utcToLocal } from "utilities/methods";
import dayjs from "dayjs";
import { utcToLocalFormat } from "common/constants";
import { ReturnWrap } from "./return.style";
import { ValidationMessages } from "enums/ValidationMessages";

export const EarlyReturn: FC<{
  form: any;
  contractData: any;
  isEditOdometerAllow: boolean;
  isEditActualRDAllow: boolean;
  vinLast8Digit: string;
  convertedReturnType: string;
}> = ({ form, contractData, isEditActualRDAllow, isEditOdometerAllow, vinLast8Digit, convertedReturnType }) => {
  const theme = useTheme();
  const { control, setValue, reset, formState: { errors },watch,getValues } = form;
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [buyOutOption, setBuyOutOption] = useState("");
  const [returnType, setReturnType] = useState("");
  const [plannedConfiguredDays, setPlannedConfiguredDays] = useState(0);
  const [configureReason, setConfigureReason] = useState<{ value: string; text: string }[]>([]);
  // const {
  //   mutate: getEarlyReturn,
  //   data: earlyReturnChecklist,
  //   isLoading: getEarlyReturnLoading,
  // } = useGetEventAssociatedChecklist();
  const { mutate: getAllUnwindConfiguredReasons} = useGetUnwindConfiguredReasons();
  const {data: tenantInformation} = useGetTenantConfiguredInformation()
  const subscriptionStatus = contractData?.subscription_status;
  const buyOutObj = contractData?.buyout;
  const startDate = contractData?.start_date;
  const current_odometer_reading = watch("returns.current_odometer_reading");
  useEffect(() => {
    const overrunMiles =
        parseInt(current_odometer_reading) - getValues("returns.allowed_usage") - contractData?.starting_odometer;
    setValue("returns.overrun_miles", overrunMiles > 0 ? overrunMiles : 0);
}, [current_odometer_reading]);
  useEffect(() => {
    const overrun_miles =
      contractData?.returns?.current_odometer_reading -
      contractData?.allowed_miles;
    reset({
      identifier: contractData?.identifier,
      customer_email: contractData?.customer?.email,
      buyOutOption: contractData?.buyout?.buyout_option,
      vehiclePurchasePrice: contractData?.buyout?.vehicle_purchase_price,
      returns:
        contractData?.returns?.status === "CANCELLED"
          ? {
              return_type: contractData?.returns?.return_type,
              planned_return_date: contractData?.returns?.planned_return_date && utcToLocal(contractData?.returns?.planned_return_date),
              noi_date:null,
              actual_return_date: null,
              current_odometer_reading: null,
              allowed_usage: contractData?.allowed_miles,
              starting_odometer: contractData?.starting_odometer ? contractData?.starting_odometer : 0,
              et_fee: undefined,
            }
          : {
              ...contractData?.returns,
              overrun_miles: overrun_miles > 0 ? overrun_miles : 0,
              et_fee: (contractData?.returns && contractData?.returns?.et_fee) ? contractData?.returns?.et_fee / 100 : contractData?.returns?.et_fee === 0 ? 0 :  undefined,
              starting_odometer: contractData?.starting_odometer ? contractData?.starting_odometer : 0,
              planned_return_date: contractData?.returns?.planned_return_date && utcToLocal(contractData?.returns?.planned_return_date),
              actual_return_date: contractData?.returns?.actual_return_date && utcToLocal(contractData?.returns?.actual_return_date),
              noi_date: contractData?.returns?.noi_date && utcToLocal(contractData?.returns?.noi_date),
              check_list_start_date: contractData?.returns?.check_list_start_date && utcToLocal(contractData?.returns?.check_list_start_date, utcToLocalFormat),
              allowed_usage: contractData?.allowed_miles,
              unwind_reason: contractData?.returns?.reason,
              others: contractData?.returns?.reason_detail,
            },
    }
    );
/* eslint-disable array-callback-return */
    contractData?.returns?.order_checklist?.map((checklist) => {
      setValue(checklist.question_text, checklist.primary_answer);
    });
      setReturnType(contractData?.returns?.return_type)
  }, [contractData]);

  // useEffect(() => {
  //   getEarlyReturn({ event_name: ChecklistEvents.EARLY_RETURN_EVENT });
  // }, []);
  useEffect(() => {
    setPlannedConfiguredDays(tenantInformation?.planned_return_date)
  }, [tenantInformation]);
  useEffect(() => {
    getAllUnwindConfiguredReasons(
      {reason_type: ReasonType.UNWIND_REASON},
      {
        onSuccess: ({ data }) => {
          data = [
            ...data,
            { reason_name: "OTHER", reason_description: "Other" },
          ];
          setConfigureReason(
            data.map((item) => ({
              value: item.reason_name,
              text: item.reason_description,
            }))
          );
        },
      }
    );
  }, []);
  useEffect(() => {
    if(convertedReturnType === "EarlyReturn"){
      setValue("returns.return_type", "EarlyReturn")
      setReturnType(ReturnTypes.EARLY_RETURN)
    }
  }, [convertedReturnType]);
  return (
    <>
      <Grid
        theme={theme}
        rowSpacing={3}
        columnSpacing={3}
        sx={{ mb: 3 }}
        container
      >
        <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
          <Controller
            name="returns.return_type"
            control={control}
            defaultValue={ReturnTypes.EARLY_RETURN}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                label={"Return Type"}
                items={[
                  {
                    text: "Early Return",
                    value: ReturnTypes.EARLY_RETURN,
                  },
                  {
                    text: "Unwind",
                    value: ReturnTypes.UNWIND,
                  },
                ]}
                onChange={(e) => {
                  onChange(e);
                  setReturnType(e?.target?.value as unknown as any);
                  }}  
                value={value}    
                disabled={convertedReturnType === ReturnTypes.EARLY_RETURN || subscriptionStatus === SubscriptionStatus.RETURN_SUBMITTED || 
                  subscriptionStatus === SubscriptionStatus.PENDING_RETURN || subscriptionStatus === SubscriptionStatus.PROCESSING_RETURN ||
                  contractData?.returns?.status === SubscriptionStatus.CLOSED}  
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
          <ReturnWrap className='datepicker'>
            <Controller
              name="returns.planned_return_date"
              control={control}
              defaultValue={""}
              // rules={{
              //   required: ValidationMessages.PLANNED_RETURN_DATE,
              // }}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  theme={theme}
                  label={"Planned Return Date"}
                  value={value}
                  maxDate={dayjs(Date.now())?.add(plannedConfiguredDays,"day")}  
                  minDate={dayjs(startDate)}
                  onChange={(e: Date) => {
                    if (
                      e.getFullYear() !== 1969 &&
                      e.getFullYear() !== 1970 
                    ) { onChange(e)}
                  }}
                  onKeyDown={(e: any) => e.preventDefault()}
                  disabled={
                    subscriptionStatus !== SubscriptionStatus.ACTIVE &&
                    subscriptionStatus !== SubscriptionStatus.PAUSED &&
                    subscriptionStatus !== SubscriptionStatus.PENDING_RETURN
                  }
                  // error={errors?.returns?.planned_return_date?.message}
                />
              )}
            />
          </ReturnWrap>
        </Grid>
        <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
          <Controller
            name="returns.actual_return_date"
            control={control}
            defaultValue={"Input Value"}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                theme={theme}
                label={"Actual Return Date"}
                value={value ?? ""}
                disableFuture={true}
                onChange={(e: Date) => {
                  onChange(
                    e.getFullYear() === 1970 || e?.getFullYear() === 1969
                      ? contractData?.returns?.actual_return_date
                      : e.toISOString()
                  );
                }}
                onKeyDown={(e: any) => e.preventDefault()}
                disabled={
                  subscriptionStatus !== SubscriptionStatus.PROCESSING_RETURN ||
                  !isEditActualRDAllow
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
            <Controller
                name="returns.check_list_start_date"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    {...field}
                    type={"text"}
                    fullWidth
                    label="Return Checklist Start Date"
                    disabled
                  />
                )}
            />
        </Grid>
        <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
          <Controller
            name="returns.starting_odometer"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Input
                theme={theme}
                {...field}
                type={"decimal"}
                fullWidth
                label="Starting Odometer"
                placeholder="Type here.."
                disabled
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
          <Controller
            name="returns.current_odometer_reading"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Input
                theme={theme}
                {...field}
                type={"decimal"}
                max={999999}
                fullWidth
                label="Return Mileage"
                placeholder="Type here.."
                disabled={
                  subscriptionStatus !== SubscriptionStatus.PROCESSING_RETURN ||
                  !isEditOdometerAllow
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
          <Controller
            name="returns.allowed_usage"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Input
                theme={theme}
                {...field}
                fullWidth
                placeholder="0000"
                label="Allowed Term Miles"
                type="decimal"
                disabled
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
            <Controller
                name="returns.overrun_miles"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                    <Input
                        theme={theme}
                        {...field}
                        fullWidth
                        placeholder="0000"
                        label="Mileage Overage"
                        type="decimal"
                        disabled
                    />
                )}
            />
        </Grid>
        {returnType !== ReturnTypes.UNWIND &&
        <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
            <Controller
                name="returns.et_fee"
                control={control}
                rules={{
                  required: ValidationMessages.ET_FEE,
                }}
                render={({ field }) => (
                    <Input
                        theme={theme}
                        {...field}
                        fullWidth
                        placeholder="0000"
                        label="Early Termination Fee"
                        type="decimal"
                        masking
                        maskNumeric
                        maskDecimalScale={2}
                        disabled={
                            subscriptionStatus === SubscriptionStatus.RETURN_SUBMITTED ||
                            subscriptionStatus === SubscriptionStatus.CLOSED || 
                            subscriptionStatus === SubscriptionStatus.PURCHASED
                        }
                        startAdornment={field?.value !== null ? "$" : ""}
                        error={errors?.returns?.et_fee?.message}
                    />
                )}
            />
        </Grid>
        } 
        <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
            <Controller
                name="last8Digits"
                control={control}
                defaultValue={""}
                render={() => (
                    <Input
                        theme={theme}
                        fullWidth
                        placeholder="0000"
                        label="Last 8 Digits of VIN"
                        type="decimal"
                        value={vinLast8Digit}
                        disabled
                    />
                )}
            />
        </Grid>
        {returnType !== ReturnTypes.UNWIND &&
        <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
        <Controller
              name="returns.noi_date"
              control={control}
              defaultValue={""}
              rules={{
                required: ValidationMessages.NOI_DATE,
              }}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  theme={theme}
                  label={"NOI Date"}
                  value={value}
                  onChange={(e: Date) => {
                    if (
                      e.getFullYear() !== 1969 &&
                      e.getFullYear() !== 1970 
                    ) { onChange(e)}
                  }}
                  onKeyDown={(e: any) => e.preventDefault()}
                  error={errors?.returns?.noi_date?.message}
                  disabled={ contractData?.returns?.status === SubscriptionStatus.CLOSED}  
                />
              )}
            />
        </Grid>
        }
        <Grid theme={theme} item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Controller
                name="returns.dealer_comments"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                    <Input
                        theme={theme}
                        {...field}
                        fullWidth
                        placeholder="0000"
                        label="Dealer Comments"
                        type="decimal"
                        disabled
                    />
                )}
            />
        </Grid>
        <Grid theme={theme} item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box theme={theme} sx={{ opacity: 0.7, mt: 1, mb: 2 }}>
                        <hr />
                    </Box>
                    </Grid>
      {returnType === ReturnTypes.UNWIND &&
        <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
          <Controller
            name="returns.unwind_reason"
            control={control}
            rules={{
              required: "Unwind reason required",
            }}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                label={"Reason for Unwind"}
                items={configureReason}
                 onChange={(e) => {
                  onChange(e);
                  }} 
                  selectError={
                    errors?.returns?.unwind_reason?.message as unknown as string
                  } 
                value={value}      
                disabled={ contractData?.returns?.status === SubscriptionStatus.CLOSED}  
              />
            )}
          />
      </Grid>
      }
      <Grid theme={theme} item xs={12}>
                  {watch("returns.unwind_reason") === "OTHER" && (
                    <Controller
                      name="returns.others"
                      control={control}
                      defaultValue={""}
                      rules={{
                        maxLength: {
                          value: 100,
                          message:
                            ValidationMessages.UNWIND_REASON_LENGTH,
                        },
                          required: "Unwind reason details is required",
                        
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Textarea
                          type="text"
                          id={""}
                          rows={5}
                          theme={theme}
                          label="Reason Details..."
                          fullWidth
                          placeholder="Reason Details..."
                          value={value}
                          onChange={onChange}
                          error={errors?.returns?.others?.message?.toString()}
                          disabled={ contractData?.returns?.status === SubscriptionStatus.CLOSED}  
                        />
                      )}
                    />
                  )}
                </Grid>
      </Grid>
      {(subscriptionStatus === SubscriptionStatus.PENDING_RETURN ||
        buyOutObj !== null) && (returnType !== ReturnTypes.UNWIND) && (
        <BuyOut
          form={form}
          setBuyOutOption={setBuyOutOption}
          contractData={contractData}
        />
      )}
      {/* {(buyOutObj === null || buyOutOption === "No" || buyOutOption === "") && (
        <DealerChecklist
          checklist={earlyReturnChecklist?.[0]?.[0]}
          dealerChecklist={
            contractData?.returns?.status !== "CANCELLED" ?
            contractData?.returns?.order_checklist :
            []
          }
          returnType={ReturnTypes.EARLY_RETURN}
          isLoading={getEarlyReturnLoading}
        />
      )} */}
    </>
  );
};
