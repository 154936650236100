import { TableCell, TablePagination, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  DataTable,
  DateRange,
  Icon,
  Input,
  Skeleton,
} from "@ntpkunity/controls";
import { APP_ROUTES } from "@router/path";
import { DataTableWrap } from "@shared/DataTableWrap";
import { RETURNS_PARAMS_CACHED_KEY, dateFormatSearch } from "common/constants";
import { useGetReturnRequest } from "common/hooks/useReturnData";
import { WorkQueueSortBy } from "enums";
import { FC, Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formatedDate, isDateValid } from "utilities/methods";

export const WorkQueueTable: FC<{ resetFilters: any; setResetFilter: any }> = ({
  resetFilters,
  setResetFilter,
}) => {
  const theme = useTheme();
  const { control, reset, setValue } = useForm();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortingOrder, setSortingOrder] = useState<string>("");
  const [sortBy, setSortBy] = useState("");
  const navigate = useNavigate();
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
  };
  const orderInformation = (
    reference_id: string,
    order_reference_id: string
  ) => {
    navigate(
      APP_ROUTES.ORDER_DETAILS + "/" + reference_id + "/" + order_reference_id,
      {
        state: {
          isWorkQueue: true,
        },
      }
    );
  };
  const [return_type, setReturnType] = useState("");
  const [request_status, setRequestStatus] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [customer_email, setCustomerEmail] = useState("");
  const [order_reference_id, setOrderRefereneId] = useState("");
  const [vin, setVin] = useState("");
  const [billing_status, setBillingStatus] = useState("");
  const [dateRange, setDateRange1] = useState([null, null]);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );
  const [returnRequestData, setReturnRequestData] = useState<any>();
  const { data: returnData, isLoading } = useGetReturnRequest(columnFilters);

    useEffect(() => {
        setReturnRequestData(returnData);
    }, [returnData]);
    const saveSearchParamsToLocalStorage = (params) => {
        localStorage.setItem(RETURNS_PARAMS_CACHED_KEY, JSON.stringify(params));
    };
    
    useEffect(() => {
        // Retrieve search and sort parameters from local storage
        const storedSearchParams = localStorage.getItem(RETURNS_PARAMS_CACHED_KEY);
        if (storedSearchParams) {
          // Parse stored parameters and set state
          const { pageNumber, pageSize, ...restParams } = JSON.parse(storedSearchParams);
          setPageNumber(pageNumber || 0);
          setPageSize(pageSize || 10);
          // Set other state variables based on retrieved parameters
          setReturnType(restParams.return_type || "");
          setRequestStatus(restParams.request_status || "");
          setCustomerName(restParams.customer_name || "");
          setCustomerEmail(restParams.customer_email || "");
          setOrderRefereneId(restParams.order_reference_id || "");
          setVin(restParams.vin || "");
          setBillingStatus(restParams.billing_status || "");
          setSelectedEndDate(restParams.dateRange?.[1] || "")
          setSelectedStartDate(restParams.dateRange?.[0] || "")
          setSortingOrder(restParams.sortingOrder || "");
          setSortBy(restParams.sortBy || "")
          setDateRange1(restParams.dateRange || [null, null ])
          // Set other state variables as needed
          setValue("return_type", restParams.return_type)
          setValue("request_status", restParams.request_status)
          setValue("customer_name", restParams.customer_name)
          setValue("customer_email", restParams.customer_email)
          setValue("customer_reference_id", restParams.order_reference_id)
          setValue("vin", restParams.vin)
          setValue("request_date", restParams.dateRange)
          setValue("billing_status", restParams.billing_status)
        }
    }, []);
    
    useEffect(() => {
        // Update search and sort parameters in local storage whenever they change
        const params = {
            pageNumber,
            pageSize,
            return_type,
            request_status,
            customer_name,
            customer_email,
            order_reference_id,
            vin,
            billing_status,
            sortingOrder,
            sortBy,
            dateRange,
            // Add other parameters as needed
        };
        setColumnFilters(setQueryString(pageNumber, pageSize));
        saveSearchParamsToLocalStorage(params);
    }, [pageNumber, pageSize, return_type, request_status,customer_name,customer_email,order_reference_id,vin,billing_status,sortingOrder,sortBy,selectedEndDate,selectedStartDate,dateRange]);
    const setQueryString = (pgNo, pgSize) => {
        var query_string = `page_number=${pgNo}&page_size=${pgSize}`;
        if (return_type != null && return_type != "") {
            query_string = query_string.concat(`&return_type=${return_type}`);
        }
        if (request_status != null && request_status != "") {
            query_string = query_string.concat(
                `&return_status=${request_status}`
            );
        }
        if (customer_name != null && customer_name != "") {
            query_string = query_string.concat(`&customer_name=${customer_name}`);
        }
        if (customer_email != null && customer_email != "") {
            query_string = query_string.concat(`&email=${encodeURIComponent(customer_email)}`);
        }
        if (order_reference_id != null && order_reference_id != "") {
            query_string = query_string.concat(
                `&external_order_id=${order_reference_id}`
            );
        }
        if (vin != null && vin != "") {
            query_string = query_string.concat(`&vin=${vin}`);
        }
        if (isDateValid(selectedStartDate) && isDateValid(selectedEndDate)) {
            query_string = query_string.concat(
                `&start_date=${formatedDate(
                    selectedStartDate,
                    dateFormatSearch
                )}&end_date=${formatedDate(selectedEndDate, dateFormatSearch)}`
            );
        }
        if (billing_status != null && billing_status != "") {
            query_string = query_string.concat(`&billing_status=${billing_status}`);
        }
        if (
            sortBy != null &&
      sortBy != "" &&
      sortingOrder != "" &&
      sortingOrder != null
    ) {
      query_string = query_string.concat(
        `&sort_by=${sortBy}&sort=${sortingOrder}`
      );
    }
    return query_string;
  };

  useEffect(() => {
    if (resetFilters) {
      resetLocalStorageAndFetch();
    }
  }, [resetFilters]);
  const resetLocalStorageAndFetch = () => {
    localStorage.removeItem(RETURNS_PARAMS_CACHED_KEY); // Clear local storage
    setPageNumber(0); // Reset page number
    setPageSize(10); // Reset page size
    setReturnType(""); // Reset return type
    setCustomerName("");
    setCustomerEmail("");
    setOrderRefereneId("");
    setVin("");
    setBillingStatus("");
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setRequestStatus(""); // Reset request status
    setSortingOrder(""); // Reset sorting order
    setSortBy(""); // Reset sort by
    setDateRange1([null, null]),
    reset({
      return_type: "",
      request_status: "",
      customer_name: "",
      customer_email: "",
      customer_reference_id: "",
      vin: "",
      request_date: "",
      billing_status: "",
    });
    setResetFilter(false);
  };

  return (
    <>
      <DataTableWrap className="table-pagination input-width">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          theadChildren={
            <>
              <TableRow className="table-header">
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Request Type
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === WorkQueueSortBy.RETURNTYPE ? (
                          sortingOrder === WorkQueueSortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : (
                            <Icon name="DescendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        setSortBy(WorkQueueSortBy.RETURNTYPE);
                        setSortingOrder(
                          sortingOrder === WorkQueueSortBy.ASCENDING
                            ? WorkQueueSortBy.DESCENDING
                            : WorkQueueSortBy.ASCENDING
                        );
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Request Status
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === WorkQueueSortBy.REQUESTSTATUS ? (
                          sortingOrder === WorkQueueSortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : (
                            <Icon name="DescendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        setSortBy(WorkQueueSortBy.REQUESTSTATUS);
                        setSortingOrder(
                          sortingOrder === WorkQueueSortBy.ASCENDING
                            ? WorkQueueSortBy.DESCENDING
                            : WorkQueueSortBy.ASCENDING
                        );
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Customer Name
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === WorkQueueSortBy.CUSTOMERNAME ? (
                          sortingOrder === WorkQueueSortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : (
                            <Icon name="DescendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        setSortBy(WorkQueueSortBy.CUSTOMERNAME);
                        setSortingOrder(
                          sortingOrder === WorkQueueSortBy.ASCENDING
                            ? WorkQueueSortBy.DESCENDING
                            : WorkQueueSortBy.ASCENDING
                        );
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Email
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === WorkQueueSortBy.EMAIL ? (
                          sortingOrder === WorkQueueSortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : (
                            <Icon name="DescendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        setSortBy(WorkQueueSortBy.EMAIL);
                        setSortingOrder(
                          sortingOrder === WorkQueueSortBy.ASCENDING
                            ? WorkQueueSortBy.DESCENDING
                            : WorkQueueSortBy.ASCENDING
                        );
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Reference ID
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === WorkQueueSortBy.ACCOUNTNUMBER ? (
                          sortingOrder === WorkQueueSortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : (
                            <Icon name="DescendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        setSortBy(WorkQueueSortBy.ACCOUNTNUMBER);
                        setSortingOrder(
                          sortingOrder === WorkQueueSortBy.ASCENDING
                            ? WorkQueueSortBy.DESCENDING
                            : WorkQueueSortBy.ASCENDING
                        );
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      VIN
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === WorkQueueSortBy.VIN ? (
                          sortingOrder === WorkQueueSortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : (
                            <Icon name="DescendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        setSortBy(WorkQueueSortBy.VIN);
                        setSortingOrder(
                          sortingOrder === WorkQueueSortBy.ASCENDING
                            ? WorkQueueSortBy.DESCENDING
                            : WorkQueueSortBy.ASCENDING
                        );
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Request Date
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === WorkQueueSortBy.REQUESTDATE ? (
                          sortingOrder === WorkQueueSortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : (
                            <Icon name="DescendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        setSortBy(WorkQueueSortBy.REQUESTDATE);
                        setSortingOrder(
                          sortingOrder === WorkQueueSortBy.ASCENDING
                            ? WorkQueueSortBy.DESCENDING
                            : WorkQueueSortBy.ASCENDING
                        );
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Billing Status
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === WorkQueueSortBy.BILLINGSTATUS ? (
                          sortingOrder === WorkQueueSortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : (
                            <Icon name="DescendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        setSortBy(WorkQueueSortBy.BILLINGSTATUS);
                        setSortingOrder(
                          sortingOrder === WorkQueueSortBy.ASCENDING
                            ? WorkQueueSortBy.DESCENDING
                            : WorkQueueSortBy.ASCENDING
                        );
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell space-fixed"></TableCell>
              </TableRow>

                            <TableRow className="filters-row with-date-range">
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Controller
                                            name="return_type"
                                            defaultValue={""}
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                              <>
                                                <Input
                                                  theme={theme}
                                                  fullWidth={true}
                                                  placeholder={"Search..."}
                                                  type="text"
                                                  value={value}
                                                  onChange={onChange}
                                                  onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                      setReturnType(e?.target?.value);
                                                      setPageNumber(0);
                                                    }
                                                  }}
                                                />
                                                <Button
                                                  defaultBtn
                                                  iconText={<Icon name="IconFilter" />}
                                                  onClick={() => {
                                                    setReturnType(value);
                                                    setPageNumber(0);
                                                  }}
                                                ></Button>
                                              </>
                                            )}
                                          />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Controller
                                            name="request_status"
                                            defaultValue={""}
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                              <>
                                                <Input
                                                  theme={theme}
                                                  fullWidth={true}
                                                  placeholder={"Search..."}
                                                  type="text"
                                                  value={value}
                                                  onChange={onChange}
                                                  onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                      setRequestStatus(e?.target?.value);
                                                      setPageNumber(0);
                                                    }
                                                  }}
                                                />
                                                <Button
                                                  defaultBtn
                                                  iconText={<Icon name="IconFilter" />}
                                                  onClick={() => {
                                                    setRequestStatus(value);
                                                    setPageNumber(0);
                                                  }}
                                                ></Button>
                                              </>
                                            )}
                                          />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Controller
                                            name="customer_name"
                                            defaultValue={""}
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                              <>
                                                <Input
                                                  theme={theme}
                                                  fullWidth={true}
                                                  placeholder={"Search..."}
                                                  type="text"
                                                  value={value}
                                                  onChange={onChange}
                                                  onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                      setCustomerName(e?.target?.value);
                                                      setPageNumber(0);
                                                    }
                                                  }}
                                                />
                                                <Button
                                                  defaultBtn
                                                  iconText={<Icon name="IconFilter" />}
                                                  onClick={() => {
                                                    setCustomerName(value);
                                                    setPageNumber(0);
                                                  }}
                                                ></Button>
                                              </>
                                            )}
                                          />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Controller
                                            name="customer_email"
                                            defaultValue={""}
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                              <>
                                                <Input
                                                  theme={theme}
                                                  fullWidth={true}
                                                  placeholder={"Search..."}
                                                  type="text"
                                                  value={value}
                                                  onChange={onChange}
                                                  onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                      setCustomerEmail(e?.target?.value);
                                                      setPageNumber(0);
                                                    }
                                                  }}
                                                />
                                                <Button
                                                  defaultBtn
                                                  iconText={<Icon name="IconFilter" />}
                                                  onClick={() => {
                                                    setCustomerEmail(value);
                                                    setPageNumber(0);
                                                  }}
                                                ></Button>
                                              </>
                                            )}
                                          />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Controller
                                            name="customer_reference_id"
                                            defaultValue={""}
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                              <>
                                                <Input
                                                  theme={theme}
                                                  fullWidth={true}
                                                  placeholder={"Search..."}
                                                  type="text"
                                                  value={value}
                                                  onChange={onChange}
                                                  onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                      setOrderRefereneId(e?.target?.value);
                                                      setPageNumber(0);
                                                    }
                                                  }}
                                                />
                                                <Button
                                                  defaultBtn
                                                  iconText={<Icon name="IconFilter" />}
                                                  onClick={() => {
                                                    setOrderRefereneId(value);
                                                    setPageNumber(0);
                                                  }}
                                                ></Button>
                                              </>
                                            )}
                                          />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Controller
                                            name="vin"
                                            defaultValue={""}
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                              <>
                                                <Input
                                                  theme={theme}
                                                  fullWidth={true}
                                                  placeholder={"Search..."}
                                                  type="text"
                                                  value={value}
                                                  onChange={onChange}
                                                  onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                      setVin(e?.target?.value);
                                                      setPageNumber(0);
                                                    }
                                                  }}
                                                />
                                                <Button
                                                  defaultBtn
                                                  iconText={<Icon name="IconFilter" />}
                                                  onClick={() => {
                                                    setVin(value);
                                                    setPageNumber(0);
                                                  }}
                                                ></Button>
                                              </>
                                            )}
                                          />
                                    </Box>
                                </TableCell>
                                <TableCell className="date-range-filter">
                                  <Box theme={theme} className="table-filter">
                                    <Controller
                                      name="request_date"
                                      control={control}
                                      render={({ field: { value, onChange } }) => (
                                        <>
                                          <DateRange
                                            theme={theme}
                                            placeholder="00/00/0000 - 00/00/0000"
                                            onFocus={onfocus}
                                            onBlur={onblur}
                                            onChange={(value) => {
                                              setSelectedStartDate(null);
                                              setSelectedEndDate(null);
                                              setDateRange1(value);
                                              setPageNumber(0);
                                            }}
                                            format='MM/DD/YYYY'
                                            onClose={() => {
                                              const startDate = typeof dateRange?.[0] !== "number" ? dateRange?.[0]?.toDate() : dateRange?.[0];
                                              const endDate = typeof dateRange?.[1] !== "number" ? dateRange?.[1]?.toDate() : dateRange?.[1];
                                              if (
                                                isDateValid(startDate) &&
                                                isDateValid(endDate)
                                              ) {
                                                setSelectedStartDate(startDate);
                                                setSelectedEndDate(endDate);
                                              }
                                            }}
                                            range
                                            rangeHover
                                            value={dateRange}
                                            endAdornment={
                                              <Icon
                                                name="CalendarIcon"
                                                className="calendar-icon"
                                              />
                                            }
                                            render={<input placeholder='MM/DD/YYYY' readOnly />}
                                            dateSeparator={" - "}
                                            noLabel={true}
                                          />
                                          <Button
                                            defaultBtn
                                            iconText={<Icon name="IconFilter" />}
                                            onClick={() => {
                                              onChange(value);
                                              setPageNumber(0);
                                            }}
                                          ></Button>
                                        </>
                                      )}
                                    />
                                  </Box>
                                </TableCell>


                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Controller
                                            name="billing_status"
                                            defaultValue={""}
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                              <>
                                                <Input
                                                  theme={theme}
                                                  fullWidth={true}
                                                  placeholder={"Search..."}
                                                  type="text"
                                                  value={value}
                                                  onChange={onChange}
                                                  onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                      setBillingStatus(e?.target?.value);
                                                      setPageNumber(0);
                                                    }
                                                  }}
                                                />
                                                <Button
                                                  defaultBtn
                                                  iconText={<Icon name="IconFilter" />}
                                                  onClick={() => {
                                                    setBillingStatus(value);
                                                    setPageNumber(0);
                                                  }}
                                                ></Button>
                                              </>
                                            )}
                                          />
                                    </Box>
                                </TableCell>
                                <TableCell className="action-cell fixed-cell space-fixed"></TableCell>
                            </TableRow>
                        </>
                    }
                    tbodyChildren={
                        <>
                            {isLoading ? (
                                <Fragment>
                                    <TableRow>
                                        <TableCell colSpan={9}>
                                            <Skeleton width={"100%"} height={20} theme={theme} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={9}>
                                            <Skeleton width={"100%"} height={20} theme={theme} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={9}>
                                            <Skeleton width={"100%"} height={20} theme={theme} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={9}>
                                            <Skeleton width={"100%"} height={20} theme={theme} />
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            ) : (
                                returnRequestData &&
                returnRequestData?.result?.map((item, index) => (
                  <TableRow className="child-tr" key={index}>
                    <TableCell>
                      {item?.return_type ? item?.return_type : "-"}
                    </TableCell>
                    <TableCell>{item?.status ? item?.status : "-"}</TableCell>
                    <TableCell>
                      {item?.customer_first_name +
                        " " +
                        item?.customer_last_name}
                    </TableCell>
                    <TableCell>
                      {item?.customer_email ? item?.customer_email : "-"}
                    </TableCell>
                    <TableCell>
                      {item?.external_order_id ? item?.external_order_id : "-"}
                    </TableCell>
                    <TableCell>{item?.vin ? item?.vin : "-"}</TableCell>
                    <TableCell>
                      {item?.created_at === null
                        ? ""
                        : formatedDate(item?.created_at, "MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>
                      {item?.billing_status ? item?.billing_status : "-"}
                    </TableCell>
                    <TableCell className="action-cell fixed-cell space-fixed">
                      <Button
                        defaultBtn
                        iconText={<Icon name="IcView" />}
                        onClick={() => {
                          orderInformation(
                            item.customer_reference_id,
                            item.external_order_id
                          );
                        }}
                      ></Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </>
          }
        />
        <TablePagination
          component="div"
          id="pagination"
          count={returnRequestData ? returnRequestData?.total_results : -1}
          page={pageNumber}
          rowsPerPage={pageSize}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DataTableWrap>
    </>
  );
};
