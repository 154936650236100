/* CONSTANTS*/
export const PAGE_SIZE = 10;
export const SEARCH_PARAMS_CACHED_KEY = 'SEARCH_PARAMS_CACHED_KEY';
export const RETURNS_PARAMS_CACHED_KEY = 'RETURN_PARAMS_CACHED_KEY';


/* MESSAGES*/
export const SAVE_DATA_MESSAGE = "Record Added Successfully";
export const DELETE_DATA_MESSAGE = "Record Deleted Successfully";
export const UPDATE_DATA_MESSAGE = "Record Updated Successfully";
export const SUBSCRIPTION_CANCEL_MESSAGE = "Subscription Canceled Successfully";
export const RETURN_CANCEL_MESSAGE = "Early Termination Request cancelled";
export const INTERNAL_ACCESS_TOKEN_KEY = "settings";
export const EXTERNAL_SUBSCRIPTION_KEY = "external_subscription_key";
export const CANCEL_REASON_UPDATE_MESSAGE = "Cancel Reason Updated Successfully";
export const DEAL_NUMBER_UPDATE_MESSAGE = "Deal Number Updated Successfully";


/* Date Formats */
export const utcToLocalForHistoryFormat = "MMM DD, YYYY, hh:mm:ss A";
export const utcToLocalFormat = "MM/DD/YYYY, hh:mm:ss A";
export const dateFormat = "MM-DD-YYYY";
export const dateFormatSearch = "MM/DD/YYYY";
export const dateFormatEarlyReturn = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
// CHECKLIST QUESTIONS
export const IS_VEHICLE_AVAILABLE = "Is this vehicle available?";

/* PAUSE SUBSCRIPTION FLAGS */
export const PAUSE_RESUME_SUBSCRIPTION_FLAGS = [
    "Legal Hold",
    "Cease & Desist",
    "Bankruptcy",
    "Deceased",
    "Locked from Collections",
    "Debt Validation",
    "General Pause",
];
export const PAUSE_RESUME_SUBSCRIPTION_FLAGS_SET = new Set(
    PAUSE_RESUME_SUBSCRIPTION_FLAGS
);
