import {
  EXTERNAL_SUBSCRIPTION_KEY,
  INTERNAL_ACCESS_TOKEN_KEY,
  dateFormatSearch,
} from "common/constants";
import type { RegisterOptions } from "react-hook-form";
import jwt_decode from "jwt-decode";
import type { IADUserInfo, IUserInfo } from "Interface";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
export const convertToPascalCase = (text: string): string => {
  return text?.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });
};

export const dateFormat = (date) => {
  return new Date(date).toLocaleDateString("en");
};

export const getTime = (date) => {
  return new Date(date).toLocaleTimeString();
};
export const formatedDate = (date: string, format: string) =>
  dayjs(date).format(format);

export const utcToLocal = (date: string | number, format?: string) => {
  const utcDate = dayjs.utc(typeof date === "number" ? date * 1000 : date);
  const localDate = utcDate.tz(dayjs.tz.guess());
  return format ? localDate.format(format) : localDate.format();
};
export const getTodayDate = (format: string = dateFormatSearch) => {
  return dayjs().startOf("day").format(format);
};
export const getPreviousDayDate = (
  days: number,
  format: string = dateFormatSearch
) => {
  return dayjs().subtract(days, "day").startOf("day").format(format);
};

export const localToUTC = (date: any) => {
  return dayjs.unix(date).utc().format();
};

export const isValidUTC = (value: any) => dayjs.utc(value).isUTC();

export const base64ToFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

export const validation = (
  name: string,
  required: boolean
): RegisterOptions => ({
  required: {
    value: required,
    message: `${name} is required`,
  },
});

export const getInitials = function (initials: string) {
  if (initials != null) {
    var names = initials?.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
  }
  return initials;
};

export const setInternalAccessToken = (access_token: IUserInfo) => {
  localStorage.setItem(INTERNAL_ACCESS_TOKEN_KEY, JSON.stringify(access_token));
};

export const getInternalAccessToken = (): IUserInfo => {
  return JSON.parse(
    localStorage.getItem(INTERNAL_ACCESS_TOKEN_KEY)
  ) as IUserInfo;
};

export const setExternalSubscriptionKey = (key: string) => {
  localStorage.setItem(EXTERNAL_SUBSCRIPTION_KEY, JSON.stringify(key));
};

export const getExternalSubscriptionKey = (): string => {
  return JSON.parse(localStorage.getItem(EXTERNAL_SUBSCRIPTION_KEY)) as string;
};

export const decodeADAccessToken = (ad_acc_tk) => {
  const dec_tk = jwt_decode(ad_acc_tk) as any;
  const adUserInfo: IADUserInfo = {
    access_token: ad_acc_tk,
    user_name: dec_tk.preferred_username,
    tenant_id: dec_tk.tid,
    job_code: dec_tk.jobcode,
    hyperion_id: dec_tk.hyperion,
  };
  return adUserInfo;
};
export const removeSpecialChars = (input: unknown): string => {
  // Convert input to string and remove all non-numeric characters
  const cleanedString = String(input).replace(/\D+/g, "");
  // Format the cleaned string as "(###) ###-####"
  const formattedString = cleanedString.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "($1) $2-$3"
  );
  return formattedString;
};

export const isDateValid = (date: Date | number): boolean => {
  if (date instanceof Date && !isNaN(date.getTime())) {
    return true; // Valid JavaScript Date object
  } else if (typeof date === "number" && !isNaN(date) && date > 0) {
    const unixTimeInMilliseconds = date * 1000; // Convert Unix timestamp to milliseconds
    return !isNaN(unixTimeInMilliseconds); // Check if it's a valid timestamp
  }
  return false; // Invalid date or timestamp
};