import { Skeleton, useTheme } from "@mui/material";
import {
    Box,
    Button,
    Dialog,
    Grid,
    Select,
    Textarea,
    Typography,
    Icon,
    Input,
    MultiSelect,
} from "@ntpkunity/controls";
import { useAddStip, useAddStipReview } from "common/hooks/useCustomerData";
import { useEffect, useState, FC } from "react";
import { useParams } from "react-router-dom";
import { CommentsTextField } from "./commentTextFieldStyle";
import { Comments } from "./previousCommentBoxStyle";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import {
    useGetDocumentType,
    useGetStipRejectReasons,
} from "common/hooks/useConfigurationData";
import { useStoreContext } from "store/storeContext";
import { SAVE_DATA_MESSAGE, UPDATE_DATA_MESSAGE, utcToLocalForHistoryFormat } from "common/constants";
import { v4 as uuidv4 } from "uuid";
import type { ICustomerStip } from "Interface/ICustomerStip";
import {
    StipulationStatuses,
} from "enums/StipulationStatuses";

import DisableLoader from "../../../../shared/assets/images/loader-disabled.gif";
import { getInternalAccessToken, utcToLocal } from "utilities/methods";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import { EventStatus, EventType } from "enums";
import { useCreateOrderEvent } from "common/hooks/useCustomerOrderData";
import {
  useDocumentURLByArtifactId,
} from "common/hooks/useCustomerData";
import { Documents } from "@modules/orderDetails/pages/orderDetail/orderDetailPageStyle";
import { useQueryClient } from "react-query";
import { QueryKeys } from "enums/UseQueryKeys";

interface IFormInputs {
    document_name?: string | null;
    document_type?: string | null;
    review_status?: string | null;
    review_notes?: string | null;
    reason_for_customer?: string | null;
    reason?: any;
    other_reason?: string | null;
}

export const StipulationModal: FC<{
    openAddDialog: boolean;
    setOpenAddDialog;
    isAdded: boolean;
    customerStip: ICustomerStip;
    handleRefreshGrid;
    handleRefreshGridReview;
}> = ({
    openAddDialog,
    setOpenAddDialog,
    isAdded,
    customerStip,
      /* eslint-disable @typescript-eslint/no-unused-vars */
    handleRefreshGrid,
    handleRefreshGridReview,
}) => {
    const schema = yup.object().shape({
        document_name: yup.string().when([], {
            is: () => isAdded,
            then: yup.string().nullable().required("Type of stipulation is required"),
            otherwise: yup.string().nullable(),
        }),
        review_status: yup.string().when([], {
            is: () => !isAdded,
            then: yup.string().nullable().required("Review status is required"),
            otherwise: yup.string().nullable(),
        }),
        reason: yup.array().when([], {
            is: () => !isAdded,
            then: yup.array().nullable().required("Reason is required"),
            otherwise: yup.array().nullable(),
        }),
        other_reason: yup.string().when("reason", {
            is: (rejectReason) => rejectReason && rejectReason.includes("OTHERS"),
            then: yup.string().nullable().required("Other reason text is required"),
            otherwise: yup.string().nullable(),
        }),
        review_notes: yup
            .string()
            .nullable()
            .max(1000, "Comment maximum length is up to 1000 characters"),
        reason_for_customer: yup
            .string()
            .nullable()
            .max(1000, "Reason for customer maximum length is up to 1000 characters"),
    });
    const params = useParams();
    const queryClient = useQueryClient();

    const [customerStips, setCustomerStips] = useState<ICustomerStip[]>();
    useEffect(() => {
    setCustomerStips(customerStips);
  }, []);
    const { mutate: downloadDocument } =
  useDocumentURLByArtifactId();
    const handleDownloadDocument = (artifect_id: string) => {
    downloadDocument(
      { artifect_id },
      {
        onSuccess({ data }) {
          window.open(data?.document_url, "_blank", "noreferrer");
          actions.setToast({
            toastMessage: "File Downloaded Successfully",
            toastState: true,
          });
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

    const theme = useTheme();
    const { actions } = useStoreContext();
    const form = useForm<IFormInputs>({
        resolver: yupResolver(schema),
        mode: "onTouched",
    });
    const {
        control,
        setValue,
        formState: { errors,isDirty },
        handleSubmit,
        watch,
        setError,
        clearErrors
    } = form;
    const tokenInforamtion = getInternalAccessToken();
    const {
        mutate: getDocumentType,
        data: documentTypes,
        isLoading: isLoadingGet,
    } = useGetDocumentType();
    const { mutate: addCustomerStip, isLoading: isLoadingAdd } = useAddStip();
    const { mutate: addCustomerStipReview, isLoading: isLoadingAddReview } =
    useAddStipReview();
    const {
        mutate: getStipRejectReasons,
        data: stipRejectReasons,
        isLoading: rejectReasonLoading,
    } = useGetStipRejectReasons();
    const { mutate: addOrderEvent } = useCreateOrderEvent();
    const [documentType, setDocumentType] = useState<
    { value: string; text: string }[]
    >([]);

    const addStipulationReveiwCommet = useHasPermissions(
        ccpPermissions.FIELDS.ADD_STIPULATION_REVIEW_COMMENT,
        PermissionTypes.DISABLED
    );
    const [addStipulationtComment, setAddStipulationtComment] = useState(false);
    const [isReject, setIsReject] = useState(false);
    const [rejectReasons, setRejectReasons] = useState([]);
    const reason = watch("reason");

const logOrderEvent = (data) => {
        addOrderEvent(
            {
                orderId: params?.order_reference_id,
                type: EventType.STIP_STATUS_CHANGED,
                status: EventStatus.INPROGRESS,
                idempotentKey: uuidv4(),
                completedBy: tokenInforamtion?.user_name,
                metadata: {
                    stipStatus: data?.review_status,
                    stipType: customerStip?.document_type,
                    stipIdentifier: data?.document_identifier,
                    stipReviewReasonCodes: data?.reason
                },
            },
            {
                onSuccess () {
                    handleRefreshGridReview(data);
                    handleCloseAddDialog();
                    actions.setToast({
                        toastMessage: UPDATE_DATA_MESSAGE,
                        toastState: true,
                    });
                },
                onError (error: any) {
                    handleRefreshGridReview(data);
                    handleCloseAddDialog();
                    actions.setToast({
                        toastMessage: error?.message?.toString(),
                        toastState: true,
                        variant: "error",
                    });
                },
            }
        );
    };

    useEffect(() => {
        setAddStipulationtComment(addStipulationReveiwCommet);
    }, [addStipulationReveiwCommet]);

    useEffect(() => {
        const reviewStatus =
      customerStip?.document_status === StipulationStatuses.INPROGRESS
          ? customerStip?.document_status
          : customerStip?.document_reviews?.length > 0
              ? customerStip?.document_reviews?.[0]?.review_status
              : customerStip?.document_status;
        if (!isAdded) {
            getStipRejectReasons({
                stipName: customerStip?.document_name,
                rejectStatus: reviewStatus,
            });
        }
    }, []);
    useEffect(() => {
        setRejectReasons(
            stipRejectReasons?.map((reason) => {
                return { text: reason?.reason_description, value: reason?.reason_code };
            })
        );
    }, [stipRejectReasons]);

    useEffect(() => {
        if (openAddDialog && isAdded) {
            getDocumentType(
                {is_stipulation: true},
                {
                    onSuccess: ({ data }) => {
                        setDocumentType(
                            data.map((item) => ({
                                value: item.description,
                                text: item.description,
                            }))
                        );
                    },
                }
            );
        } else if (openAddDialog && !isAdded) {
            setValue(
                "review_status",
                customerStip?.document_status === StipulationStatuses.INPROGRESS
                    ? customerStip?.document_status
                    : customerStip?.document_reviews?.length > 0
                        ? customerStip?.document_reviews?.[0]?.review_status
                        : customerStip?.document_status
            );
            setValue("reason", customerStip?.document_reviews?.[0]?.reason);
            setValue(
                "other_reason",
                customerStip?.document_reviews?.[0]?.reason?.includes("OTHERS")
                    ? customerStip?.document_reviews?.[0]?.other_reason
                    : ""
            );
        }
    }, [openAddDialog, isAdded]);

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleStatusChange = (e) => {
        getStipRejectReasons({
            stipName: customerStip?.document_name,
            rejectStatus: e.target.value,
        });
        setValue("reason", []);
        setValue("other_reason", "");
    };

    const dialogContent = (isAdded) => {
        if (isAdded) {
            return (
                <>
                    <Grid theme={theme} container sx={{ mb: 3 }}>
                        <Grid theme={theme} item xs={12}>
                            {isLoadingGet || isLoadingAdd || isLoadingAddReview ? (
                                <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
                            ) : (
                                <>
                                    <Controller
                                        name="document_name"
                                        control={control}
                                        defaultValue={""}
                                        render={({ field: { value, onChange } }) => (
                                            <Select
                                                theme={theme}
                                                label="Stipulation Name"
                                                placeholder="Type of Stipulation"
                                                items={documentType}
                                                value={value}
                                                onChange={(e: any) => {
                                                    console.log("e", e);
                                                    onChange(e);
                                                }}
                                                disablePortal={false}
                                                selectError={errors?.document_name?.message}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="reason_for_customer"
                                        control={control}
                                        defaultValue={""}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                theme={theme}
                                                fullWidth
                                                type="text"
                                                label="Reason For Customer"
                                                placeholder="Reason"
                                                value={value}
                                                onChange={onChange}
                                                error={errors?.reason_for_customer?.message}
                                            />
                                        )}
                                    />

                                    <CommentsTextField sx={{ mb: 3 }}>
                                        <Controller
                                            name="review_notes"
                                            control={control}
                                            defaultValue={""}
                                            render={({ field }) => (
                                                <Textarea
                                                    theme={theme}
                                                    label={"Add Comment"}
                                                    type="text"
                                                    id="comments"
                                                    rows={3}
                                                    name="comments"
                                                    fullWidth
                                                    {...field}
                                                    value={field.value}
                                                    error={errors?.review_notes?.message}
                                                />
                                            )}
                                        />
                                    </CommentsTextField>

                                </>
                            )}
                        </Grid>
                    </Grid>
                    <hr />
                </>
            );
        } else {
            return (
                <>
                    <Box theme={theme} sx={{ mb: 3 }}>
                        <Grid theme={theme} container spacing={3} sx={{ mb: 3 }}>
                            <Grid theme={theme} item xs={4}>
                                <Controller
                                    name="review_status"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            theme={theme}
                                            label="Review Status"
                                            placeholder="Review Status"
                                            disablePortal={false}
                                            items={Object.keys(StipulationStatuses).map((key) => ({
                                                value: StipulationStatuses[key],
                                                text: StipulationStatuses[key],
                                                disabled:
                          StipulationStatuses[key] ===
                            StipulationStatuses.DRAFT ||
                          StipulationStatuses[key] ===
                            StipulationStatuses.INPROGRESS ||
                          !addStipulationtComment,
                                            }))}
                                            value={value}
                                            onChange={(e: any) => {
                                                onChange(e);
                                                handleStatusChange(e);
                                                clearErrors("reason")
                                            }}
                                            selectError={errors?.review_status?.message}
                                        />
                                    )}
                                />
                                {/* {errors?.review_status && <p>{errors?.review_status?.message}</p>} */}
                            </Grid>
                            <Grid theme={theme} item xs={4}>
                                <Input
                                    theme={theme}
                                    fullWidth
                                    type="text"
                                    label="Stipulation Name"
                                    placeholder="Reason"
                                    value={customerStip?.document_name}
                                    disabled
                                />
                            </Grid>
                            <Grid theme={theme} item xs={4}>
                                <Input
                                    theme={theme}
                                    fullWidth
                                    type="text"
                                    label="Stipulation Type"
                                    placeholder="Reason"
                                    value={customerStip?.document_type}
                                    disabled
                                />
                            </Grid>
                            {
                            rejectReasonLoading ? <Skeleton width={"100%"} height={20} /> :
                            stipRejectReasons?.length > 0 && (
                                <Grid theme={theme} item xs={12}>
                                    <Controller
                                        name="reason"
                                        control={control}
                                        defaultValue={""}
                                        render={({ field: { value, onChange } }) => (
                                            <MultiSelect
                                                theme={theme}
                                                sxProps={{ m: 0, width: "100%" }}
                                                label="Reason"
                                                value={value || []}
                                                items={rejectReasons}
                                                onChange={(e) => {
                                                    onChange(e)
                                                    clearErrors("reason")
                                                }}
                                                disablePortal={false}
                                                selectError={errors?.reason?.message as unknown as any}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            {reason?.includes("OTHERS") && (
                                <Grid theme={theme} item xs={12}>
                                    <Controller
                                        name="other_reason"
                                        control={control}
                                        defaultValue={""}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                theme={theme}
                                                fullWidth
                                                type="text"
                                                label="Other Reason Description"
                                                placeholder="Enter Reason"
                                                value={value}
                                                onChange={onChange}
                                                error={errors?.other_reason?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                        </Grid>
<Box theme={theme} sx={{ mb: 3 }}>
<Documents>
                                {customerStip !== null && customerStip?.document_url !== null && (
                                    <>
                                           
                                            
                                                        <Box theme={theme} className="card">
                                                            <Box theme={theme} className="row">
                                                                <Icon name="DocumentIcon" />
                                                                <Typography
                                                                    theme={theme}
                                                                    variant="body2"
                                                                    component="p"
                                                                > 
                                                                    {customerStip?.document_name}
                                                                </Typography>
                                                            </Box>
                                                                <Button
                                                                    theme={theme}
                                                                    defaultBtn
                                                                    onClick={() => {
                                                                        handleDownloadDocument(
                                                                            customerStip.document_url
                                                                        );
                                                                    }}
                                                                    iconText={<Icon name="IcView" />}
                                                                />
                                                            </Box>
                                              
                                          
                                    </>
                                )}
                            </Documents>
</Box>
                        <CommentsTextField sx={{ mb: 3 }}>
                            <Controller
                                name="review_notes"
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                    <Textarea
                                        theme={theme}
                                        label={"Add Comment"}
                                        type="text"
                                        id="comments"
                                        rows={3}
                                        name="comments"
                                        fullWidth
                                        {...field}
                                        value={field.value}
                                        error={errors?.review_notes?.message}
                                        // children={comment?.review_notes}

                                    />
                                )}
                            />
                        </CommentsTextField>
                                                        
                    </Box>
                    <>
                        {customerStip?.document_reviews?.filter(f => f.review_notes)?.length > 0 && (
                            <Box theme={theme}>
                                <Typography
                                        theme={theme}
                                        component="h4"
                                        variant="h4"
                                        className="title"
                                        children="Previous Comments"
                                        sx={{ mb: 2 }}
                                    />
                                <Comments className="comments scroll">
                                    
                                    
                                        <ul>
                                            {customerStip?.document_reviews?.filter(f => f.review_notes)?.map((comment, index) => (
                                            <li key={index}>
                                                <Typography
                                                    theme={theme}
                                                    component="p"
                                                    variant="body2"
                                                    className="commented-text"
                                                    children={comment?.review_notes}
                                                    sx={{ mb: 1 }}
                                                />
                                                <Typography
                                                    theme={theme}
                                                    component="p"
                                                    variant="caption"
                                                    children={`Added by: ${
                                                        comment?.reviewed_by
                                                    }, ${comment?.review_date_time && utcToLocal(
                                                        comment?.review_date_time,
                                                        utcToLocalForHistoryFormat
                                                    )}`}
                                                />
                                            </li>
                                            ))}
                                        </ul>
                                    
                                </Comments>
                            </Box>
                        )}
                    </>
                </>
            );
        }
    };
    const processAddCustomerStipResponse= (formData,fullData,selectedDocumentType)=> {
    addCustomerStipReview(
        {
            document_identifier: fullData.identifier,
            document_name: fullData.document_name,
            reviewed_by: tokenInforamtion?.user_name,
            review_notes: fullData.comment,
            review_status: fullData.document_status,
            document_type: selectedDocumentType,
            ...formData,
        },
        {
            onSuccess () {
                 handleCloseAddDialog();
                actions.setToast({
                    toastMessage: UPDATE_DATA_MESSAGE,
                    toastState: true,
                });
            },
            onError (error: any) {
                console.log("testttt")
                actions.setToast({
                    toastMessage: error.error.toString(),
                    toastState: true,
                    variant: "error",
                });
            },
        }
    );
        }



    const handleDoneEvent = (isAdded: boolean, formData: any) => {
        console.log(formData)
        if (stipRejectReasons?.length && !formData?.reason.length) {
            return setError("reason", {message: "Reason is required"})
        }
        const selectedDocumentType = documentTypes?.data?.find(
            (f) => f.description == formData.document_name
        ).document_code;
        if (isAdded) {
            addCustomerStip(
    {
        customer_reference_id: params?.reference_id,
        external_reference_id: params?.order_reference_id,
        document_type: selectedDocumentType,
        document_name: formData.document_name,
        reason_for_customer: formData.reason_for_customer,
        created_by: tokenInforamtion?.user_name,
        comment: formData.review_notes,
    },
    {
        onSuccess({data}) {
            if(formData?.review_notes){
                processAddCustomerStipResponse(formData,data,selectedDocumentType)
            }
            actions.setToast({
                toastMessage: SAVE_DATA_MESSAGE,
                toastState: true,
            });
            if(!formData?.review_notes){
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_DOCUMENTS]);
                handleCloseAddDialog();
            }
        },
        onError(error: any) {
            actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: "error",
            });
            handleCloseAddDialog()
        },
    }
);

        } else {
                addCustomerStipReview(
                    {
                        document_identifier: customerStip?.identifier,
                        document_name: formData.document_name,
                        document_type: selectedDocumentType,
                        reviewed_by: tokenInforamtion?.user_name,
                        ...formData,
                    },
                    {
                        onSuccess ({ data }) {
                            logOrderEvent(data);
                            actions.setToast({
                                toastMessage: UPDATE_DATA_MESSAGE,
                                toastState: true,
                            });
                        },
                        onError (error: any) {
                            actions.setToast({
                                toastMessage: error.error.toString(),
                                toastState: true,
                                variant: "error",
                            });
                        },
                    }
                );
            }};

    return (
        <>
            {/* <CustomeDialog
                className={isAdded ? "add-stipulation-dialog" : "fixed-scroll"}
            > */}
                <Dialog
                    theme={theme}
                    variant={undefined}
                    size={isAdded ? "sm" : "lg"}
                    title={`${isAdded ? "Add" : "Review"} Stipulation`}
                    open={openAddDialog}
                    onCloseDialog={handleCloseAddDialog}
                    disablePortal={true}
                    children={dialogContent(isAdded)}
                    customFooter={
                        <>
                            <Button
                                theme={theme}
                                secondary
                                text="Cancel"
                                fullWidth
                                onClick={handleCloseAddDialog}
                            />
                            <Button
                                disabled={
                                    isLoadingGet ||
                                    isLoadingAdd ||
                                    isLoadingAddReview ||
                                    !addStipulationtComment ||
                                    !isDirty
                                }
                                theme={theme}
                                primary
                                text="Save"
                                fullWidth
                                onClick={handleSubmit((data: any) => {
                                    if (data.review_status === StipulationStatuses.REJECT) {
                                        setIsReject(true);
                                    }
                                    if (data.review_status !== StipulationStatuses.REJECT) {
                                        handleDoneEvent(isAdded, data);
                                    }
                                })}
                                endIcon={
                                    (isLoadingGet || isLoadingAdd || isLoadingAddReview) && (
                                        <img src={DisableLoader} alt="Loader" />
                                    )
                                }
                            />
                        </>
                    }
                />
            {/* </CustomeDialog> */}
            <Dialog
                theme={theme}
                size="xs"
                variant="confirmation"
                open={isReject}
                children={
                    <>
                        <Box theme={theme} textAlign="center">
                            <Box theme={theme} className="dialog-icon">
                                <Icon name="ErrorTriangle" />
                            </Box>
                            <Box theme={theme} mt={4}>
                                <Typography
                                    theme={theme}
                                    mb={2}
                                    className="content-title"
                                    variant="h2"
                                    component="h2"
                                >
                  Are you sure you want to reject?
                                </Typography>
                                <Typography
                                    theme={theme}
                                    className="text-muted"
                                    variant="body2"
                                    component="span"
                                >
                  Rejecting this stipulation will result in order cancellation.
                  Once rejected, you cannot undo this action.
                                </Typography>
                            </Box>
                        </Box>
                    </>
                }
                customFooter={
                    <>
                        <Grid container theme={theme} spacing={2}>
                            <Grid theme={theme} item xs={6} textAlign={"right"}>
                                <Button
                                    theme={theme}
                                    secondary
                                    text="Cancel"
                                    onClick={() => {
                                        setIsReject(false);
                                    }}
                                />
                            </Grid>
                            <Grid theme={theme} item xs={6}>
                                <Button
                                    disabled={isLoadingAddReview}
                                    theme={theme}
                                    danger
                                    text="Reject"
                                    onClick={handleSubmit((data) => {
                                        handleDoneEvent(isAdded, data);
                                        setIsReject(false);
                                    })}
                                    endIcon={
                                        isLoadingAddReview && (
                                            <img src={DisableLoader} alt="Loader" />
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            />
        </>
    );
};
