import { useTheme } from "@mui/material";
import {
    Box,
    Grid,
    Input,
    Radio,
    Select,
    Typography,
} from "@ntpkunity/controls";
import { useGetEventAssociatedChecklist } from "common/hooks/useConfigurationData";
import { ChecklistEvents, SubscriptionStatus } from "enums";
import { ValidationMessages } from "enums/ValidationMessages";
import { useEffect, FC } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router";
import { getInternalAccessToken } from "utilities/methods";

export const BuyOut: FC<{
    form: any;
    setBuyOutOption: any;
    contractData: any;
}> = ({ form, setBuyOutOption, contractData }) => {
    const theme = useTheme();
    const {
        control,
        getValues,
        watch,
        setValue,
        formState: { errors },
    } = form;
    const subscriptionStatus = contractData?.subscription_status;
    const params = useParams();
    const reference_id = params?.reference_id;
    const order_reference_id = params?.order_reference_id;
    const buyOutValue = getValues("buyOutOption");
    const tokenInforamtion = getInternalAccessToken();
    const {
        mutate: getBuyOutCheckList,
        data: buyOutChecklist,
    } = useGetEventAssociatedChecklist();
    useEffect(() => {
        getBuyOutCheckList({ event_name: ChecklistEvents.BUYOUT_CHECKLIST });
    }, []);
/* eslint-disable array-callback-return */
    useEffect(() => {
        const buyOutlist = buyOutChecklist?.[0]?.[0];
        const buyOutCheckList = buyOutlist?.questions?.map((question) => {
            const answer = watch(question.question);
            if (answer) {
                return {
                    customer_reference_id: reference_id,
                    order_reference_id: order_reference_id,
                    checklist_name: buyOutlist?.checklist_name,
                    event: ChecklistEvents.BUYOUT_CHECKLIST,
                    question_text: question.question,
                    question_type: "YES_NO",
                    primary_answer: answer,
                    external_order_ref_number: order_reference_id,
                    checklist_identifier: buyOutlist?.checklist_identifier,
                    created_by:tokenInforamtion?.user_name,
                };
            }
        });
        setValue("checklist", buyOutCheckList);
    }, [getValues()]);

    return (
        <>
            <Box theme={theme} className="refund">
                <Typography
                    theme={theme}
                    className="content-title"
                    variant="subtitle1"
                    component="p"
                    sx={{ mb: 3 }}
                >
          Buyout
                </Typography>
                <Grid container theme={theme} spacing={2}>
                    <Grid theme={theme} item xs={12} lg={4}>
                        <Controller
                            name="buyOutOption"
                            control={control}
                            defaultValue={"No"}
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    theme={theme}
                                    label={"Buyout Options"}
                                    placeholder={"Select Value"}
                                    disabled={
                                        subscriptionStatus !== SubscriptionStatus.PENDING_RETURN
                                    }
                                    items={[
                                        { text: "Yes", value: "Yes" },
                                        { text: "No", value: "No" },
                                    ]}
                                    onChange={(e) => {
                                        onChange(e);
                                        setBuyOutOption(e?.target?.value);
                                    }}
                                    value={value}
                                />
                            )}
                        />
                    </Grid>
                    {buyOutValue === "Yes" && (
                        <Grid theme={theme} item xs={12} lg={4}>
                            <Controller
                                name="vehiclePurchasePrice"
                                control={control}
                                defaultValue={""}
                                rules={{
                                    required: ValidationMessages.VEHICLE_PURCHASE_PRICE,
                                }}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        disabled={
                                            subscriptionStatus !== SubscriptionStatus.PENDING_RETURN
                                        }
                                        fullWidth
                                        type="decimal"
                                        masking
                                        maskNumeric
                                        maskDecimalScale={2}
                                        placeholder="00000"
                                        {...field}
                                        label={"Vehicle Purchase Price"}
                                        error={errors?.vehiclePurchasePrice?.message}
                                        startAdornment={
                                            <>
                                                <Box theme={theme} sx={{ mr: 1 }}>
                          $
                                                </Box>
                                            </>
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    )}
                </Grid>
                {buyOutValue === "Yes" && (
                    <Box theme={theme} sx={{ opacity: 0.4, mt: 3, mb: 3 }}>
                        <hr />
                    </Box>
                )}
                {buyOutValue === "Yes" && (
                    <Grid theme={theme} item rowSpacing={3} columnSpacing={3} container>
                        {buyOutChecklist?.[0]?.[0]?.questions?.map((data, index) => (
                            <Grid theme={theme} item xl={12} lg={4} key={index}>
                                <Typography
                                    theme={theme}
                                    variant="subtitle2"
                                    component="p"
                                    sx={{ mb: 1 }}
                                    children={data.question}
                                />
                                <Controller
                                    name={`${data.question}`}
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <Radio
                                            theme={theme}
                                            row={true}
                                            options={[
                                                {
                                                    label: "Yes",
                                                    value: "Yes",
                                                    disabled:
                            subscriptionStatus !==
                            SubscriptionStatus.PENDING_RETURN,
                                                },
                                                {
                                                    label: "No",
                                                    value: "No",
                                                    disabled:
                            subscriptionStatus !==
                            SubscriptionStatus.PENDING_RETURN,
                                                },
                                            ]}
                                            className="custom-radio"
                                            value={value}
                                            sx={{ gap: 3 }}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
                {buyOutValue === "Yes" && (
                    <Box theme={theme} sx={{ opacity: 0.4, mt: 3, mb: 3 }}>
                        <hr />
                    </Box>
                )}
            </Box>
        </>
    );
};
