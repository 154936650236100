import { useEffect, useState, FC, useContext } from "react";
import { SubscriptionStatus } from "enums/SubscriptionTypes";
import { useGetContractByRefercnceId } from "common/hooks/useSubscription";
import { useParams } from "react-router";
import { ReturnTypes } from "enums/ReturnTypes";
import { InvoiceDisplayStatus, ModalModes, OrderTabName, PermissionTypes } from "enums";
import { StandardReturn } from "./return/standardReturn";
import { EarlyReturn } from "./return/earlyReturn";
import { Box, Button } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { InvoiceHistoryModalComponent, InvoiceModalComponent } from "../invoices";
import {
    setTaxLineItemIndex,
    useGetInvoiceLineItems,
    useGetStripeInvoice,
} from "common/hooks/useHubexData";
import { useStoreContext } from "store/storeContext";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import DisableLoader from "@shared/assets/images/loader-disabled.gif";
import { RefundModalComponent } from "../../../../shared/RefundModal";
import { OrderContext } from "@modules/orderDetails/context/order";
import { NoSubscriptionComponent } from "@shared/NoSubscription/noSubscriptionComponent";

export const Return: FC<{ form: any; isLoading: boolean,setTabLoaded:any, convertedReturnType:any }> = ({
    form,
    isLoading,
    setTabLoaded,
    convertedReturnType,
}) => {
    const theme = useTheme();
    const params = useParams();
    const { actions } = useStoreContext();
    const reference_id = params?.reference_id;
    const order_reference_id = params?.order_reference_id;
    const [openBillDialog, setOpenBillDialog] = useState(false);
    const [openRefundDialog, setOpenRefundDialog] = useState(false);
    const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(null);
    const [returnType, setReturnType] = useState("");
    const [modalOpeningMode, setModalOpeningMode] = useState<ModalModes>();
    const [customerInvoice, setCustomerInvoice] = useState<any>();
    const [itemSelectedFromInvoiceModel, setItemSelectedFromInvoiceModel] = useState<boolean>(false);
    const { selectedOrder } = useContext(OrderContext);
    const [vinLast8Digit, setVinLast8digits] = useState<any>();
    const { data: contractData } = useGetContractByRefercnceId(
        reference_id,
        order_reference_id
    );
    const { mutate: getStripeInvoice, isLoading: invoiceLoading } =
    useGetStripeInvoice();
    const { mutate: getInvoiceLineItems, isLoading: invoiceLineItemsLoading } =
    useGetInvoiceLineItems();

    const odometerPermission = useHasPermissions(
        ccpPermissions.FIELDS.DISABLE_ODOMETER,
        PermissionTypes.DISABLED
    );
    const acrualReturnDatePermission = useHasPermissions(
        ccpPermissions.FIELDS.DISABLE_ACTUAL_RETURN_DATE,
        PermissionTypes.DISABLED
    );
    const finalBillPermission = useHasPermissions(
        ccpPermissions.FIELDS.DISABLE_FINAL_BILL_BUTTON,
        PermissionTypes.DISABLED
    );
    const refundPermission = useHasPermissions(
        ccpPermissions.FIELDS.DISABLE_REFUND,
        PermissionTypes.VIEW
    );

    const [isFinalBillAllow, setIsFinalBillAllow] = useState(false);
    const [isEditOdometerAllow, setIsOdometerAllow] = useState(false);
    const [isEditActualRDAllow, setIsActualRDAllow] = useState(false);
    const [isRefundAllow, setIsRefundAllow] = useState(false);

    useEffect(() => {
        setIsOdometerAllow(odometerPermission);
    }, [odometerPermission]);
    useEffect(() => {
        setIsActualRDAllow(acrualReturnDatePermission);
    }, [acrualReturnDatePermission]);
    useEffect(() => {
        setIsFinalBillAllow(finalBillPermission);
    }, [finalBillPermission]);
    useEffect(() => {
        setIsRefundAllow(refundPermission);
    }, [refundPermission]);

    useEffect(() => {
        if (selectedOrder) {
            const vin = selectedOrder?.orderItems?.find((f) => f.type === "vehicle")?.metadata?.vin
            setVinLast8digits(vin?.trim()?.slice(-8))
        }
    }, [selectedOrder]);

    useEffect(() => {
        setReturnType(
            contractData?.returns
                ? contractData?.returns?.return_type
                // : contractData?.subscription_status === SubscriptionStatus.DRAFT
                //     ? ReturnTypes.UNWIND
                    : ReturnTypes.EARLY_RETURN
        );
        setTabLoaded(OrderTabName[OrderTabName.RETURN])
    }, [contractData]);

    const handleStripeInvoice = () => {
        if (contractData?.final_bill_invoice_id) {
            getStripeInvoice(
                { invoice_id: contractData?.final_bill_invoice_id },
                {
                    onSuccess (data) {
                        if (data?.data?.lines?.has_more) {
                            getInvoiceLineItems(
                                { invoiceId: contractData?.final_bill_invoice_id },
                                {
                                    onSuccess (lineItems) {
                                        setCustomerInvoice({
                                            ...data.data,
                                            due_amount: data?.data?.amount_due,
                                            starting_balance: data?.data?.starting_balance,
                                            lines: setTaxLineItemIndex(lineItems),
                                        });
                                        setOpenBillDialog(true);
                                    },
                                }
                            );
                        } else {
                            setCustomerInvoice({
                                ...data.data,
                                due_amount: data?.data?.amount_due,
                                created_date: data?.data?.created,
                                starting_balance: data?.data?.starting_balance,
                                invoice_status: data?.data?.status,
                                invoice_number: data?.data?.number,
                                lines: setTaxLineItemIndex(data?.data?.lines?.data)?.map((line) => {
                                    return {
                                        id: line?.id,
                                        invoice_item: line?.invoice_item,
                                        description: line?.description,
                                        amount: line?.amount,
                                        price_id: line?.price?.id,
                                        product_id: line?.price?.product,
                                        metadata: line?.metadata,
                                    };
                                }),
                            });
                            setOpenBillDialog(true);
                            setModalOpeningMode((data?.data?.status === InvoiceDisplayStatus.OPEN || data?.data?.status === InvoiceDisplayStatus.PAID) && ModalModes.VIEW)
                        }
                    },
                    onError () {
                        actions.setToast({
                            toastMessage: "Invoice not found",
                            toastState: true,
                            variant: "error",
                        });
                    },
                }
            );
        }
    };

    const getReturnRequest = () => {
        switch (returnType) {
            case ReturnTypes.STANDARD_RETURN:
                return (
                    <StandardReturn
                        form={form}
                        contractData={contractData}
                        isEditOdometerAllow={isEditOdometerAllow}
                        isEditActualRDAllow={isEditActualRDAllow}
                        vinLast8Digit={vinLast8Digit}
                    />
                );
            case ReturnTypes.EARLY_RETURN:
                case ReturnTypes.UNWIND :
                return (
                    <EarlyReturn
                        form={form}
                        contractData={contractData}
                        isEditOdometerAllow={isEditOdometerAllow}
                        isEditActualRDAllow={isEditActualRDAllow}
                        vinLast8Digit={vinLast8Digit}
                        convertedReturnType={convertedReturnType}
                    />
                );
        }
    };

    const handleRefund = () => {
        setOpenRefundDialog(true);
    };

    const handleOpenHistoryDialog = () => {
        setOpenHistoryDialog(true);
    };

    return (
        contractData === undefined ? 
        <NoSubscriptionComponent/>
        :
        <>
            {getReturnRequest()}
            <Box theme={theme} sx={{ mt: "12px", mb: "24px", opacity: "0.8" }}>
                <hr />
            </Box>
            <Box
                theme={theme}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexShrink: 0,
                    gap: 1.1,
                    marginBottom: 4,
                }}
            >
               { (contractData?.subscription_status !== SubscriptionStatus.ACTIVE && contractData?.subscription_status !== SubscriptionStatus.DRAFT && contractData?.subscription_status !== SubscriptionStatus.PENDING_RETURN) &&
                        <Button
                            theme={theme}
                            primary={!((contractData?.subscription_status === SubscriptionStatus.CLOSED ||
                                contractData?.subscription_status === SubscriptionStatus.PURCHASED) &&
                                contractData?.final_bill_amount > 0)}
                            secondary={(contractData?.subscription_status === SubscriptionStatus.CLOSED ||
                                contractData?.subscription_status === SubscriptionStatus.PURCHASED) &&
                                contractData?.final_bill_amount > 0}
                            text="Final Bill"
                            onClick={handleStripeInvoice}
                            disabled={isLoading || !isFinalBillAllow || invoiceLineItemsLoading}
                            endIcon={
                                invoiceLoading && <img src={DisableLoader} alt="Loader" />
                            }
                        />
                        }
                {(contractData?.subscription_status === SubscriptionStatus.CLOSED ||
          contractData?.subscription_status === SubscriptionStatus.PURCHASED) &&
          returnType !== ReturnTypes.UNWIND &&
          contractData?.final_bill_amount > 0 &&
            isRefundAllow && (
                    <Button
                        theme={theme}
                        secondary
                        text="Refund"
                        onClick={handleRefund}
                        disabled={!isRefundAllow}
                    />
                )}
                {/* {contractData?.subscription_status === SubscriptionStatus.PENDING_RETURN && //commented for now
                    <Button theme={theme} secondary text="Cancel Return" />
                } */}
            </Box>
            {openBillDialog && (
                <InvoiceModalComponent
                    openInvoiceDialog={openBillDialog}
                    setOpenInvoiceDialog={setOpenBillDialog}
                    modalOpeningMode={modalOpeningMode}
                    setModalOpeningMode={setModalOpeningMode}
                    contractData={contractData}
                    customerInvoice={customerInvoice}
                    setCustomerInvoice={setCustomerInvoice}
                    handleOpenHistoryDialog={handleOpenHistoryDialog}
                    handleOpenRefundDialog={() => {
                        setOpenRefundDialog(true)
                        setItemSelectedFromInvoiceModel(true)
                    }}
                />
            )}
            {openRefundDialog && (
                <RefundModalComponent
                    openRefundDialog={openRefundDialog}
                    setOpenRefundDialog={setOpenRefundDialog}
                    invoiceId={
                       itemSelectedFromInvoiceModel
                            ? contractData?.final_bill_invoice_id
                            : contractData?.first_bill_invoice_id
                    }
                    securityDeposit={
                        (returnType !== ReturnTypes.UNWIND && !itemSelectedFromInvoiceModel) && contractData?.final_bill_amount
                    }
                    openingMode={(returnType !== ReturnTypes.UNWIND && !itemSelectedFromInvoiceModel) && ModalModes.RETURN}
                />
            )}
             {openHistoryDialog && (
                <InvoiceHistoryModalComponent
                    openHistoryDialog={openHistoryDialog}
                    customerInvoice={customerInvoice}
                    setOpenHistoryDialog={setOpenHistoryDialog}
                />
            )}
        </>
    );
};
