import { CustomerDetailsPage } from "modules/customerDetails/pages";
import { CustomerListPage } from "modules/customerList/pages";
import { OrderDetailPage } from "@modules/orderDetails/pages/orderDetail/orderDetailPage";
import { Route, Routes } from "react-router-dom";
import { APP_ROUTES } from "./path";
// import { ProtectedRoute } from "./ProtectedRoutes";
// import { UMSConfigurationComponent ,LoginValidatorComponent} from "@ntpkunity/controls-ums";
// import { MsalAuthenticationTemplate } from "@azure/msal-react";
// import { InteractionType } from "@azure/msal-browser";
// import { loginRequest } from "../authConfig";
// import { SSOErrorComponent } from "./ssoError-component";
// import { SSOLoadingComponent } from "./ssoLoading-component";
import { CCPWorkQueuePage } from "@modules/workQueueList/pages/workQueueList/ccpWorkQueuePage";

export const Router = () => {
    return (
        <>
            {/* <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
                errorComponent={SSOErrorComponent}
                loadingComponent={SSOLoadingComponent}
            >
                <ProtectedRoute> */}
                    {/* <UMSConfigurationComponent>
                    <LoginValidatorComponent> */}
                        <Routes>
                            <Route
                                path={APP_ROUTES.BASE_URL}
                                element={<CustomerListPage />}
                            />
                            <Route
                                path={APP_ROUTES.CUSTOMER_LIST}
                                element={<CustomerListPage />}
                            />
                            <Route
                                path={APP_ROUTES.CUSTOMER_DETAILS + "/:reference_id"}
                                element={<CustomerDetailsPage />}
                            />
                            <Route
                                path={
                                    APP_ROUTES.ORDER_DETAILS +
                  "/:reference_id" +
                  "/:order_reference_id"
                                }
                                element={<OrderDetailPage />}
                            />
                            <Route
                                path={APP_ROUTES.CCP_WORKQUEUE}
                                element={<CCPWorkQueuePage />}
                            />
                        </Routes>
                    {/* </LoginValidatorComponent>
                    </UMSConfigurationComponent> */}
                {/* </ProtectedRoute>
            </MsalAuthenticationTemplate> */}
        </>
    );
};
