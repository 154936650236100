// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
}
hr{ 
  margin: 0; 
  opacity: 0.4;
}
a.link { text-decoration: none; }

a.link:hover { text-decoration: underline !important; }

.MuiDivider-root { border-color: #CCCED9; border-width: 1px;}
@media print {
  header,
  footer,
  .action-btn {
    display: none !important;
  }
  .main-content {
    padding-top: 80px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/root.component.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;AACA;EACE,SAAS;EACT,YAAY;AACd;AACA,SAAS,qBAAqB,EAAE;;AAEhC,eAAe,qCAAqC,EAAE;;AAEtD,mBAAmB,qBAAqB,EAAE,iBAAiB,CAAC;AAC5D;EACE;;;IAGE,wBAAwB;EAC1B;EACA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":["body {\n  margin: 0;\n}\nhr{ \n  margin: 0; \n  opacity: 0.4;\n}\na.link { text-decoration: none; }\n\na.link:hover { text-decoration: underline !important; }\n\n.MuiDivider-root { border-color: #CCCED9; border-width: 1px;}\n@media print {\n  header,\n  footer,\n  .action-btn {\n    display: none !important;\n  }\n  .main-content {\n    padding-top: 80px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
