import { useTheme } from "@mui/material";
import { DatePicker, Grid, Input, Select } from "@ntpkunity/controls";
import { useEffect, useState, FC } from "react";
import { Controller } from "react-hook-form";
import { ReturnTypes } from "enums/ReturnTypes";
import { useGetEventAssociatedChecklist , useGetTenantConfiguredInformation} from "common/hooks/useConfigurationData";
import { ChecklistEvents, SubscriptionStatus } from "enums";
import { DealerChecklist } from "../../../../../shared/DealerCheckList/dealerChecklist";
import { BuyOut } from "../../../../../shared/BuyOut/buyOut";
import { utcToLocal } from "utilities/methods";
import dayjs from "dayjs";
import { utcToLocalFormat } from "common/constants";
import { ReturnWrap } from "./return.style";

export const StandardReturn: FC<{
    form: any;
    contractData: any;
    isEditOdometerAllow: boolean;
    isEditActualRDAllow: boolean;
    vinLast8Digit: string
}> = ({ form, contractData, isEditOdometerAllow, isEditActualRDAllow, vinLast8Digit }) => {
    const theme = useTheme();
    const { control, setValue, getValues, reset, watch } = form;

    const [buyOutOption, setBuyOutOption] = useState("");
    const [plannedConfiguredDays, setPlannedConfiguredDays] = useState(0);
    const current_odometer_reading = watch("returns.current_odometer_reading");
    const subscriptionStatus = contractData?.subscription_status;
    const buyOutObj = contractData?.buyout;
    const startDate = contractData?.start_date;
    const {
        mutate: getStandardReturn,
        data: standardReturnChecklist,
        isLoading: getStandardReturnLoading,
    } = useGetEventAssociatedChecklist();
    const {data: tenantInformation} = useGetTenantConfiguredInformation()
    useEffect(() => {
        const overrunMiles =
            parseInt(current_odometer_reading) - getValues("returns.allowed_usage") - contractData?.starting_odometer;
        setValue("returns.overrun_miles", overrunMiles > 0 ? overrunMiles : 0);
        setValue("returns.starting_odometer", contractData?.starting_odometer ? contractData?.starting_odometer : 0 );
    }, [current_odometer_reading]);

    useEffect(() => {
        const overrun_miles =
            contractData?.returns?.current_odometer_reading -
            contractData?.returns?.allowed_usage - contractData?.starting_odometer;
        reset({
            customer_email: contractData?.customer?.email,
            buyOutOption: contractData?.buyout?.buyout_option,
            vehiclePurchasePrice: contractData?.buyout?.vehicle_purchase_price,
            returns: {
                ...contractData?.returns,
                overrun_miles: overrun_miles > 0 ? overrun_miles : 0,
                planned_return_date: contractData?.returns?.planned_return_date &&  utcToLocal(contractData?.returns?.planned_return_date),
                actual_return_date: contractData?.returns?.actual_return_date && utcToLocal(contractData?.returns?.actual_return_date),
                check_list_start_date: contractData?.returns?.check_list_start_date && utcToLocal(contractData?.returns?.check_list_start_date, utcToLocalFormat)
            },
    });
/* eslint-disable array-callback-return */
        contractData?.returns?.order_checklist?.map((checklist) => {
            setValue(checklist.question_text, checklist.primary_answer);
        });
    }, [contractData]);

    useEffect(() => {
        getStandardReturn({ event_name: ChecklistEvents.STANDARD_RETURN_EVENT });
    }, []);
    useEffect(() => {
        setPlannedConfiguredDays(tenantInformation?.planned_return_date)
    }, [tenantInformation]);
    return (
        <>
            <Grid theme={theme} rowSpacing={3} columnSpacing={3} sx={{ mb: 3 }} container>
                <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        name="returns.return_type"
                        control={control}
                        defaultValue={ReturnTypes.STANDARD_RETURN}
                        render={({ field: { value, onChange } }) => (
                            <Select
                                theme={theme}
                                label={"Return Type"}
                                items={[
                                    {
                                        text: "Standard Return",
                                        value: ReturnTypes.STANDARD_RETURN,
                                    }
                                ]}
                                value={value}
                                onChange={onChange}
                                disabled
                            />
                        )}
                    />
                </Grid>
                <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <ReturnWrap className='datepicker'>
                        <Controller
                            name="returns.planned_return_date"
                            control={control}
                            defaultValue={""}
                            render={({ field: { value, onChange } }) => (
                                <DatePicker
                                    theme={theme}
                                    label={"Planned Return Date"}
                                    value={value ?? ""}
                                    maxDate={dayjs(Date.now())?.add(plannedConfiguredDays,"day")}  
                                    minDate={dayjs(startDate)}
                                    onChange={(e: Date) => {
                                        if (
                                            e.getFullYear() !== 1969 &&
                                            e.getFullYear() !== 1970 
                                        ) { onChange(e)}
                                    }}
                                    onKeyDown={(e: any) => e.preventDefault()}
                                    disabled={subscriptionStatus !== SubscriptionStatus.ACTIVE && subscriptionStatus !== SubscriptionStatus.PAUSED && subscriptionStatus !== SubscriptionStatus.PENDING_RETURN}
                                />
                            )}
                        />
                    </ReturnWrap>
                </Grid>
                <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        name="returns.actual_return_date"
                        control={control}
                        defaultValue={"Input Value"}
                        render={({ field: { value, onChange } }) => (
                            <DatePicker
                                theme={theme}
                                label={"Actual Return Date"}
                                value={value ?? ""}
                                disableFuture={true}
                                onChange={(e: Date) => {
                                    onChange(e.getFullYear() === 1970  || e?.getFullYear() === 1969 ? contractData?.returns?.actual_return_date : e.toISOString())
                                }}
                                onKeyDown={(e: any) => e.preventDefault()}
                                disabled={subscriptionStatus !== SubscriptionStatus.PROCESSING_RETURN || !isEditActualRDAllow}
                            />
                        )}
                    />
                </Grid>
                <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        name="returns.check_list_start_date"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                            <Input
                              theme={theme}
                              {...field}
                              type={"text"}
                              fullWidth
                              label="Return Checklist Start Date"
                              disabled
                            />
                          )}
                    />
                </Grid>
                <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        name="returns.starting_odometer"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                        <Input
                            theme={theme}
                            {...field}
                            type={"decimal"}
                            fullWidth
                            label="Starting Odometer"
                            placeholder="Type here.."
                            disabled
                        />
                        )}
                    />
                    </Grid>
                <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        name="returns.current_odometer_reading"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                            <Input
                                theme={theme}
                                {...field}
                                type={"decimal"}
                                max={999999}
                                fullWidth label="Return Mileage"
                                placeholder="Type here.."
                                disabled={subscriptionStatus !== SubscriptionStatus.PROCESSING_RETURN || !isEditOdometerAllow}
                            />
                        )}
                    />
                </Grid>
                <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        name="returns.allowed_usage"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                            <Input
                                theme={theme}
                                {...field}
                                fullWidth
                                placeholder="0000"
                                label="Allowed Term Miles"
                                type="decimal"
                                disabled
                            />
                        )}
                    />
                </Grid>
                <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        name="returns.overrun_miles"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                            <Input
                                theme={theme}
                                {...field}
                                fullWidth
                                placeholder="0000"
                                label="Mileage Overage"
                                type="decimal"
                                disabled
                            />
                        )}
                    />
                </Grid>
                <Grid theme={theme} item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        name="last8Digits"
                        control={control}
                        defaultValue={""}
                        render={() => (
                            <Input
                                theme={theme}
                                value={vinLast8Digit}
                                fullWidth
                                placeholder="0000"
                                label="Last 8 Digits of VIN"
                                type="decimal"
                                disabled
                            />
                        )}
                    />
                </Grid>
                <Grid theme={theme} item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Controller
                        name="returns.dealer_comments"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                            <Input
                                theme={theme}
                                {...field}
                                fullWidth
                                placeholder="0000"
                                label="Dealer Comments"
                                type="decimal"
                                disabled
                            />
                        )}
                    />
                </Grid>
            </Grid>
            {(subscriptionStatus === SubscriptionStatus.PENDING_RETURN || buyOutObj !== null) &&
                <BuyOut
                    form={form}
                    setBuyOutOption={setBuyOutOption}
                    contractData={contractData} />}
            {(buyOutObj === null && (buyOutOption === "No" || buyOutOption === "")) &&
                <DealerChecklist
                    checklist={standardReturnChecklist?.[0]?.[0]}
                    dealerChecklist={contractData?.returns?.order_checklist}
                    returnType={ReturnTypes.STANDARD_RETURN}
                    isLoading={getStandardReturnLoading}
                />}
        </>
    )
}