import {
    CANCEL_RETURN_REQUEST,
    CREATE_RETURN_REQUEST,
    GET_REQUEST,
    LATE_RETURN_PENALTY,
    OVERRUN_MILE_PENALTY,
    PROCEED_RETURN_REQUEST,
    UPDATE_RETURN_REQUEST,
    UPDATE_RETURN_REUQUEST_STATUS,
} from "common/internalEndpointUrls";
import { eotBaseUrl, sendRequest } from "utilities/http-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "enums/UseQueryKeys";

export const useUpdateReturnRequest = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            return sendRequest(eotBaseUrl).patch<any>(
                `${UPDATE_RETURN_REQUEST}/${body.identifier}`,
                body
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CONTRACT_BY_REFERENCE_ID]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useCreateReturnRequest = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            return sendRequest(eotBaseUrl).post<any>(
                `${CREATE_RETURN_REQUEST}`,
                body
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CONTRACT_BY_REFERENCE_ID]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useProceedReturnRequest = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            return sendRequest(eotBaseUrl).post<any>(
                `${PROCEED_RETURN_REQUEST}/${body.identifier}`,
                body
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CONTRACT_BY_REFERENCE_ID]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useOverrunMilesPenalty = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query = `request_identifier=${body.identifier}`;
        return sendRequest(eotBaseUrl).post<any>(
            `${OVERRUN_MILE_PENALTY}?${query}`,
            body
        );
    });
    return { data, isLoading, mutate };
};

export const useLateReturnPenalty = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query = `request_identifier=${body.identifier}`;
        return sendRequest(eotBaseUrl).post<any>(
            `${LATE_RETURN_PENALTY}?${query}`,
            body
        );
    });
    return { data, isLoading, mutate };
};

export const getAllReturnRequestFilterFromServer = (columnFilters: string) => {
    return sendRequest(eotBaseUrl)
        .get(`${GET_REQUEST}?`.concat(columnFilters))
        .then((response) => {
            return response.data;
        });
};

export const useGetReturnRequest = (columnFilters: string) => {
    return useQuery([QueryKeys.GET_RETURN_REQUEST, columnFilters], async () => {
        return getAllReturnRequestFilterFromServer(columnFilters).then((data) => {
            return data;
        });
    });
};

export const useCancelReturnRequest = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            return sendRequest(eotBaseUrl).post<any>(
                `${CANCEL_RETURN_REQUEST}`,
                body
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CONTRACT_BY_REFERENCE_ID]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useUpdateReturnRequestStatus = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            /* eslint-disable @typescript-eslint/no-unused-vars */
            const { order_identifier, return_updated_to, ...bodyWithoutParams } = body;
            const query_string = `return_identifier=${body.order_identifier}&return_updated_to=${return_updated_to}`;
            return sendRequest(eotBaseUrl).post<any>(
                `${UPDATE_RETURN_REUQUEST_STATUS}?${query_string}`,
                bodyWithoutParams 
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CONTRACT_BY_REFERENCE_ID]);
            },
        }
    );
    return { data, isLoading, mutate };
};
