import { AutoSearch } from "@shared/AutoSearch";
import { AccountNotesTable } from "./accountNotesTable";
import { ToggleButton, useTheme } from "@mui/material";
import {
  Button,
  Box,
  Icon,
  Textarea,
  Typography,
  Skeleton,
} from "@ntpkunity/controls";
import { useEffect, useState } from "react";
import { AccountNotesStyle } from "./accountNotesStyle";
import {
  useAddDiaryComment,
  useGetCustomerCommentByReferenceId,
} from "common/hooks/useCustomerData";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useGetAllDiaryCatergory } from "common/hooks/useConfigurationData";
import { ValidationMessages } from "enums/ValidationMessages";
import DisableLoader from "../../../../shared/assets/images/loader-disabled.gif";
import { getInternalAccessToken } from "utilities/methods";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import { useStoreContext } from "store/storeContext";

export const AccountNotes = () => {
  const theme = useTheme();
  const params = useParams();
  const addAccountNotePermission = useHasPermissions(
    ccpPermissions.FIELDS.ADD_ACCOUNT_NOTE,
    PermissionTypes.VIEW
  );
  const viewPreviousAccountNotesPermission = useHasPermissions(
    ccpPermissions.FIELDS.VIEW_PREVIOUS_ACCOUNT_NOTES,
    PermissionTypes.VIEW
  );

  const form = useForm<any>({
    mode: "onSubmit",
    defaultValues: {
      description: "",
      toggleValue: "",
    },
  });
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = form;
  const { actions } = useStoreContext();
  const tokenInforamtion = getInternalAccessToken();
  const { data: getAllDiaryCategory, isLoading } = useGetAllDiaryCatergory();
  const [getDiaryCategoryResponse, setDiaryCategoryResponse] = useState<any>();
  const [getCustomerDiaryComments, setCustomerDiaryComments] = useState<any>();
  const { mutate: addDiaryComment, isLoading: isSaveLoading } =
    useAddDiaryComment();
  const [toggleValue, setToggleValue] = useState("");
  const [toggleButtonSelected, setToggleButtonSelected] = useState(false);
  const [page_number, setPageNumber] = useState(0);
  const [page_size, setPageSize] = useState(10);
  const [commentValue, setCommentValue] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [columnFilters, setColumnFilters] = useState(
    `reference_id=${encodeURIComponent(
      params?.reference_id
    )}&page_number=${page_number}&page_size=${page_size}`
  );
  const [addAccountNotePermissionState, setAddAccountNotePermissionState] =
    useState(false);
  const [
    viewPreviousAccountNotesPermissionState,
    setViewPreviousAccountNotesPermissionState,
  ] = useState(false);

  const { data: getCustomerCommentByReferenceID } =
    useGetCustomerCommentByReferenceId(columnFilters);

  const setQueryString = () => {
    let query_string = `reference_id=${encodeURIComponent(
      params?.reference_id
    )}&page_number=${page_number}&page_size=${page_size}`;
    if (commentValue != null && commentValue != "") {
      query_string = query_string.concat(`&comment=${commentValue}`);
    }
    if (
      categoryValue != null &&
      categoryValue != "" &&
      categoryValue != "All"
    ) {
      query_string = query_string.concat(`&category=${categoryValue}`);
    }
    return query_string;
  };

  useEffect(() => {
    setCustomerDiaryComments(getCustomerCommentByReferenceID);
    setDiaryCategoryResponse(getAllDiaryCategory?.data);
    {
      const category =
        getDiaryCategoryResponse &&
        getDiaryCategoryResponse?.find((x) => x.category == "General Comments")
          ?.category;
      if (category) {
        setToggleValue(category);
      } else {
        setToggleValue(getDiaryCategoryResponse?.[0]?.category);
      }
    }
  }, [getAllDiaryCategory, getCustomerCommentByReferenceID]);

  useEffect(() => {
    setColumnFilters(setQueryString);
  }, [page_number, page_size, commentValue, categoryValue]);

  useEffect(() => {
    setAddAccountNotePermissionState(addAccountNotePermission);
    setViewPreviousAccountNotesPermissionState(
      viewPreviousAccountNotesPermission
    );
  }, [addAccountNotePermission, viewPreviousAccountNotesPermission]);

  const handleOnSave = (form) => {
    if (!toggleValue) {
      actions.setToast({
        toastMessage: "Please select a category to add a comment.",
        toastState: true,
        variant: "error",
        showIcon: true,
      });
      return;
    }
    const data = {
      reference_id: params.reference_id,
      category: toggleValue,
      comment: form.description,
      created_by: tokenInforamtion?.user_name,
    };
    addDiaryComment(
      { data },
      {
        onSuccess() {
          reset();
        },
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <Skeleton width={"100%"} height={80} theme={theme}></Skeleton>
      ) : (
        <>
          {addAccountNotePermissionState && (
            <form onSubmit={handleSubmit(handleOnSave)}>
              <AccountNotesStyle>
                <Box theme={theme} className="title">
                  <Typography
                    variant="h4"
                    component="span"
                    rowGap={5}
                    theme={theme}
                  >
                    Add Comment
                  </Typography>
                </Box>
                <Controller
                  name="category"
                  control={control}
                  defaultValue={""}
                  render={() => (
                    <AutoSearch
                      theme={theme}
                      className="auto-search"
                      sx={{ mb: 3 }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{ mr: 1 }}
                        theme={theme}
                      >
                        Select Category:
                      </Typography>
                      <ToggleButton
                        value="check"
                        selected={toggleButtonSelected}
                        onChange={() => {
                          setToggleButtonSelected(!toggleButtonSelected);
                        }}
                      >
                        {toggleValue}
                        <Icon name="ChevronDown" />
                      </ToggleButton>
                      {toggleButtonSelected && (
                        <Box theme={theme} className="list-wrap">
                          {getDiaryCategoryResponse?.length > 0 &&
                            getDiaryCategoryResponse?.map((key, index) => {
                              return (
                                <li
                                  key={index}
                                  onClick={(e) => {
                                    setToggleValue(
                                      (e.target as HTMLElement).innerText as any
                                    );
                                    setValue("category", e);
                                    setToggleButtonSelected(
                                      !toggleButtonSelected
                                    );
                                  }}
                                >
                                  <span>{key.category}</span>
                                </li>
                              );
                            })}
                        </Box>
                      )}
                    </AutoSearch>
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  defaultValue={""}
                  rules={{
                    maxLength: {
                      value: 1000,
                      message: ValidationMessages.COMMENT_DESCRIPTION_LENGTH,
                    },
                    required: ValidationMessages.COMMENT_DESCRIPTION_REQUIRED,
                  }}
                  render={({ field }) => (
                    <Textarea
                      theme={theme}
                      label={"Comment"}
                      type="text"
                      id="drawerTextDescription"
                      name="drawerDescription"
                      placeholder="Type your Comment here..."
                      rows={3}
                      fullWidth
                      {...field}
                      value={field.value}
                      error={errors?.description?.message?.toString()}
                    />
                  )}
                />

                <Button
                  theme={theme}
                  disabled={isSaveLoading}
                  endIcon={
                    isSaveLoading && <img src={DisableLoader} alt="Loader" />
                  }
                  primary
                  type="submit"
                  text="Post Comment"
                />
              </AccountNotesStyle>
            </form>
          )}
          {viewPreviousAccountNotesPermissionState &&
            getCustomerDiaryComments && (
              <div>
                <AccountNotesTable
                  key={getCustomerDiaryComments}
                  getCustomerDiaryComments={getCustomerDiaryComments}
                  getDiaryCategoryResponse={getDiaryCategoryResponse}
                  setPageNumber={setPageNumber}
                  setPageSize={setPageSize}
                  setCategoryValue={setCategoryValue}
                  setCommentValue={setCommentValue}
                  page_number={page_number}
                  page_size={page_size}
                />
              </div>
            )}
        </>
      )}
    </>
  );
};
