import { LayoutWithSideNavComponent } from "@layout/components";
import { Box, DuoTab } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { OrderDetails } from "@modules/orderDetails/components/orderDetails/orderDetails";
import { OrderDetailPageHeader } from "@modules/orderDetails/components/orderDetailPageHeader";
import { VehicleDetail } from "@modules/orderDetails/components/vehicleDetail/vehicleDetail";
import { StipulationTable } from "@modules/orderDetails/components/stipulations/stipulationsTable";
import { AccountDocuments } from "@modules/orderDetails/components/documents/accountDocuments";
import { InsuranceDetails } from "@modules/orderDetails/components/insuranceDetails/insuranceDetails";
import { Return } from "@modules/orderDetails/components/return/return";
import { useForm } from "react-hook-form";
import {
  useAddInsuranceInformation,
  useGetCustomerInformationByReferenceId,
  useUpdateInsuranceInformationByReferenceId,
} from "common/hooks/useCustomerData";
import { useLocation, useParams } from "react-router-dom";
import { useStoreContext } from "store/storeContext";
import { OrderTabName, WorkQueueOrderTabName } from "enums/TabNames";
import { useRef, useState, SyntheticEvent, useEffect } from "react";
import {
  useGetCountries,
  useGetStates,
} from "common/hooks/useConfigurationData";
import { getInternalAccessToken, utcToLocal } from "utilities/methods";
import { LayoutHeaderComponent } from "@layout/components/layoutHeader/layoutHeaderComponent";
import {
  useHasPermissions,
  ccpPermissions,
  Validate,
} from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import { APP_ROUTES } from "@router/path";
import {
  DealerResponses,
  InvoiceListComponent,
} from "@modules/orderDetails/components";
import { OrderProvider } from "@modules/orderDetails/context/order";
import type { IInsuranceDocument } from "Interface/IInsuranceDocument";
import {
  useCreateReturnRequest,
  useLateReturnPenalty,
  useOverrunMilesPenalty,
  useUpdateReturnRequest,
  useUpdateReturnRequestStatus,
} from "common/hooks/useReturnData";
import {
  useGetContractByRefercnceId,
  useGetTaxAgainstStripeProduct,
} from "common/hooks/useSubscription";
import {
  InvoiceDisplayStatus,
  StripeMetaData,
  SubscriptionStatus,
} from "enums";
import {
  useDeleteInvoiceItem,
  useGetStripeInvoice,
  useSaveInvoiceItem,
  useSaveStripePrice,
  useStripeProductSearch,
  useUpdateInvoice,
} from "common/hooks/useHubexData";
import { useCustomerOrderData } from "common/hooks/useCustomerOrderData";
import { ReturnTypes } from "enums/ReturnTypes";
import { dateFormatEarlyReturn } from "common/constants";

export const OrderDetailPage = () => {
  const theme = useTheme();
  const params = useParams();
  const tokenInforamtion = getInternalAccessToken();
  const { actions } = useStoreContext();
  const userId = params?.reference_id;
  const { data: getCustomerOrderData } = useCustomerOrderData(userId);
  useGetStates();
  useGetCountries();
  const form = useForm<any>({
    mode: "onTouched",
  });
  const [hideButton, setHideButton] = useState(false);
  const [addressDirty, setAddressDirty] = useState<boolean>(false);
  const [isTabLoaded, setTabLoaded] = useState("");
  const [tabValue, setTabValue] = useState(null);
  const isInsuranceDataAlreadyAddedRef = useRef<boolean>(false);
  const [insuranceDocuments, setInsuranceDocuments] = useState<
    IInsuranceDocument[]
  >([]);
  const [orderData, setCustomerOrderData] = useState<any>();
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const { state } = useLocation();
  const { getValues, reset } = form;
  const [initiateReturnDialog, setInitiateReturnDialog] =
    useState<boolean>(false);
  const [initiateUnwindDialog, setInitiateUnwindDialog] =
    useState<boolean>(false);
  const [updateReturnDialog, setUpdateReturnDialog] = useState<boolean>(false);
  const [buyOutDialog, setBuyOutDialog] = useState<boolean>(false);
  const [convertedReturnType, setConvertedRetrnType] = useState("");
  const [convertETDialogOpened, setConvertETDialogOpened] = useState<boolean>(false);

  const { data: getCustomreInfo } = useGetCustomerInformationByReferenceId(
    params?.reference_id
  );
  const { data: contractData } = useGetContractByRefercnceId(
    params?.reference_id,
    params?.order_reference_id
  );
  const { mutate: updateInsuranceInformation, isLoading } =
    useUpdateInsuranceInformationByReferenceId();
  const { mutate: addInsurance, isLoading: addingInsurance } =
    useAddInsuranceInformation();
  const { mutate: updateReturnRequest, isLoading: updatingReturn } =
    useUpdateReturnRequest();
  const { mutate: updateUnwindToET,isLoading: convertUnwindLoading } = useUpdateReturnRequestStatus();
  const { mutate: createReturnRequest, isLoading: addingReturn } =
    useCreateReturnRequest();
  const { mutateAsync: saveStripePrice, isLoading: saveStripePriceLoading } =
    useSaveStripePrice();
  const { mutateAsync: saveInvoiceItem, isLoading: saveInvoiceItemLoading } =
    useSaveInvoiceItem();
  const { mutate: getStripeInvoice, isLoading: getInvoiceLoading } =
    useGetStripeInvoice();
  const {
    mutateAsync: getOverrunPenaltyProduct,
    isLoading: getOverrunProductLoading,
  } = useStripeProductSearch();
  const {
    mutateAsync: getLateReturnPenaltyProduct,
    isLoading: getLateReturnProductLoading,
  } = useStripeProductSearch();
  const {
    mutateAsync: getEarlyTerminationFeeProduct,
    isLoading: getEarlyTerminationFeeLoading,
  } = useStripeProductSearch();
  const { mutate: overrunMilesPenalty, isLoading: getOverrunPenaltyLoading } =
    useOverrunMilesPenalty();
  const { mutate: lateReturnPenalty, isLoading: getLateReturnPenaltyLoading } =
    useLateReturnPenalty();
  const { mutate: getTaxAgainstStripeProduct } =
    useGetTaxAgainstStripeProduct();
  const { mutate: updateInvoice } = useUpdateInvoice();
  const { mutate: getStripeProducts } = useStripeProductSearch();
  const { mutate: deleteInvoiceItem } = useDeleteInvoiceItem();

  useEffect(() => {
    if (getCustomerOrderData?.data) {
      setCustomerOrderData(getCustomerOrderData?.data?.orders);
    }
  }, [getCustomerOrderData?.data]);
  useEffect(() => {
    if (orderData) {
      setSelectedOrder(
        orderData?.find((f) => f.id === params?.order_reference_id)
      );
    }
  }, [orderData]);
  const addTaxPriceAndLineItem = (
    productId,
    amount,
    quoteId,
    invoiceLineItems,
    invoiceId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    index = -1
  ) => {
    saveStripePrice(
      {
        currency: "usd",
        unit_amount_decimal: amount,
        product: productId,
      },
      {
        onSuccess({ data: stripePrice }) {
          saveInvoiceItem(
            {
              customer: invoiceLineItems?.invoice_item
                ? undefined
                : contractData?.customer?.stripe_customer_id,
              invoice: invoiceLineItems?.invoice_item ? undefined : invoiceId,
              invoice_item: invoiceLineItems?.invoice_item
                ? invoiceLineItems?.invoice_item
                : undefined,
              price: stripePrice?.id,
              metadata: {
                [StripeMetaData.TAX_CHARGE]: true,
              },
            },
            {
              onSuccess() {
                updateInvoice({
                  invoice_id: invoiceId,
                  metadata: {
                    quote_id: quoteId,
                  },
                });
              },
              onError(error: any) {
                console.log("Error Occured", error);
                actions.setToast({
                  toastMessage: "An error occurred while calculating tax",
                  toastState: true,
                  variant: "error",
                });
              },
            }
          );
        },
        onError(error: any) {
          console.log("Error Occured", error);
          actions.setToast({
            toastMessage: "An error occurred while calculating tax",
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const calculateTax = (invoiceLineItems, invoiceId) => {
    const lineItems = invoiceLineItems?.filter(
      (f) => f?.metadata?.[StripeMetaData.TAX_CHARGE] != "True"
    );
    const customerAddress =
      contractData?.customer?.customer_addresses?.find(
        (f) => f.address_type === "Residential"
      ) ?? {};
    getTaxAgainstStripeProduct(
      {
        lines:
          lineItems?.length > 0
            ? lineItems?.map((item) => ({
              productId: item?.price?.product,
              productType: item?.description
                ?.trim()
                ?.toUpperCase()
                ?.replace(/ /g, "_"),
              price: item?.amount,
            }))
            : [],
        vin: contractData?.assets?.[0]?.vin ?? "",
        address: {
          city: customerAddress?.city ?? "",
          state: customerAddress?.state_name ?? "",
          line1: customerAddress?.address_line_1 ?? "",
          line2: customerAddress?.address_line_2 ?? "",
          zipCode: customerAddress?.zip_code ?? "", // "90003"
        },
      },
      {
        onSuccess(data) {
          const totalTaxAmount = data?.data?.totalTaxAmount;
          const quoteId = data?.data?.quoteId;
          const taxLineItemIndex = invoiceLineItems?.findIndex(
            (f) => f?.metadata?.[StripeMetaData.TAX_CHARGE] === "True"
          );
          if (taxLineItemIndex > -1) {
            addTaxPriceAndLineItem(
              invoiceLineItems[taxLineItemIndex]?.price?.product,
              totalTaxAmount,
              taxLineItemIndex,
              invoiceLineItems[taxLineItemIndex],
              invoiceId,
              quoteId
            );
          } else {
            getStripeProducts(
              {
                query_params: `query=metadata["${StripeMetaData.TAX_CHARGE}"]:"true"&limit=100`,
              },
              {
                onSuccess(data) {
                  addTaxPriceAndLineItem(
                    data?.[0]?.id,
                    totalTaxAmount,
                    taxLineItemIndex,
                    invoiceLineItems,
                    invoiceId,
                    quoteId
                  );
                },
                onError(error: any) {
                  console.log("Error Occured", error);
                  actions.setToast({
                    toastMessage: "An error occurred while calculating tax",
                    toastState: true,
                    variant: "error",
                  });
                },
              }
            );
          }
        },
        onError(error: any) {
          console.log("Error Occured", error);
          actions.setToast({
            toastMessage: "An error occurred while calculating tax",
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const handleOverrunPenaltyProduct = async (price, metadata, invoice) => {
    try {
      let lineItemsArray = invoice?.lines?.data;
      // Step 1: Get Overrun Penalty Products
      const productsResponse = await getOverrunPenaltyProduct({
        query_params: `query=metadata["${metadata}"]:"true"`,
      });
      if (price > 0) {
        // Step 2: Create Stripe Prices for each product
        const stripePricePromises = productsResponse?.map(async (product) => {
          return saveStripePrice({
            currency: "usd",
            unit_amount_decimal: price,
            product: product?.id,
          });
        });
        const stripePrices = await Promise.all(stripePricePromises);
        // Step 3: Create Invoice Items
        const invoiceItemPromises = stripePrices?.map(async (stripePrice) => {
          const invoiceItem = invoice?.lines?.data?.find(
            (invoiceItem) =>
              invoiceItem?.price?.product === stripePrice?.data?.product
          )?.invoice_item;
          const newInvoiceItem = await saveInvoiceItem({
            customer: invoiceItem
              ? undefined
              : contractData?.customer?.stripe_customer_id,
            invoice: invoiceItem
              ? undefined
              : contractData?.final_bill_invoice_id,
            invoice_item: invoiceItem ? invoiceItem : undefined,
            price: stripePrice?.data?.id,
          });
          if (invoiceItem) {
            lineItemsArray = lineItemsArray.filter(
              (lineItem) =>
                lineItem?.price?.product !== stripePrice?.data?.product
            );
          }
          lineItemsArray.push(
            newInvoiceItem?.data ? newInvoiceItem?.data : newInvoiceItem
          );
        });
        await Promise.all(invoiceItemPromises);
        calculateTax(lineItemsArray, invoice?.id);
      } else {
        productsResponse?.map(async (overrunProduct) => {
          const isOverrunPenaltyItem = invoice?.lines?.data?.find(
            (invoiceItem) => invoiceItem?.price?.product === overrunProduct?.id
          );
          if (isOverrunPenaltyItem) {
            deleteInvoiceItem(
              { invoice_item: isOverrunPenaltyItem?.invoice_item },
              {
                onSuccess() {
                  lineItemsArray = lineItemsArray.filter(
                    (lineItem) =>
                      lineItem?.price?.product !== overrunProduct?.id
                  );
                  calculateTax(lineItemsArray, invoice?.id);
                },
              }
            );
          }
        });
      }
    } catch (error) {
      actions.setToast({
        toastMessage: error?.detail?.message?.toString(),
        toastState: true,
        variant: "error",
      });
    }
  };

  const handleLateReturnPenaltyProduct = async (price, metadata, invoice) => {
    try {
      let lineItemsArray = invoice?.lines?.data;
      // Step 1: Get Late Return Penalty Products
      const productsResponse = await getLateReturnPenaltyProduct({
        query_params: `query=metadata["${metadata}"]:"true"`,
      });
      if (price > 0) {
        // Step 2: Create Stripe Prices for each product
        const stripePricePromises = productsResponse?.map(async (product) => {
          return saveStripePrice({
            currency: "usd",
            unit_amount_decimal: price,
            product: product?.id,
          });
        });
        const stripePrices = await Promise.all(stripePricePromises);
        // Step 3: Create Invoice Items
        const invoiceItemPromises = stripePrices?.map(async (stripePrice) => {
          const invoiceItem = invoice?.lines?.data?.find(
            (invoiceItem) =>
              invoiceItem?.price?.product === stripePrice?.data?.product
          )?.invoice_item;
          const newInvoiceItem = await saveInvoiceItem({
            customer: invoiceItem
              ? undefined
              : contractData?.customer?.stripe_customer_id,
            invoice: invoiceItem
              ? undefined
              : contractData?.final_bill_invoice_id,
            invoice_item: invoiceItem ? invoiceItem : undefined,
            price: stripePrice?.data?.id,
          });
          if (invoiceItem) {
            lineItemsArray = lineItemsArray.filter(
              (lineItem) =>
                lineItem?.price?.product !== stripePrice?.data?.product
            );
          }
          lineItemsArray.push(
            newInvoiceItem?.data ? newInvoiceItem?.data : newInvoiceItem
          );
        });
        await Promise.all(invoiceItemPromises);
        calculateTax(lineItemsArray, invoice?.id);
      } else {
        productsResponse?.map(async (overrunProduct) => {
          const isOverrunPenaltyItem = invoice?.lines?.data?.find(
            (invoiceItem) => invoiceItem?.price?.product === overrunProduct?.id
          );
          if (isOverrunPenaltyItem) {
            deleteInvoiceItem(
              { invoice_item: isOverrunPenaltyItem?.invoice_item },
              {
                onSuccess() {
                  lineItemsArray = lineItemsArray.filter(
                    (lineItem) =>
                      lineItem?.price?.product !== overrunProduct?.id
                  );
                  calculateTax(lineItemsArray, invoice?.id);
                },
              }
            );
          }
        });
      }
    } catch (error) {
      console.log("error", error);
      actions.setToast({
        toastMessage: error?.detail?.message?.toString(),
        toastState: true,
        variant: "error",
      });
    }
  };

  const handleEarlyTerminationFeeLineItem = async (price, metadata, invoice) => {
    try {
      let lineItemsArray = invoice?.lines?.data;
      // Step 1: Get Early Termination Fee Products
      const productsResponse = await getEarlyTerminationFeeProduct({
        query_params: `query=metadata["${metadata}"]:"true"`,
      });
      if (price > 0) {
        // Step 2: Create Stripe Prices for each product
        const stripePricePromises = productsResponse?.map(async (product) => {
          return saveStripePrice({
            currency: "usd",
            unit_amount_decimal: price,
            product: product?.id,
          });
        });
        const stripePrices = await Promise.all(stripePricePromises);
        // Step 3: Create Invoice Items
        const invoiceItemPromises = stripePrices?.map(async (stripePrice) => {
          const invoiceItem = invoice?.lines?.data?.find(
            (invoiceItem) =>
              invoiceItem?.price?.product === stripePrice?.data?.product
          )?.invoice_item;
          const newInvoiceItem = await saveInvoiceItem({
            customer: invoiceItem
              ? undefined
              : contractData?.customer?.stripe_customer_id,
            invoice: invoiceItem
              ? undefined
              : contractData?.final_bill_invoice_id,
            invoice_item: invoiceItem ? invoiceItem : undefined,
            price: stripePrice?.data?.id,
            metadata: {
              [StripeMetaData.EARLY_TERMINATION_FEE]: "true",
            },
          });
          if (invoiceItem) {
            lineItemsArray = lineItemsArray.filter(
              (lineItem) =>
                lineItem?.price?.product !== stripePrice?.data?.product
            );
          }
          lineItemsArray.push(
            newInvoiceItem?.data ? newInvoiceItem?.data : newInvoiceItem
          );
        });
        await Promise.all(invoiceItemPromises);
        calculateTax(lineItemsArray, invoice?.id);
      } else {
        productsResponse?.map(async (product) => {
          const isOverrunPenaltyItem = invoice?.lines?.data?.find(
            (invoiceItem) => invoiceItem?.price?.product === product?.id
          );
          if (isOverrunPenaltyItem) {
            deleteInvoiceItem(
              { invoice_item: isOverrunPenaltyItem?.invoice_item },
              {
                onSuccess() {
                  lineItemsArray = lineItemsArray.filter(
                    (lineItem) =>
                      lineItem?.price?.product !== product?.id
                  );
                  calculateTax(lineItemsArray, invoice?.id);
                },
              }
            );
          }
        });
      }
    } catch (error) {
      console.log("error", error);
      actions.setToast({
        toastMessage: error?.detail?.message?.toString(),
        toastState: true,
        variant: "error",
      });
    }
  }

  const calculateFinalBill = (returns) => {
    getStripeInvoice(
      { invoice_id: contractData?.final_bill_invoice_id },
      {
        onSuccess({ data: invoice }) {
          if (invoice?.status === InvoiceDisplayStatus.DRAFT) {
            if (
              contractData?.returns?.current_odometer_reading !==
              returns?.current_odometer_reading
            ) {
              overrunMilesPenalty(
                { identifier: contractData?.returns?.identifier },
                {
                  onSuccess({ data: penalty }) {
                    handleOverrunPenaltyProduct(
                      penalty?.over_run_miles,
                      StripeMetaData.OVERRUN_PENALTY,
                      invoice
                    );
                  },
                  onError(error) {
                    actions.setToast({
                      toastMessage: error?.detail?.message,
                      toastState: true,
                      variant: "error",
                    });
                  },
                }
              );
            }
            if (
              contractData?.returns?.actual_return_date !==
              returns?.actual_return_date
            ) {
              lateReturnPenalty(
                {
                  identifier: contractData?.returns?.identifier,
                  monthlyPayment: selectedOrder?.monthlyPayment,
                  downPayment: selectedOrder?.downPayment,
                },
                {
                  onSuccess({ data: penalty }) {
                    handleLateReturnPenaltyProduct(
                      penalty?.total_amount,
                      StripeMetaData.LATE_RETURN_PENALTY,
                      invoice
                    );
                  },
                  onError(error) {
                    actions.setToast({
                      toastMessage: error?.detail?.message,
                      toastState: true,
                      variant: "error",
                    });
                  },
                }
              );
            }
            if (contractData?.returns?.et_fee !==
              returns?.et_fee) {
              handleEarlyTerminationFeeLineItem(
                returns?.et_fee,
                StripeMetaData.EARLY_TERMINATION_FEE,
                invoice
              )
            }
          } else {
            actions.setToast({
              toastMessage: "Invoice has alreday been sent to custoner",
              toastState: true,
              variant: "error",
            });
          }
        },
        onError() {
          actions.setToast({
            toastMessage: "Something went wrong",
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };
  const handleUpdateReturnRequest = (data) => {
    if (convertETDialogOpened === true){
      const returnData = {
          return_type: ReturnTypes.EARLY_RETURN,
          invoice_id:contractData?.final_bill_invoice_id,
          order_identifier:data?.returns?.identifier,
          created_by: data?.customer_email,
          updated_by: data?.customer_email,
          planned_return_date:  data?.returns?.planned_return_date && utcToLocal(data?.returns?.planned_return_date,dateFormatEarlyReturn),
          actual_return_date:  data?.returns?.actual_return_date && utcToLocal(data?.returns?.actual_return_date,dateFormatEarlyReturn),
          et_fee: data?.returns?.et_fee ? data?.returns?.et_fee* 100 : undefined,
          return_updated_to: ReturnTypes.EARLY_RETURN,
          current_odometer_reading: parseFloat(
            data?.returns?.current_odometer_reading
          ),
          buyout: null
      };
      updateUnwindToET(returnData, {
        onSuccess() {
          setConvertETDialogOpened(false)
          actions.setToast({
            toastMessage: "Return Type changed and final bill updated Successfully",
            toastState: true,
          });
        },
        onError(error) {
          setConvertETDialogOpened(false)
            actions.setToast({
            toastMessage: error?.detail?.message?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
    }else{
    if (data?.returns?.et_fee?.valueOf()?.length > 9) {
      setUpdateReturnDialog(false);
      setBuyOutDialog(false);
      actions.setToast({
        toastMessage:
          "Early Termination Fee should be less than or equal to $999,999.99",
        toastState: true,
        variant: "error",
      });
    } else if (
      data?.buyOutOption === "Yes" &&
      data?.vehiclePurchasePrice?.valueOf()?.length > 13
    ) {
      setUpdateReturnDialog(false);
      setBuyOutDialog(false);
      actions.setToast({
        toastMessage:
          "Vehicle purchase price should be less than or equal to $9,999,999,999.99",
        toastState: true,
        variant: "error",
      });
    } else {
      const returnData = {
        actual_return_date: data?.returns?.actual_return_date,
        planned_return_date:
          data?.returns?.planned_return_date != ""
            ? data?.returns?.planned_return_date
            : null,
        noi_date:
          data?.returns?.noi_date != "" ? data?.returns?.noi_date : null,
        et_fee: data?.returns?.et_fee * 100,
        current_odometer_reading: parseFloat(
          data?.returns?.current_odometer_reading
        ),
        identifier: data?.returns?.identifier,
        updated_by: data?.customer_email,
        monthlyPayment: selectedOrder?.monthlyPayment,
        downPayment: selectedOrder?.downPayment,
        reason: data?.returns?.unwind_reason,
        reason_detail: data?.returns?.others,
        ...(
          data?.buyOutOption === "Yes"
            ? {
                buyout: {
                  buyout_option: data?.buyOutOption,
                  vehicle_purchase_price: parseFloat(data?.vehiclePurchasePrice),
                },
              }
            : {
                buyout: null,
              }
        )
      };
      updateReturnRequest(returnData, {
        onSuccess({ data: returns }) {
          setUpdateReturnDialog(false);
          setBuyOutDialog(false);
          actions.setToast({
            toastMessage: returns,
            toastState: true,
          });
          if (contractData?.final_bill_invoice_id) {
            calculateFinalBill(returnData);
          }
        },
        onError(error) {
          setUpdateReturnDialog(false);
          actions.setToast({
            toastMessage: error?.detail?.message?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
    }
  }
  };

  const handleCreateReturnRequest = (data) => {
    if (data?.returns?.et_fee?.valueOf()?.length > 9) {
      setInitiateReturnDialog(false);
      actions.setToast({
        toastMessage:
          "Early Termination Fee should be less than or equal to $999,999.99",
        toastState: true,
        variant: "error",
      });
    } else {
      const newData = {
        planned_return_date:
          data?.returns?.planned_return_date != ""
            ? data?.returns?.planned_return_date
            : null,
        noi_date:
          data?.returns?.noi_date != "" ? data?.returns?.noi_date : null,
        return_type: data?.returns?.return_type,
        order_identifier: data?.identifier,
        et_fee: data?.returns?.et_fee * 100,
        created_by: data?.customer_email,
        reason: data?.returns?.unwind_reason,
        reason_detail: data?.returns?.others,
      };
      createReturnRequest(newData, {
        onSuccess(res) {
          actions.setToast({
            toastMessage: res.data,
            toastState: true,
          });
          setInitiateReturnDialog(false);
          setInitiateUnwindDialog(false);
        },
        onError(error) {
          actions.setToast({
            toastMessage: error?.detail?.detail?.message?.toString(),
            toastState: true,
            variant: "error",
          });
          setInitiateReturnDialog(false);
        },
      });
    }
  };

  const handleSubmitReturn = (data: any) => {
    switch (contractData?.subscription_status) {
      case SubscriptionStatus.PENDING_RETURN:
      case SubscriptionStatus.PROCESSING_RETURN:
       case convertETDialogOpened === true:
        return handleUpdateReturnRequest(data);
      case SubscriptionStatus.DRAFT:
      case SubscriptionStatus.ACTIVE:
      default:
        return handleCreateReturnRequest(data);
    }
  };

  const handleSubmitInsurance = (data) => {
    const newData = {
      insurance_detail: {
        insurer_name: data?.insuranceDetail?.insurer_name
          ? data?.insuranceDetail?.insurer_name
          : null,
        policy_number: data?.insuranceDetail?.policy_number
          ? data?.insuranceDetail?.policy_number
          : null,
        order_reference_id: data?.insuranceDetail?.order_reference_id,
        deductible_comprehensive: data?.insuranceDetail
          ?.deductible_comprehensive
          ? data?.insuranceDetail?.deductible_comprehensive
          : null,
        deductible_collision: data?.insuranceDetail?.deductible_collision
          ? data?.insuranceDetail?.deductible_collision
          : null,
        insurer_address: data?.insuranceDetail?.insurer_address,
        start_date: data?.insuranceDetail?.start_date?.split("T")[0],
        expiration_date: data?.insuranceDetail?.expiration_date?.split("T")[0],
        status: data?.insuranceDetail?.status
          ? data?.insuranceDetail?.status
          : null,
        updated_by: tokenInforamtion?.user_name,
        agent_phone: data?.insuranceDetail?.agent_phone
          ? data?.insuranceDetail?.agent_phone
          : null,
      },
    };
    if (isInsuranceDataAlreadyAddedRef.current) {
      updateInsuranceInformation(
        {
          ...newData,
          reference_id: params?.reference_id,
          order_reference_id: params?.order_reference_id,
        },
        {
          onSuccess() {
            reset({ ...getValues() });
            reset(data);
            setAddressDirty(false);
            actions.setToast({
              toastMessage: "Record Updated Successfully",
              toastState: true,
            });
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error?.detail?.message?.toString(),
              toastState: true,
              variant: "error",
            });
          },
        }
      );
    } else {
      addInsurance(
        {
          ...newData.insurance_detail,
          reference_id: params?.reference_id,
          order_reference_id: params?.order_reference_id,
          created_by: tokenInforamtion?.user_name,
          insurance_documents: insuranceDocuments,
        },
        {
          onSuccess() {
            reset({ ...getValues() });
            reset(data);
            setAddressDirty(false);
            actions.setToast({
              toastMessage: "Record Updated Successfully",
              toastState: true,
            });
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error?.detail?.message?.toString(),
              toastState: true,
              variant: "error",
            });
          },
        }
      );
    }
  };

  const onSubmit = (data: any) => {
    if (isTabLoaded === OrderTabName[OrderTabName.INSURANCE_DETAILS]) {
      handleSubmitInsurance(data);
    } else {
      handleSubmitReturn(data);
    }
  };

  const tabs = [
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_ORDER_DETAILS,
      PermissionTypes.VIEW
    )
      ? [
        {
          title: "Order Details",
          content: (
            <>
              <OrderDetails />
            </>
          ),
        },
      ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_VEHICLE_DETAILS,
      PermissionTypes.VIEW
    )
      ? [
        {
          title: "Vehicle Detail",
          content: (
            <>
              <VehicleDetail />
            </>
          ),
        },
      ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_DEALER_RESPONSE,
      PermissionTypes.VIEW
    )
      ? [
        {
          title: "Dealer Responses",
          content: (
            <>
              <DealerResponses />
            </>
          ),
        },
      ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_STIPULATIONS,
      PermissionTypes.VIEW
    )
      ? [
        {
          title: "Stipulations",
          content: (
            <>
              <StipulationTable />
            </>
          ),
        },
      ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_ACCOUNT_DOCUMENTS,
      PermissionTypes.VIEW
    )
      ? [
        {
          title: "Order Documents",
          content: (
            <>
              {" "}
              <AccountDocuments />
            </>
          ),
        },
      ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_INSURANCE_DETAILS,
      PermissionTypes.VIEW
    )
      ? [
        {
          title: "Insurance Details",
          content: (
            <>
              {" "}
              <InsuranceDetails
                form={form}
                setAddressDirty={setAddressDirty}
                isInsuranceDataAlreadyAddedRef={
                  isInsuranceDataAlreadyAddedRef
                }
                insuranceDocuments={insuranceDocuments}
                setInsuranceDocuments={setInsuranceDocuments}
                setTabLoaded={setTabLoaded}
              />
            </>
          ),
        },
      ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_INVOICE_LIST,
      PermissionTypes.VIEW
    )
      ? [
        {
          title: "Invoices",
          content: (
            <>
              <InvoiceListComponent></InvoiceListComponent>
            </>
          ),
        },
      ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_RETURN_TAB,
      PermissionTypes.VIEW
    )
      ? [
        {
          title: "Return",
          content: (
            <>
              <Return
                form={form}
                isLoading={
                  getLateReturnPenaltyLoading ||
                  getOverrunPenaltyLoading ||
                  getLateReturnProductLoading ||
                  getEarlyTerminationFeeLoading ||
                  getOverrunProductLoading ||
                  getInvoiceLoading ||
                  saveInvoiceItemLoading ||
                  saveStripePriceLoading
                }
                setTabLoaded={setTabLoaded}
                convertedReturnType={convertedReturnType}
              />
            </>
          ),
        },
      ]
      : []),
  ];

  return (
    <>
      <LayoutWithSideNavComponent theme={theme}>
        <Validate
          permission={ccpPermissions.FIELDS.OPEN_ORDER_DETAILS}
          url={APP_ROUTES.BASE_URL}
        >
          {getCustomreInfo && (
            <LayoutHeaderComponent
              theme={theme}
              breadcrumbs={[
                ...(state?.routeState === true || state?.isWorkQueue === true
                  ? [
                    {
                      item: "Work queue",
                      link: `${APP_ROUTES.CCP_WORKQUEUE}`,
                    },
                    { item: `${params?.order_reference_id}`, link: "" },
                  ]
                  : [
                    {
                      item: "Customer List",
                      link: `${APP_ROUTES.CUSTOMER_LIST}`,
                    },
                    {
                      item: `${[
                        getCustomreInfo?.data?.first_name,
                        getCustomreInfo?.data?.middle_name,
                        getCustomreInfo?.data?.last_name,
                      ]
                        .filter(Boolean)
                        .join(" ")
                        .replace(/(\w)(\w*)/g, function (g0, g1, g2) {
                          return g1.toUpperCase() + g2.toLowerCase();
                        })}`,
                      link: `${APP_ROUTES.CUSTOMER_DETAILS}/${params?.reference_id}`,
                    },
                    { item: `${params?.order_reference_id}`, link: "" },
                  ]
                ),
              ]}
            />
          )}
          <OrderDetailPageHeader
            form={form}
            isLoading={isLoading || addingInsurance}
            hideButton={hideButton}
            addressDirty={addressDirty}
            onSubmit={(data) => onSubmit(data)}
            initiateReturnDialog={initiateReturnDialog}
            setInitiateReturnDialog={setInitiateReturnDialog}
            initiateUnwindDialog={initiateUnwindDialog}
            setInitiateUnwindDialog={setInitiateUnwindDialog}
            returnLoading={addingReturn || updatingReturn}
            updateReturnDialog={updateReturnDialog}
            setUpdateReturnDialog={setUpdateReturnDialog}
            setBuyOutDialog={setBuyOutDialog}
            buyOutDialog={buyOutDialog}
            isTabLoaded={isTabLoaded}
            setConvertedRetrnType={setConvertedRetrnType}
            convertedReturnType={convertedReturnType}
            setConvertETDialogOpened={setConvertETDialogOpened}
            convertETDialogOpened={convertETDialogOpened}
            convertUnwindLoading={convertUnwindLoading}
          />
          <Box theme={theme}>
            <OrderProvider>
              <DuoTab
                theme={theme}
                varient={"underline"}
                items={tabs}
                defaultTabIndex={tabValue}
                onChange={(e: SyntheticEvent, value: number) => {
                  if (state?.isWorkQueue) {
                    if (
                      value === WorkQueueOrderTabName.ORDER_DETAILS ||
                      value === WorkQueueOrderTabName.VEHICLE_DETAILS
                    ) {
                      setHideButton(true);
                    } else {
                      setHideButton(false);
                    }
                  } else {
                    if (
                      value === OrderTabName.DEALER_RESPONSE ||
                      value === OrderTabName.ORDER_DETAILS ||
                      value === OrderTabName.DEALER_RESPONSE ||
                      value === OrderTabName.VEHICLE_DETAILS ||
                      value === OrderTabName.STIPULATION ||
                      value === OrderTabName.DOCUMENT ||
                      value === OrderTabName.CHARGES
                    ) {
                      setHideButton(true);
                    } else {
                      setHideButton(false);
                    }
                  }

                  setTabValue(value);
                  form.reset();
                }}
                value={
                  tabValue != null
                    ? tabValue
                    : state?.isWorkQueue === true
                      ? tabs?.findIndex((f) => f.title === "Return")
                      : OrderTabName.ORDER_DETAILS
                }
              />
            </OrderProvider>
          </Box>
        </Validate>
      </LayoutWithSideNavComponent>
    </>
  );
};
